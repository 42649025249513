// Library methods
import { useMemo } from "react";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import Circle from "../../../../../components/UI/Circle";
import Square from "../../../../../components/UI/Square";

// Utilities
import { round } from "../../../../../utils/numberHelper";

const SingleThresholdChartLegend = ({ singleThresholdPoints, t }) => {
  const seenSpots = useMemo(() => {
    return singleThresholdPoints?.length
      ? singleThresholdPoints.filter((point) => point.seen)
      : [];
  }, [singleThresholdPoints]);

  return (
    <div style={{ paddingLeft: 4 }}>
      <Box display="flex" alignItems="center" mb={0.5}>
        <Circle />
        <Typography ml={2} variant="body1" noWrap>
          {t("word_seen")} {seenSpots.length}/120
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={0.5}>
        <Square />
        <Typography ml={2} variant="body1" noWrap>
          {t("word_not_seen")} {120 - seenSpots.length}/120
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" noWrap>
          {t("single_threshold_efficiency_score")}:{" "}
          {round((seenSpots.length / 120) * 100, 2)}
        </Typography>
      </Box>
    </div>
  );
};

export default SingleThresholdChartLegend;
