// Library methods
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";

// Styles
import {
  CustomTextFieldOutlined,
  ModalCustomCancel,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalPaperProps,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";
import SearchBox from "../SearchBox";
import { useContext } from "react";
import { AlgorithmContext } from "../../../contexts/ExamPropertyContext";

const LinkDeviceModalGeneral = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  patientOptions = [],
  examsOptions = [],
  deviceLinkPatient,
  setDeviceLinkPatient,
  deviceLinkExam,
  setDeviceLinkExam,
  loading,
}) => {
  // internationalization
  const { t } = useTranslation();
  const { TUMBLING_E } = useContext(AlgorithmContext);

  const formattedPatientOptions = useMemo(
    () =>
      patientOptions.map((option) => {
        return {
          name: `${option?.firstName} ${option?.lastName}`,
          val: option?.id,
        };
      }),
    [patientOptions]
  );

  const sortedPatientOptions = useMemo(
    () =>
      formattedPatientOptions.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      }),
    [formattedPatientOptions]
  );

  const formattedExamOptions = useMemo(
    () =>
      examsOptions.map((exam) => {
        const name =
          exam?.visualAcuitySections?.[0]?.algorithm === TUMBLING_E
            ? `${t("word_acuity_tumbling")} | ${exam.scheduledDate?.slice(0, 10)}`
            : `${
                exam.visualFieldSections?.[0]?.algorithm ??
                exam.colorVisionSections?.[0]?.algorithm ??
                exam.contrastSensitivitySections?.[0]?.algorithm ??
                exam.visualAcuitySections?.[0]?.algorithm ??
                "N/A"
              } | ${exam.scheduledDate?.slice(0, 10) ?? "N/A"}`;
        return {
          name: name,
          val: exam?.id,
        };
      }),
    [examsOptions, t, TUMBLING_E]
  );

  const sortedExamOptions = useMemo(
    () =>
      formattedExamOptions.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
    [formattedExamOptions]
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = 0;
    formattedPatientOptions.forEach((option) => {
      if (option?.val === deviceLinkPatient?.val) isIncluded++;
    });
    formattedExamOptions.forEach((option) => {
      if (option?.val === deviceLinkExam?.val) isIncluded++;
    });
    return (
      !(deviceLinkExam && deviceLinkPatient) || loading || isIncluded !== 2
    );
  }, [
    deviceLinkExam,
    deviceLinkPatient,
    formattedExamOptions,
    formattedPatientOptions,
    loading,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...ModalPaperProps,
          width: 400,
          maxHeight: 500,
        },
      }}
      maxWidth={false}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faLink} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("word_link_device")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* Patient selector */}
          <FormControl
            fullWidth
            sx={() => CustomTextFieldOutlined({ my: "1.25rem" })}
            color="warning"
          >
            <SearchBox
              data={sortedPatientOptions}
              state={deviceLinkPatient}
              setState={setDeviceLinkPatient}
              propertyBit={4}
              label={t("current_patient")}
            />
          </FormControl>

          {/* Exam selector */}
          <SearchBox
            data={sortedExamOptions}
            state={deviceLinkExam}
            setState={setDeviceLinkExam}
            propertyBit={4}
            label={t("word_exam")}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          color="primary"
          disabled={shouldDisable}
          onClick={() => onConfirm(deviceLinkExam?.val)}
        >
          {t("button_link")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkDeviceModalGeneral;
