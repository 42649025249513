// Library methods
import axios from "axios";

// Utilities
import {
  isResponseValid,
  webServiceUrl,
  injectBearer,
} from "../../utils/servicesHelper";

const getPortalHub = async () =>
  axios
    .get(`${webServiceUrl}/live-data/portal-hub`)
    .then((response) => {
      const data = isResponseValid(response);
      return data;
    })
    .catch((error) => {
      throw error;
    });

const subscribeToClinic = async (token, clinicId, connection) => {
  const payload = {
    token: injectBearer(token),
    clinicId,
  };
  return await connection.invoke("SubscribeToClinic", JSON.stringify(payload));
};

const getVersion = async (connection) => {
  return await connection.invoke("GetVersion", "");
};

const subscribeToAdmin = async (token, connection) => {
  const injectedToken = injectBearer(token);
  return await connection.invoke("SubscribeToAdmin", injectedToken);
};

const unsubscribeFromClinic = async (token, clinicId, connection) => {
  const payload = {
    token: injectBearer(token),
    clinicId,
  };
  return await connection.invoke(
    "UnSubscribeFromClinic",
    JSON.stringify(payload)
  );
};

const unsubscribeFromAdmin = async (token, connection) => {
  const injectedToken = injectBearer(token);
  return await connection.invoke("UnSubscribeFromAdmin", injectedToken);
};

const subscribeToDevice = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.invoke("SubscribeToDevice", JSON.stringify(payload));
};

const unsubscribeFromDevice = async (deviceId, connection) => {
  return await connection.invoke("UnSubscribeFromDevice", deviceId);
};

const updateDeviceStage = async (token, deviceId, stage, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
    stage,
  };
  return await connection.invoke(
    "RequestUpdateDeviceStage",
    JSON.stringify(payload)
  );
};

const deviceExamTerminationRequest = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.invoke(
    "RequestExamTermination",
    JSON.stringify(payload)
  );
};

const deviceIncreaseVolumeRequest = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.send(
    "SendMessageToDevice",
    "DeviceIncreaseVolumeRequested",
    JSON.stringify(payload)
  );
};

const deviceDecreaseVolumeRequest = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.send(
    "SendMessageToDevice",
    "DeviceDecreaseVolumeRequested",
    JSON.stringify(payload)
  );
};

const deviceDisableAutoPauseRequested = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.send(
    "SendMessageToDevice",
    "DeviceDisableAutoPauseRequested",
    JSON.stringify(payload)
  );
};

const deviceEnableAutoPauseRequested = async (token, deviceId, connection) => {
  const payload = {
    token: injectBearer(token),
    deviceId,
  };
  return await connection.send(
    "SendMessageToDevice",
    "DeviceEnableAutoPauseRequested",
    JSON.stringify(payload)
  );
};

const deviceCalibrateLobbyRequested = async (token, deviceId, connection) => {
  const payload = {
    token: `Bearer ${token}`,
    deviceId,
  };
  return await connection.send(
    "SendMessageToDevice",
    "DeviceRecenterRequested",
    JSON.stringify(payload)
  );
};

// WebRtc methods signaling method
const invokeSendOffer = async (token, deviceId, offer, connection) => {
  const payload = {
    token: `Bearer ${token}`,
    deviceId,
    offer,
  };
  return await connection.invoke("SendOffer", JSON.stringify(payload));
};

const invokeSendIceCandidate = async (
  token,
  deviceId,
  iceCandidate,
  connection
) => {
  const payload = {
    token: `Bearer ${token}`,
    deviceId,
    iceCandidate,
  };
  return await connection.invoke("SendIceCandidate", JSON.stringify(payload));
};

const invokeNotifyAnswerAccepted = async (token, deviceId, connection) => {
  const payload = {
    token: `Bearer ${token}`,
    deviceId,
  };
  return await connection.invoke(
    "NotifyAnswerAccepted",
    JSON.stringify(payload)
  );
};

export {
  getPortalHub,
  subscribeToClinic,
  getVersion,
  unsubscribeFromClinic,
  subscribeToAdmin,
  unsubscribeFromAdmin,
  subscribeToDevice,
  unsubscribeFromDevice,
  updateDeviceStage,
  deviceExamTerminationRequest,
  deviceIncreaseVolumeRequest,
  deviceDecreaseVolumeRequest,
  deviceDisableAutoPauseRequested,
  deviceEnableAutoPauseRequested,
  deviceCalibrateLobbyRequested,
  invokeSendOffer,
  invokeSendIceCandidate,
  invokeNotifyAnswerAccepted,
};
