import axios from "axios";
import {
  config,
  isResponseValid,
  webServiceUrl,
} from "../../utils/servicesHelper";

const getIceEndpoint = async (token) => {
  try {
    const response = await axios.get(
      `${webServiceUrl}/web-rtc/ice-endpoint`,
      config(token)
    );
    const data = isResponseValid(response);
    return data;
  } catch (e) {
    throw e;
  }
};

const getIceServers = async (url, credentials) => {
  const data = { format: "urls" };
  // const base64Credentials = Buffer.from(credentials).toString("base64");
  const base64Credentials = btoa(credentials);
  const headers = {
    Authorization: `Basic ${base64Credentials}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.put(url, data, { headers: headers });

    if (!response?.data?.v?.iceServers) throw new Error("invalid data");

    return response.data.v.iceServers;
  } catch (e) {
    throw e;
  }
};

export { getIceEndpoint, getIceServers };
