// Library methods
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirmDelete,
  ModalCustomConfirmIconHandler,
  ModalPaperProps,
} from "../../../../styles/muiStylesHelper";

const DeleteUserModal = ({ open, onClose, onConfirm, onCancel }) => {
  // internationalization
  const { t } = useTranslation();

  // Modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: { ...ModalPaperProps, width: 400 },
      }}
    >
      <DialogContent sx={{ padding: "20px 20px 0" }}>
        <Box sx={{ padding: 1 }}>
          <Box display="flex" sx={{ alignItems: "center" }}>
            <IconButton
              aria-label="search"
              size="small"
              sx={{
                color: "#C11717",
                width: 25,
                height: 25,
                mr: 1,
                backgroundColor: "rgba(193, 23, 17, 0.4)",
                "&:hover": {
                  color: "#C11717",
                  backgroundColor: "rgba(193, 23, 17, 0.4)",
                  borderColor: "#202338",
                },
              }}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ fontWeight: "700", color: "#C11717" }}
            >
              {t("user_delete_confirmation")}
            </Typography>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            {t("user_delete_description")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          sx={ModalCustomCancel}
          onClick={onCancel}
          style={{ marginRight: "1.25rem" }}
        >
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirmDelete(),
            ...ModalCustomConfirmIconHandler(),
          }}
          onClick={onConfirm}
          color="error"
        >
          {t("button_delete")}
          <DeleteForeverIcon fontSize="medium" color="inherit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
