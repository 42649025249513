import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// Utilities
import { DeviceStagesTimeline } from "../utils/signalRHelper";

export const sectionCompletionState = {
  Undefined: "Undefined",
  Completed: "Completed",
  Terminated: "Terminated",
  Invalid: "Invalid",
};

export const useExamStatus = () => {
  const { t } = useTranslation();

  const statuses = {
    testNotStarted: t("word_not_started"),
    testCompleted: t("word_completed"),
    testPaused: t("word_pause"),
    testInProgress: t("word_in_progress"),
    testTerminated: t("word_terminated"),
    testLeftEyeCompleted: t("word_left_eye_completed"),
    // testLeftEyeTerminated: t("word_left_eye_terminated"),
    testRightEyeCompleted: t("word_right_eye_completed"),
    // testRightEyeTerminated: t("word_right_eye_terminated"),
    testInvalid: t("word_invalid"),
    patientLogin: t("word_patient_login"),
    confirmation: t("word_confirmation"),
    tutorial: t("word_tutorial"),
    // tutorialPaused,
    calibration: t("word_calibration"),
  };

  const completionStateStatus = {
    Undefined: statuses.testNotStarted,
    Completed: statuses.testCompleted,
    Terminated: statuses.testTerminated,
    Invalid: statuses.testInvalid,
  };

  // get exam status
  const getExamStatus = useCallback(
    (row, associatedExamDevices) => {
      if (row === undefined) return undefined;

      //filter to get device status
      const device = associatedExamDevices?.find(
        (item) => item?.associatedExam?.exam?.id === row?.id
      );
      const deviceStageType = device?.deviceStatus?.stage?.stageType;

      const associated = device?.associatedExam?.exam
        ? device?.associatedExam?.exam
        : row;

      if (deviceStageType === "PatientLogin") {
        const data = {
          status: statuses.patientLogin,
          timelineStage: DeviceStagesTimeline.PatientLogin,
          device: device,
        };
        return data;
      } else if (deviceStageType === "Confirmation") {
        const data = {
          status: statuses.confirmation,
          timelineStage: DeviceStagesTimeline.Confirmation,
          device: device,
        };
        return data;
      } else if (deviceStageType === "Tutorial") {
        const data = {
          status: statuses.tutorial,
          timelineStage: DeviceStagesTimeline.Tutorial,
          device: device,
          extraWord: statuses.testInProgress,
        };
        return data;
      } else if (deviceStageType === "Calibration") {
        const data = {
          status: statuses.calibration,
          timelineStage: DeviceStagesTimeline.Calibration,
          device: device,
        };
        return data;
      } else if (deviceStageType === "Pause") {
        const data = {
          status: statuses.testPaused,
          timelineStage: device?.deviceStatus?.stage?.metadata?.isTutorial
            ? DeviceStagesTimeline.Tutorial
            : DeviceStagesTimeline.Exam,
          device: device,
        };
        return data;
      } else if (deviceStageType === "Exam") {
        const data = {
          status: statuses.testInProgress,
          timelineStage: DeviceStagesTimeline.Exam,
          device: device,
        };
        return data;
      }
      // Not started or either one or both is completed
      // And not in Progress
      else if (associated) {
        const examSectionsKeys = Object.keys(associated).filter((key) =>
          key.includes("Sections")
        );
        const currentExamSectionsKey = examSectionsKeys?.filter(
          (key) => associated[key].length > 0
        );
        let result;
        currentExamSectionsKey?.forEach((sectionKey) => {
          if (associated?.[sectionKey]?.length === 1) {
            result = {
              status:
                completionStateStatus[
                  associated[sectionKey][0]?.completionState
                ],
              timelineStage: undefined,
              device: device,
            };
          } else if (associated?.[sectionKey]?.length > 1) {
            const statusFirst = associated[sectionKey][0]?.completionState;
            const statusSecond = associated[sectionKey][1]?.completionState;
            let resStatus = undefined;
            let resTimeLineStage = undefined;
            if (
              associated[sectionKey][0]?.completionDate &&
              associated[sectionKey][1]?.completionDate
            ) {
              if (
                statusFirst === sectionCompletionState.Terminated ||
                statusSecond === sectionCompletionState.Terminated
              )
                resStatus = statuses.testTerminated;
              else if (
                statusFirst === sectionCompletionState.Invalid ||
                statusSecond === sectionCompletionState.Invalid
              )
                resStatus = statuses.testInvalid;
              else if (
                statusFirst === sectionCompletionState.Completed &&
                statusSecond === sectionCompletionState.Completed
              )
                resStatus = statuses.testCompleted;
            } else if (
              statusFirst === sectionCompletionState.Terminated ||
              statusSecond === sectionCompletionState.Terminated
            ) {
              resStatus = statuses.testTerminated;
              resTimeLineStage = DeviceStagesTimeline.Terminated;
            } else if (
              statusFirst === sectionCompletionState.Invalid ||
              statusSecond === sectionCompletionState.Invalid
            )
              resStatus = statuses.testInvalid;
            else if (statusFirst === sectionCompletionState.Completed) {
              resStatus =
                associated[sectionKey][0]?.eye === "Left"
                  ? statuses.testLeftEyeCompleted
                  : statuses.testRightEyeCompleted;
            } else if (statusSecond === sectionCompletionState.Completed) {
              resStatus =
                associated[sectionKey][1]?.eye === "Left"
                  ? statuses.testLeftEyeCompleted
                  : statuses.testRightEyeCompleted;
            } else resStatus = statuses.testNotStarted;
            result = {
              status: resStatus,
              timelineStage: resTimeLineStage,
              device: device,
            };
          }
        });
        return result;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      statuses.calibration,
      statuses.confirmation,
      statuses.patientLogin,
      statuses.testCompleted,
      statuses.testInProgress,
      statuses.testNotStarted,
      statuses.testPaused,
      statuses.testRightEyeCompleted,
      statuses.testTerminated,
      statuses.tutorial,
    ]
  );

  return { getExamStatus };
};
