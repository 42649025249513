// Library methods
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

// Utilities
import {
  sectionCompletionState,
  useExamStatus,
} from "../../../hooks/useExamStatus";
import { EyeContext } from "../../../contexts/ExamPropertyContext";
import {
  isBinocular,
  isMonocularMulitpleSections,
} from "../../../utils/examReportsHelper";

const ExamCardShared = (row, devicesWithExams) => {
  // internationalization
  const { t } = useTranslation();

  const { getExamStatus } = useExamStatus();

  const { eyeLeft, eyeRight } = useContext(EyeContext);

  // exam status
  const examStatus = useMemo(() => {
    return getExamStatus(row, devicesWithExams);
  }, [devicesWithExams, getExamStatus, row]);

  const hasDevice =
    examStatus?.device !== null && examStatus?.device !== undefined;
  const exam = examStatus?.device?.associatedExam?.exam ?? row;

  // handles the checkmarks in table
  const testCompletionIcon = useMemo(() => {
    const examSectionsKeys = Object.keys(exam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => exam[key].length > 0
    );
    // 2
    if (isBinocular(exam)) {
      const isDone =
        exam?.contrastSensitivitySections?.[0]?.completionDate ||
        exam?.colorVisionSections?.[0]?.completionDate ||
        exam?.visualFieldSections?.[0]?.completionDate ||
        exam?.visualAcuitySections?.[0]?.completionDate;
      return (
        <div className="flex flex-col capitalize ">
          <div className="flex" style={{ opacity: isDone ? 1 : 0.5 }}>
            {t("word_binocular")}
          </div>
        </div>
      );
    }
    if (isMonocularMulitpleSections(exam)) {
      const leftSection = exam[currentExamSectionsKey[0]].find(
        (section) => section?.eye === eyeLeft
      );
      const rightSection = exam[currentExamSectionsKey[0]].find(
        (section) => section?.eye === eyeRight
      );
      const leftDone =
        leftSection?.completionState &&
        leftSection?.completionState !== sectionCompletionState.Undefined;
      const rightDone =
        rightSection?.completionState &&
        rightSection?.completionState !== sectionCompletionState.Undefined;

      return (
        <div className="flex flex-col capitalize ">
          <div className="flex">
            <FontAwesomeIcon
              icon={faEye}
              style={{ opacity: leftDone ? 1 : 0.5 }}
              data-testid="monocular-left-eye-icon"
            />
            <FontAwesomeIcon
              style={{ marginLeft: "30px", opacity: rightDone ? 1 : 0.5 }}
              icon={faEye}
              data-testid="monocular-right-eye-icon"
            />
          </div>
          {`${t("word_left")} / ${t("word_right")}`}
        </div>
      );
    } else if (exam?.[currentExamSectionsKey[0]]?.length === 1) {
      const isLeftEye = exam[currentExamSectionsKey[0]][0]?.eye === eyeLeft;
      const isDone =
        exam[currentExamSectionsKey[0]][0]?.completionState !==
        sectionCompletionState.Undefined;
      return (
        <div className="flex flex-col capitalize items-start">
          <FontAwesomeIcon
            icon={faEye}
            style={{ opacity: isDone ? 1 : 0.5 }}
            data-testid="monocular-one-section-icon"
          />
          {isLeftEye ? t("word_left") : t("word_right")}
        </div>
      );
    }
  }, [exam, t, eyeLeft, eyeRight]);
  const checkReportDisabled = useMemo(() => {
    const examSectionsKeys = Object.keys(exam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => exam[key].length > 0
    );
    return (
      !exam[currentExamSectionsKey[0]]?.[0]?.completionDate &&
      !exam[currentExamSectionsKey[0]]?.[1]?.completionDate
    );
  }, [exam]);

  // should disable the button in case the exam is completed
  const checkLinkDeviceDisabled = () =>
    examStatus?.status === t("word_completed") ||
    examStatus?.status === t("word_terminated") ||
    examStatus?.status === t("word_invalid");

  // define device tooltip message
  const checkTooltipDeviceMessage = () => {
    let reason = "";
    if (examStatus?.status === t("word_completed")) {
      reason = t("exams_link_device_tooltip_disabled_completed");
    } else if (
      examStatus?.status === t("word_terminated") &&
      !examStatus?.timelineStage
    ) {
      reason = t("exams_link_device_tooltip_disabled_terminated");
    } else if (hasDevice) {
      reason = t("exams_link_device_tooltip_disabled_linked");
    } else {
      reason = t("word_link_device");
    }
    return reason;
  };

  // check if live update modal button should be disabled
  const checkLiveUpdateDisabled = () =>
    !examStatus?.device?.deviceStatus?.stage?.stageType ||
    !examStatus?.device?.associatedExam?.exam;

  return {
    exam,
    examStatus,
    hasDevice,
    testCompletionIcon,
    checkReportDisabled,
    checkLinkDeviceDisabled,
    checkTooltipDeviceMessage,
    checkLiveUpdateDisabled,
  };
};

export default ExamCardShared;
