// Library methods
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

// Components
import UserMenuAdminWrapper from "../UserMenuAdminWrapper";

// Assets
import eyeLogo from "../../../assets/images/logos/eyelogo.svg";
import TabSwitcher from "../TabSwitcher";
import { forwardRef, useEffect } from "react";
import useLayoutHeight from "../../../hooks/useLayoutHeight";

const NavbarAdmin = forwardRef(
  ({ canReachPages = true, clickableLogo = true, onHeightChange }, ref) => {
    // internationalization
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const navbarPages = [
      {
        title: t("tab_dashboard"),
        path: "/admin/dashboard",
      },
      {
        title: t("clinics_title"),
        path: "/admin/clinics",
      },
      {
        title: t("tab_devices"),
        path: "/admin/devices",
      },
    ];

    const height = useLayoutHeight(ref);
    useEffect(() => {
      // Only call the onHeightChange callback if it's provided
      if (onHeightChange) {
        onHeightChange(height);
      }
    }, [height, onHeightChange]);

    return (
      <AppBar
        position="static"
        elevation={4}
        sx={{
          backgroundColor: "white",
          color: "#E2772E",
          border: "2px solid",
          height: { xs: 42, sm: 85 },
          display: "flex",
          justifyContent: "center",
        }}
        ref={ref}
      >
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              flexGrow: 1,
              maxWidth: { xs: 47, sm: 73 },
              mx: 3,
              my: 3,
              mr: 5,
              display: { sm: "flex", md: "flex" },
              ...(clickableLogo ? { cursor: "pointer" } : {}),
            }}
            alt="RetinaLogik Logo"
            src={eyeLogo}
            {...(clickableLogo
              ? { onClick: () => navigate("/admin/clinics") }
              : {})}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex", md: "flex" },
            }}
          >
            {canReachPages &&
              navbarPages.map((page) => (
                <Button
                  key={page.title}
                  onClick={() => navigate(page.path)}
                  sx={{
                    my: 1.5,
                    mx: 4,
                    color: "#E2772E",
                    display: "block",
                    textTransform: "capitalize",
                    fontSize: 20,
                    ...(location.pathname === page.path && {
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textUnderlineOffset: 4,
                    }),
                    "&:hover": {
                      ...(location.pathname === page.path && {
                        textDecoration: "underline",
                        textUnderlineOffset: 4,
                      }),
                    },
                  }}
                >
                  {page.title}
                </Button>
              ))}
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
            mr={2}
          >
            <Box py={{ xs: 1 }} display={"flex"} alignItems={"center"}>
              <UserMenuAdminWrapper canReachPages={canReachPages} />
              <Box
                sx={{
                  display: { xs: "block", sm: "none", md: "none" },
                }}
              >
                <TabSwitcher
                  navbarPages={navbarPages}
                  location={location}
                  menuColor={"#E2772E"}
                />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
);
export default NavbarAdmin;
