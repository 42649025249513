// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import { forwardRef, useEffect } from "react";
import EditButton from "../../../../components/UI/EditButton";

const ClinicsCard = forwardRef(
  (
    {
      row,
      isSelected,
      headCells,
      handleCheckboxClick,
      handleCardClick,
      isActive,
      handleReference,
      handleEditClick,
    },
    ref
  ) => {
    useEffect(() => {
      if (ref && ref.current) {
        handleReference(true);
      }
    }, [ref, handleReference]);
    return (
      <Card
        elevation={0}
        key={row.id}
        sx={{
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor:
            isActive === row.id
              ? "#202338"
              : !isSelected
                ? "#282C3C"
                : "#E2772E",
          color: "white",
          opacity: isActive === row.id ? "1" : !isSelected ? "0.4" : "1",
          // height: { xs: 89, sm: 52 },
          height: 52,
          display: "flex",
          justifyContent: "center",
        }}
        onClick={(event) => handleCardClick(event, row)}
        ref={ref}
      >
        <Grid container px={2} py={1} display={"flex"} alignItems={"center"}>
          <Grid item xs={2}>
            <CustomCheckbox
              checkedFill={isActive === row.id ? "white" : "#E2772E"}
              checkedStroke={isActive === row.id ? "#202338" : "white"}
              checked={isSelected}
              onClick={(event) => {
                event.stopPropagation();
                handleCheckboxClick(event, row.id);
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            display={"flex"}
            flexDirection={{ md: "space-between" }}
            alignItems={"center"}
          >
            <Grid item xs={11}>
              {/* <Typography
                sx={{
                  // display: { xs: "block", xxs: "none", md: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[0].label}:</b> {row.name}
              </Typography> */}
              <Typography
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                sx={{
                  // display: { xs: "none", xxs: "block", md: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                {row.name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              display={"flex"}
              justifyContent={"right"}
              alignItems={"center"}
            >
              {/* <Typography
                sx={{
                  display: { xs: "block", xxs: "none", md: "none" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[1].label}:</b> {row.language}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block", md: "block" },
                  fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
                }}
                textTransform="capitalize"
              >
                {row.language}
              </Typography> */}
              <EditButton handleEditClick={() => handleEditClick(row)} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default ClinicsCard;
