const Square = ({ size = 8 }) => (
  <div
    style={{
      backgroundColor: "black",
      border: "1px solid black",
      height: size,
      width: size,
    }}
  />
);

export default Square;
