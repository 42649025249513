// Library methods
import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PanelGrid = React.memo(
  ({ elem, index, D15Colors, row, isLiveUpdate }) => (
    <Grid
      sx={{
        background: elem === -1 ? "#f2f1f4" : D15Colors[elem],
      }}
      key={index}
      item
      xs={12 / (row.length + 4 || 1)}
    >
      <Box height={isLiveUpdate ? 30 : 35}></Box>
    </Grid>
  )
);
const ColorVisionGraph = ({ data, isLiveUpdate = false }) => {
  const { t } = useTranslation();
  const firstRow = data?.state?.row1 || [];
  const secondRow = data?.state?.row2 || [];
  const thirdrow = Array.from({ length: 16 }, (_, i) => i);
  const D15Colors = data?.state?.colors || [];

  const titleStyles = useMemo(
    () => ({
      marginTop: isLiveUpdate ? 2 : undefined,
      marginBottom: isLiveUpdate ? 0.5 : undefined,
      fontWeight: isLiveUpdate ? "bold" : undefined,
    }),
    [isLiveUpdate]
  );

  return (
    <>
      <Typography style={titleStyles}>
        {isLiveUpdate ? t("word_original_colors") : t("word_remaining_colors")}
      </Typography>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        mb={isLiveUpdate ? 0 : 1}
      >
        {firstRow.length > 0 &&
          firstRow.map((elem, index) => (
            <PanelGrid
              key={index}
              elem={elem}
              index={index}
              D15Colors={D15Colors}
              row={firstRow}
              isLiveUpdate={isLiveUpdate}
            />
          ))}
      </Grid>
      <Typography style={titleStyles}>
        {isLiveUpdate ? t("word_current_progress") : t("word_final_results")}
      </Typography>
      <Grid container display="flex" justifyContent="space-between">
        {secondRow.length > 0 &&
          secondRow.map((elem, index) => (
            <PanelGrid
              key={index}
              elem={elem}
              index={index}
              D15Colors={D15Colors}
              row={secondRow}
              isLiveUpdate={isLiveUpdate}
            />
          ))}
      </Grid>
      <Typography style={titleStyles}>{t("word_expected_output")}</Typography>
      <Grid container display="flex" justifyContent="space-between">
        {thirdrow.length > 0 &&
          thirdrow.map((elem, index) => (
            <PanelGrid
              key={index}
              elem={elem}
              index={index}
              D15Colors={D15Colors}
              row={thirdrow}
              isLiveUpdate={isLiveUpdate}
            />
          ))}
      </Grid>
    </>
  );
};

export default ColorVisionGraph;
