import { useCallback, useEffect, useRef, useState } from "react";
import {
  getPortalHub,
  subscribeToAdmin,
  // unsubscribeFromAdmin,
} from "../services/SignalR";
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { isProduction } from "../utils/envHelper";
import { webServiceUrl } from "../utils/servicesHelper";
import { useAuth0 } from "@auth0/auth0-react";

export const useAdminSignalR = (
  handleDeviceListUpdated
  //   setIsLoadingSignalR
) => {
  const { getAccessTokenSilently } = useAuth0();
  const [connection, setConnection] = useState(null);
  const reconnectedRef = useRef(true);

  // connection to signalR
  useEffect(() => {
    const signalRConnection = async () => {
      try {
        const hubName = await getPortalHub();
        const newConnection = new HubConnectionBuilder()
          .configureLogging(isProduction ? LogLevel.None : LogLevel.Debug)
          .withUrl(`${webServiceUrl}/${hubName?.properties?.hub}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              return (Math.random() + 0.2) * 10000;
            },
          })
          .build();

        setConnection(newConnection);
      } catch (e) {
        //console.log(e);
      }
    };
    signalRConnection();
  }, [setConnection]);

  const onSignalRConnected = useCallback(async () => {
    if (connection && connection.state === HubConnectionState.Connected) {
      try {
        //subscribe to clinic
        const token = await getAccessTokenSilently();
        const response = await subscribeToAdmin(token, connection);

        const data = JSON.parse(response);
        // console.log(data);
        if (data?.state === true) {
          // console.log("handle before list updated");
          connection?.off("DeviceListUpdated");
          connection.on("DeviceListUpdated", function (data) {
            // console.log(data);
            handleDeviceListUpdated(data);
          });
        }
      } catch (e) {
        //console.log("Could not subscribe to clinic.");
      }
    }
  }, [connection, getAccessTokenSilently, handleDeviceListUpdated]);

  // establish the connection
  useEffect(() => {
    if (connection && connection?.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then((result) => {
          // console.log("Connected!");
          onSignalRConnected();

          connection.onreconnecting(() => {
            // console.log("Reconnecting...");
            // Show loading spinner during reconnection
            reconnectedRef.current = false;
            // setIsLoadingSignalR(true);
          });

          connection.onreconnected(async (connectionId) => {
            // Hide loading spinner if connection closes
            // setIsLoadingSignalR(false);
            console.log(
              `Reconnected. Connection Id: ${connectionId} or ${connection?.connectionId}`
            );
            console.log(connection);
            await onSignalRConnected();
            reconnectedRef.current = true;
          });
        })
        .catch((e) => {
          console.error("Connection failed: ", e);
        });

      connection.onclose((e) => {
        if (e) {
          console.error(`Connection closed with error: ${e}`);
          // Try to restart the connection
          if (connection.state === HubConnectionState.Disconnected) {
            // Set loading state to true when attempting to reconnect
            // setIsLoadingSignalR(true);
            connection
              .start()
              .then(() => {
                onSignalRConnected();
                // Reset loading state when reconnected successfully
                // setIsLoadingSignalR(false);
              })
              .catch((e) => {
                console.error("Connection start failed after disconnect: ", e);
                // Reset loading state if reconnection fails
                // setIsLoadingSignalR(false);
              });
          }
        } else {
          console.warn("Connection closed.");
        }
      });
    }

    return () => {
      if (connection) {
        console.warn("connection is gonna stop");
        connection.stop();
        // const clearupSignalR = async () => {
        //   const token = await getAccessTokenSilently();
        //   const res = await unsubscribeFromAdmin(token, connection);
        //   console.log(res);
        //   connection.stop();
        // };
        // clearupSignalR();
      }
    };
    //   }, [connection, onSignalRConnected, setIsLoadingSignalR]);
  }, [connection, getAccessTokenSilently, onSignalRConnected]);

  return { connection, reconnectedRef };
};
