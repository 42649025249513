import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

// Components
import Navbar from "../../UI/Navbar";
import Footer from "../../UI/Footer";
import LayoutHeightContext from "../../../contexts/LayoutHeight";
import { useCallback, useEffect, useRef, useState } from "react";
import useClinic from "../../../hooks/useClinic";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getClinics } from "../../../services/Clinic";
import { allLanguageMap, languageMap } from "../../../i18n/languages";

const Layout = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    clinicId,
    clinicLang,
    setClinicLang,
    setClinicLicensing,
    setPatientDefaultLang,
  } = useClinic();
  const { i18n } = useTranslation();
  const navbarRef = useRef(null);
  const footerRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(null);
  const [footerHeight, setFooterHeight] = useState(null);

  const changeClinicLanguage = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const { settings, licensing } = await getClinics(token, clinicId);
      const getObjectKey = (obj, value) => {
        return Object.keys(obj).find((key) => obj[key].val === value);
      };
      if (settings?.clinicDefaultLanguage) {
        const langKey = getObjectKey(
          languageMap,
          settings.clinicDefaultLanguage
        );
        i18n.changeLanguage(langKey);
        setClinicLang(langKey);
      }
      if (settings?.patientDefaultLanguage)
        setPatientDefaultLang(
          getObjectKey(allLanguageMap, settings.patientDefaultLanguage)
        );
      if (licensing) setClinicLicensing(licensing);
    } catch (e) {}
  }, [
    clinicId,
    getAccessTokenSilently,
    i18n,
    setClinicLang,
    setClinicLicensing,
    setPatientDefaultLang,
  ]);
  useEffect(() => {
    if (clinicLang) i18n.changeLanguage(clinicLang);
  }, [clinicLang, i18n]);

  useEffect(() => {
    changeClinicLanguage();
  }, [changeClinicLanguage]);

  return (
    <LayoutHeightContext.Provider value={{ navbarHeight, footerHeight }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Navbar
          ref={navbarRef}
          onHeightChange={(height) => {
            setNavbarHeight(height);
          }}
        />
        <Outlet />
        <Footer
          ref={footerRef}
          onHeightChange={(height) => {
            setFooterHeight(height);
          }}
        />
      </Box>
    </LayoutHeightContext.Provider>
  );
};

export default Layout;
