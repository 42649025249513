// Library methods
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI Components
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";

const TabSwitcher = ({ navbarPages, location, menuColor }) => {
  // states init
  const [menu, setMenu] = useState(null);

  // clinic menu handling
  const menuClick = (event) => setMenu(event.currentTarget);
  const menuClose = () => setMenu(null);

  const navigate = useNavigate();

  return (
    <>
      {/* menu switcher button */}
      <Button
        onClick={menuClick}
        sx={{
          display: { xs: "block", sm: "inline" },
          minWidth: { xs: 0, sm: 64 },
          mx: { xs: 0, sm: 0, md: 4 },
          color: menuColor ?? "#E7EBF8",
          fontSize: 20,
          ...(Boolean(menu) && {
            fontWeight: "bold",
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }),
          "&:hover": {
            ...(Boolean(menu) && {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            }),
          },
        }}
      >
        <div className="flex space-x-2 items-center">
          <MenuIcon />
        </div>
      </Button>

      {/*  switcher menu, when open */}
      <Popover
        sx={{ mt: 4 }}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={menuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { width: "100%" },
        }}
        classes={{
          paper: "py-4 px-2",
        }}
      >
        <Grid container spacing={2} px={2} pt={1} flexDirection={"column"}>
          {navbarPages.map((page) => (
            <Grid
              item
              xs={12}
              display="flex"
              key={page.title}
              style={{ padding: 0 }}
            >
              <Button
                onClick={() => {
                  navigate(page.path);
                  menuClose();
                }}
                sx={{
                  color: "#202338",
                  display: "block",
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: "bold",
                  ...(location === page.title && {
                    textDecoration: "underline",
                    textUnderlineOffset: 4,
                  }),
                  "&:hover": {
                    ...(location === page.title && {
                      textDecoration: "underline",
                      textUnderlineOffset: 4,
                    }),
                  },
                }}
              >
                {page.title}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default TabSwitcher;
