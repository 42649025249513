import { useState, useEffect, useCallback } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);

  const handleResize = useCallback(() => {
    if (ref?.current) {
      setDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  useEffect(() => {
    // Observe the element
    const element = ref?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => handleResize());
    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResize, ref.current]); // Re-run if the ref changes

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Initialize dimensions
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dimensions;
};

export default useResizeObserver;
