import { useTranslation } from "react-i18next";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { setupTheme } from "../utils/themeHelper";
import cache from "./rtl/cacheRtl";

const ThemeProviderWrapper = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={setupTheme(i18n.dir(), i18n.language)}>
        <div dir={i18n.dir()}>
          <CssBaseline />
          {children}
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProviderWrapper;
