// Library methods
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownLeftAndUpRightToCenter,
  faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

// Styles
import { ModalPaperProps } from "../../../styles/muiStylesHelper";
import { useTranslation } from "react-i18next";

const ExpandDevicesModal = ({ open, onClose, children }) => {
  // modal fullScreen on small screens
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          ...ModalPaperProps,
          maxWidth: { xs: "100%", md: "100%" },
          width: { xs: "100%", md: "600px" },
          height: { xs: "100%", md: "90%" },
        },
      }}
      maxWidth={false}
    >
      <DialogContent>
        <Box padding={1} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <FontAwesomeIcon icon={faVrCardboard} size="lg" />
            <Typography
              style={{ fontWeight: "bold" }}
              variant="h5"
              component="h2"
            >
              {t("devices_title")}
            </Typography>
          </Box>
          <IconButton
            aria-label="close-modal"
            size="medium"
            sx={{
              color: "#202338",
              width: 42,
              height: 42,
              mx: 0,
            }}
            onClick={onClose}
          >
            <FontAwesomeIcon size="1x" icon={faDownLeftAndUpRightToCenter} />
          </IconButton>
        </Box>
        <Box padding={1}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandDevicesModal;
