// // Library methods
import { useTranslation } from "react-i18next";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

// MUI Components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// Styles
import {
  ModalCustomAdd,
  ModalCustomCancel,
} from "../../../styles/muiStylesHelper";

// Utilities
import {
  ReportEachSectionContext,
  CommentAPInfoContext,
  CommentContext,
  CommentModalContext,
} from "../../../contexts/CommentContext";
import { addCommentReport } from "../../../services/Exam";
import {
  ReportDataContext,
  ReportInfoDataContext,
} from "../../../contexts/ReportDataContext";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";

const AddCommentModal = ({ isColorBlind }) => {
  // internationalization
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  // contexts
  const { refetchComment } = useContext(ReportDataContext);
  const { comments } = useContext(ReportEachSectionContext);
  const { getReportInfoData } = useContext(ReportInfoDataContext);
  const { forBackendPdf } = useContext(ValidatePDFContext);

  const titles = useContext(CommentContext);
  const apiBasicInfo = useContext(CommentAPInfoContext);
  const { sectionId } = useContext(ReportEachSectionContext);
  const { commentModalSectionId, setCommentModalSectionId } =
    useContext(CommentModalContext);
  const maxLength = 200;

  // const commentTextRef = useRef("");

  const enumOptionsRef = useRef(
    Object.keys(titles)
      .slice(0, -1)
      .map((key) => key)
  );

  const latestComment = comments?.length ? comments[0] : null;
  const [toggleState, setToggleState] = useState(
    enumOptionsRef.current.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: !!(latestComment && latestComment.conditions.includes(curr)),
      }),
      {}
    )
  );

  const [commentValue, setCommentValue] = useState("");

  const shouldDisable = useMemo(
    () => !commentValue || !commentValue?.trim(),
    [commentValue]
  );

  const handleChange = (e) => {
    setCommentValue(e.target.value);
  };

  const handleToggle = (event) => {
    setToggleState({
      ...toggleState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = async (isSaved) => {
    if (isSaved) {
      // close
      setCommentModalSectionId("");
      // Add a comment
      // extract conditions except the last item which means 'other condition'.
      const keys = Object.keys(toggleState);
      const conditions = keys
        .filter((key) => toggleState[key])
        .map((key) => `${key}`);

      // replace one or more newlines with a single space
      const replacedComment = commentValue?.replace(/\n+/g, " ");
      const commentData = {
        content: replacedComment,
      };

      if (conditions.length)
        commentData["conditions"] = `[${conditions.map(
          (condition) => `"` + condition + `"`
        )}]`;

      try {
        const token = await getAccessTokenSilently();
        await addCommentReport(
          token,
          apiBasicInfo.clinicId,
          apiBasicInfo.patientId,
          apiBasicInfo.examId,
          sectionId,
          commentData
        );
        refetchComment();
        getReportInfoData();
      } catch (e) {
        console.log(e);
      }
    }
    // close
    else setCommentModalSectionId("");
    // handleClickClose(isSaved, commentTextRef.current.value);
  };

  useEffect(() => {
    if (commentModalSectionId === sectionId) setCommentValue("");
  }, [commentModalSectionId, sectionId]);

  return (
    <Dialog
      open={!forBackendPdf && commentModalSectionId === sectionId}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(event.target.value);
        }
      }}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          width: { xs: "80%", sm: "70%", md: "480px" },
          margin: "auto",
        },
      }}
    >
      <DialogTitle id="form-dialog-title" sx={{ fontWeight: "bold" }}>
        {t("add_comment")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid
            item
            xs={!isColorBlind ? 6 : 12}
            display="flex"
            flexDirection="column"
          >
            <TextField
              value={commentValue}
              autoFocus
              margin="dense"
              id="Input comments"
              label={t("comment_text")}
              type="text"
              fullWidth
              multiline
              minRows={4}
              maxRows={4}
              color="warning"
              inputProps={{ maxLength: maxLength }}
              onChange={handleChange}
            />
            <Typography
              textAlign="right"
              color={commentValue?.length === maxLength ? "red" : "inherit"}
            >{`${commentValue?.length ?? 0}/${maxLength}`}</Typography>
          </Grid>
          {!isColorBlind && (
            <Grid item xs={6}>
              <FormGroup>
                {enumOptionsRef.current?.map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Switch
                        checked={toggleState[option]}
                        onChange={handleToggle}
                        name={option}
                        color="warning"
                      />
                    }
                    label={titles[option]}
                    labelPlacement="start"
                    sx={{ margin: 0, textAlign: "right" }}
                  />
                ))}
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} sx={ModalCustomCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleClose(true)}
          sx={{ ...ModalCustomAdd(), fontSize: 15 }}
          disabled={shouldDisable}
        >
          {t("button_add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AddCommentModal);
