// Library methods
import { useContext, useMemo } from "react";

// MUI Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// utilities
import { Tooltip, createTheme, useMediaQuery } from "@mui/material";
import { ExamTypeContext } from "../../../../contexts/ExamPropertyContext";
import { useTranslation } from "react-i18next";

const CustomCell = ({
  clinicProperty,
  headCell,
  tooltipProperty = null,
  isScreenSm = false,
}) => {
  const textStyleForCells = useMemo(
    () => ({
      fontSize: {
        xs: "12px",
        sm: "12px",
        md: "16px",
        lg: "1rem",
      },
      textAlign: "center",
    }),
    []
  );
  const memoized = useMemo(
    () => (
      <Grid
        item
        xs={headCell?.gridSize}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          px={1.5}
          sx={{
            ...textStyleForCells,
            minWidth: "100%",
          }}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {isScreenSm && <b>{headCell?.label}:</b>} {clinicProperty}
        </Box>
      </Grid>
    ),
    [
      clinicProperty,
      headCell?.gridSize,
      headCell?.label,
      isScreenSm,
      textStyleForCells,
    ]
  );
  return (
    <>
      {tooltipProperty ? (
        <Tooltip title={tooltipProperty}>{memoized}</Tooltip>
      ) : (
        memoized
      )}
    </>
  );
};

const CustomCells = ({ headCells = [], clinicInfo, size, isScreenSm }) => {
  const { t } = useTranslation();
  const { titles } = useContext(ExamTypeContext);
  const memoized = useMemo(
    () => (
      <Grid
        container
        item
        xs={12}
        sm={size}
        display={{ sx: "block", sm: "flex" }}
        justifyContent={"space-around"}
        border={0.5}
        borderRadius={2}
        height={50}
        alignItems="center"
      >
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell?.id}
            clinicProperty={clinicInfo?.[headCell.propertyName]}
            headCell={headCell}
            tooltipProperty={
              headCell?.tooltipProperty
                ? Object.keys(clinicInfo?.[headCell.tooltipProperty]).map(
                    (key) => (
                      <Typography
                        key={key}
                      >{`${t(titles[key])}: ${clinicInfo?.[headCell.tooltipProperty]?.[key]}`}</Typography>
                    )
                  )
                : headCell?.isClinicName
                  ? clinicInfo?.[headCell?.propertyName]
                  : null
            }
            isScreenSm={isScreenSm}
          />
        ))}
      </Grid>
    ),
    [clinicInfo, headCells, size, isScreenSm, titles, t]
  );
  return <>{memoized}</>;
};

const ClinicCard = ({ clinicInfo, headCells }) => {
  const theme = createTheme({});
  // This checks if the screen size is below the 'sm' breakpoint
  const isScreenSm = useMediaQuery(theme.breakpoints.down("md"));
  const firstHeadCells = headCells.slice(0, 4);
  const secondHeadCells = headCells.slice(4);

  // because of the device section being split, we needed to add a grid offset to each of the items inside the card
  return (
    <Grid
      container
      px={{ md: 0, lg: 2 }}
      py={0}
      sx={{ display: "flex", alignItems: "center", flexWrap: "no-wrap" }}
    >
      <Grid item xs={12}>
        <Grid
          container
          item
          py={0}
          sx={{
            display: { xs: "grid", sm: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={2}
          xs={12}
        >
          <Grid item xs={12} display={{ sx: "block", sm: "flex" }}>
            <CustomCells
              headCells={firstHeadCells}
              clinicInfo={clinicInfo}
              size={7.5}
              isScreenSm={isScreenSm}
            />
            {!isScreenSm && <Grid item xs={0.5}></Grid>}

            <CustomCells
              headCells={secondHeadCells}
              clinicInfo={clinicInfo}
              size={4}
              isScreenSm={isScreenSm}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClinicCard;
