// Library methods
import { Box } from "@mui/system";
import { BlobServiceClient } from "@azure/storage-blob";
import { useParams } from "react-router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// Contexts
import {
  CommentAPInfoContext,
  CommentModalContextProvider,
  CommentTitleProvider,
} from "../../contexts/CommentContext";
import {
  ReportDataContext,
  ReportInfoDataContext,
} from "../../contexts/ReportDataContext";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// Components
import ReportPdf from "../../components/UI/Report";
import SnackbarMessage from "../../components/UI/SnackbarMessage";
import ReportPanel from "../../containers/Report/ReportPanel";

// Utilities
import useClinic from "../../hooks/useClinic";
import {
  AllFileTypes,
  ErrorMessages,
  generateReportFile,
  getAccessInfo,
  getReportInfo,
} from "../../services/Exam";
import { ReportStatus } from "../../utils/examReportsHelper";
import { getClinics } from "../../services/Clinic";

const Report = () => {
  // router params
  const { patientId, examId } = useParams();
  // translation
  const { t } = useTranslation();
  // clinic context
  const { clinicId, setClinicLicensing } = useClinic();
  // auth
  const { getAccessTokenSilently } = useAuth0();
  // state
  // const [hasNoPdf, setHasNoPdf] = useState(false);
  const [fileInfo, setFileInfo] = useState({
    pdf: {
      hasBlobStorageError: false,
      // reportInfoStatus: null,
      isDownloading: false,
      openFailedToast: false,
      accessInfo: null,
      reportInfoData: null,
    },
    dicom: {
      hasBlobStorageError: false,
      // reportInfoStatus: null,
      isDownloading: false,
      openFailedToast: false,
      accessInfo: null,
      reportInfoData: null,
    },
  });
  // It's true when report info request itself fails.
  const [reportInfoError, setReportInfoError] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);
  const [shouldDisplayDicomButton, setShouldDisplayDicomButton] =
    useState(true);

  const isfirstLoading = useRef(true);

  const {
    isLoading,
    patientName,
    patient,
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    comments1,
    comments2,
    sectionId1,
    sectionId2,
    refetchComment,
    durationStartTime1,
    durationStartTime2,
    duration1,
    duration2,
    shouldDisplayReport,
    clinicTimezone,
  } = ReportPdf(false, clinicId, patientId, examId);

  // Retrieve access information for blob storage
  const getBlobAccessInfo = useCallback(
    async (fileType, signal) => {
      try {
        const token = await getAccessTokenSilently();
        const accessInfo = await getAccessInfo(
          token,
          clinicId,
          patientId,
          examId,
          fileType,
          signal
        );
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (newFileInfo[fileType]?.hasBlobStorageError !== undefined)
            newFileInfo[fileType].hasBlobStorageError = false;
          if (newFileInfo[fileType]?.accessInfo !== undefined)
            newFileInfo[fileType].accessInfo = accessInfo;
          return newFileInfo;
        });
        return accessInfo;
      } catch (e) {
        if (fileInfo?.[fileType]?.hasBlobStorageError)
          setFileInfo((prev) => {
            const newFileInfo = { ...prev };
            if (newFileInfo[fileType]?.hasBlobStorageError)
              fileInfo[fileType].hasBlobStorageError = true;
          });
        throw e;
      }
    },
    [clinicId, examId, fileInfo, getAccessTokenSilently, patientId]
  );

  const getReportInfoData = useCallback(
    async (signal) => {
      try {
        const token = await getAccessTokenSilently();
        const reportInfo = await getReportInfo(
          token,
          clinicId,
          patientId,
          examId,
          signal
        );

        setReportInfoError(false);
        if (reportInfo?.length) {
          // const newReportInfo = {};
          // This object is to store the data to check the specific file format(type) is to be generated or not.
          // Each property is gonna be true when the report info doesn't have any info for the specific file types or it was failed to generate or just unavaiable.
          const fileTypeStatusChecker = Object.keys(AllFileTypes).reduce(
            (acc, key) => {
              acc[key] = false;
              return acc;
            },
            {}
          );

          const existingFileTypes = {};
          // If the information of each file type is included in the response of the reportInfo request.
          reportInfo.forEach((fileData) => {
            if (typeof fileData?.type === "string") {
              const fileType = fileData.type.toLowerCase();
              // newReportInfo[fileData.type] = fileData;
              setFileInfo((prev) => {
                const newFileInfo = { ...prev };
                if (
                  newFileInfo[fileType] &&
                  newFileInfo[fileType].reportInfoData !== undefined
                ) {
                  newFileInfo[fileType].reportInfoData = fileData;
                }

                return newFileInfo;
              });

              existingFileTypes[fileType] = true;

              if (
                fileData.status === ReportStatus.Failed ||
                fileData.status === ReportStatus.Unavailable
              ) {
                // Add the file types which is to be generate.
                fileTypeStatusChecker[fileType] = true;
              } else if (fileData.status !== ReportStatus.Ready)
                fileTypeStatusChecker[fileType] = false;
            }
          });

          // We'll regenerate lacked files on demand only at once.
          if (isfirstLoading.current === false) return;
          // The file types of which there's no information in the response from the beginning
          Object.keys(AllFileTypes).forEach((type) => {
            if (!existingFileTypes[type]) fileTypeStatusChecker[type] = true;
          });

          // Generate files on demand. (Never generated or Unavailable or Failed)
          Object.keys(fileTypeStatusChecker).forEach((type) => {
            if (fileTypeStatusChecker[type]) {
              // licensing check that is for users to be able to generate DICOM file
              // This alos should be triggered concurrently in this loop
              const licensingCheckAndDownload = async () => {
                const { licensing } = await getClinics(token, clinicId);
                if (licensing) setClinicLicensing(licensing);
                if (
                  type === AllFileTypes.dicom &&
                  licensing.canUseDicomReports !== true
                )
                  return setShouldDisplayDicomButton(false);
                await generateReportFile(
                  token,
                  clinicId,
                  patientId,
                  examId,
                  AllFileTypes[type]
                );
              };
              licensingCheckAndDownload();
            }
          });
        }
      } catch (e) {
        console.error(e);
        if (e === ErrorMessages.ReportInfoError) {
          setReportInfoError(true);
        } else if (e === ErrorMessages.ReportFileGenrationError) {
          console.error(e);
        }
        // setHasPdfBlobStorageError(true);
      } finally {
        isfirstLoading.current = false;
      }
    },
    [clinicId, examId, getAccessTokenSilently, patientId, setClinicLicensing]
  );

  // Check continuously if blob info to download pdf is ready or not.
  // Since report page gets open
  useEffect(() => {
    const controller = new AbortController();

    const intervalTime = 3000; // Interval between requests (3 seconds)

    const intervalId = setInterval(async () => {
      if (
        (fileInfo.dicom.reportInfoData?.status === ReportStatus.Ready ||
          !shouldDisplayDicomButton) &&
        fileInfo.pdf.reportInfoData?.status === ReportStatus.Ready
      ) {
        clearInterval(intervalId);
        return;
      }

      try {
        await getReportInfoData(controller.signal);
        setReportInfoError(false);
      } catch (e) {
        setReportInfoError(true);
      }
    }, intervalTime);

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [
    shouldDisplayDicomButton,
    fileInfo.dicom.reportInfoData?.status,
    fileInfo.pdf.reportInfoData?.status,
    getReportInfoData,
  ]);

  const downloadPdfBlob = async (fileType) => {
    setFileInfo((prev) => {
      const newFileInfo = { ...prev };
      if (
        newFileInfo?.[fileType] &&
        newFileInfo[fileType].isDownloading !== undefined
      )
        newFileInfo[fileType].isDownloading = true;
      return newFileInfo;
    });
    try {
      const accessInfo = await getBlobAccessInfo(fileType);
      const access = accessInfo?.access;
      if (!accessInfo || !access) {
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (
            newFileInfo?.[fileType] &&
            newFileInfo[fileType].isDownloading !== undefined
          )
            newFileInfo[fileType].isDownloading = false;
          return newFileInfo;
        });
        throw new Error("Invalid access information");
      }
      const blobServerClient = new BlobServiceClient(
        `${access?.url}${access.sasKey}`
      );
      const containerClient = blobServerClient.getContainerClient(
        access.containerName
      );
      const blobClient = containerClient
        .getBlobClient(accessInfo.fileName)
        .getBlockBlobClient();
      const downloadedRes = await blobClient.download();
      const blob = await downloadedRes.blobBody;
      if (blob) {
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (
            newFileInfo?.[fileType] &&
            newFileInfo[fileType].isDownloading !== undefined
          )
            newFileInfo[fileType].isDownloading = false;
          return newFileInfo;
        });
        return {
          blob: blob,
          fileName: accessInfo.recommendedFileName,
        };
      }
      return null;
    } catch (e) {
      console.error(e);
      setOpenFailedToast(true);
      return null;
    } finally {
      setFileInfo((prev) => {
        const newFileInfo = { ...prev };
        if (
          newFileInfo?.[fileType] &&
          newFileInfo[fileType].isDownloading !== undefined
        )
          newFileInfo[fileType].isDownloading = false;
        return newFileInfo;
      });
    }
  };

  const downloadPdf = async (fileType) => {
    const blobData = await downloadPdfBlob(fileType);
    if (!blobData) {
      // setHasNoPdf(true);
      // warning modal
      return;
    }
    const blob = blobData.blob;
    if (blob && blob.size > 0) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = blobData.fileName;
      link.click();
    } else {
      // warning modal
    }
  };

  return (
    <CommentTitleProvider>
      <CommentAPInfoContext.Provider
        value={{
          clinicId: clinicId,
          patientId: patientId,
          examId: examId,
        }}
      >
        <CommentModalContextProvider>
          <ReportDataContext.Provider
            value={{
              isLoading: isLoading,
              patientName: patientName,
              patient: patient,
              exam: exam,
              examReport: examReport1,
              examReport2: examReport2,
              eyeReport: eyeReport1,
              eyeReport2: eyeReport2,
              comments: comments1,
              comments2: comments2,
              sectionId: sectionId1,
              sectionId2: sectionId2,
              refetchComment: refetchComment,
              durationStartTime: durationStartTime1,
              durationStartTime2: durationStartTime2,
              duration: duration1,
              duration2: duration2,
              clinicTimezone: clinicTimezone,
            }}
          >
            <ReportInfoDataContext.Provider
              value={{ getReportInfoData: getReportInfoData }}
            >
              <Box
                sx={() => PageContainerWrapper()}
                px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                mb={{ xs: 2 }}
              >
                <ReportPanel
                  downloadPdf={downloadPdf}
                  shouldDisplayReport={shouldDisplayReport}
                  fileInfo={fileInfo}
                  reportInfoError={reportInfoError}
                  shouldDisplayDicomButton={shouldDisplayDicomButton}
                />
                {/* Edit exam success/failure toasts */}
                <SnackbarMessage
                  open={openFailedToast}
                  onClose={() => setOpenFailedToast(false)}
                  success={false}
                  text={t("pdf_download_failed")}
                />
              </Box>
            </ReportInfoDataContext.Provider>
          </ReportDataContext.Provider>
        </CommentModalContextProvider>
      </CommentAPInfoContext.Provider>
    </CommentTitleProvider>
  );
};

export default Report;
