// Library methods
import { withAuthenticationRequired } from "@auth0/auth0-react";

// MUI Components
import Box from "@mui/material/Box";

// Components
import Loader from "../../UI/Loader";

// Styles
import { PageContainerWrapper } from "../../../styles/muiStylesHelper";

const ProtectedRoute = ({ component, isPdf = false }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Box sx={PageContainerWrapper()}>{!isPdf && <Loader />}</Box>
    ),
  });

  return <Component />;
};

export default ProtectedRoute;
