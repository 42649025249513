// Library methods
import React from "react";

// MUI Components
import { Grid, Typography } from "@mui/material";

// Utilities
import styles from "./index.module.css";

const PelliRobson = ({ examData }) => {
  const letters = examData?.state?.letters ?? [];
  const displayedLetters = letters.flatMap((letter) =>
    letter.letters.map((char) => ({
      color:
        char?.State === "GuessedCorrectly"
          ? "#13A321"
          : char?.State === "Skipped"
            ? "#ACA8A8"
            : char?.State === "None"
              ? "#202338"
              : "#B21515", // Added a missing '#' before 'B21515'
      value: char?.Value,
    }))
  );

  return (
    <Grid className={styles.gridContainer}>
      {letters?.length > 0 &&
        displayedLetters.map((letter, index) => {
          return (
            <Grid item key={index} className={styles.gridItem}>
              <Typography
                variant="h5"
                color={letter.color}
                fontWeight={500}
                fontSize={"20px"}
              >
                {letter.value}
              </Typography>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default PelliRobson;
