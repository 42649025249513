// Library methods
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import Navbar from "../../components/UI/Navbar";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

const NotFound = () => {
  // internationalization
  const { t } = useTranslation();

  const notFoundText = (
    <Typography variant="h3" pt={20}>
      404 {t("page_not_found")}!
    </Typography>
  );

  return (
    <div>
      <Navbar canReachPages={false} clickableLogo={true} />
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          {notFoundText}
        </Box>
      </Box>
    </div>
  );
};

export default NotFound;
