// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const ClinicInfoSection = ({ clinic, numberOfUsers, isLoading }) => {
  const { t } = useTranslation();
  const memoizedDate = useMemo(() => {
    const dateObj = new Date(clinic?.creationDate);

    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    // Ensure the year is in YYYY format with leading zeros if necessary
    const year = String(dateObj.getFullYear()).padStart(4, "0");

    return `${month}-${day}-${year}`;
  }, [clinic?.creationDate]);

  const lang = useMemo(
    () =>
      typeof clinic?.settings?.clinicDefaultLanguage === "string"
        ? t(
            "language_" +
              clinic?.settings?.clinicDefaultLanguage.charAt(0).toLowerCase() +
              clinic?.settings?.clinicDefaultLanguage.slice(1)
          )
        : "N/A",
    [clinic?.settings?.clinicDefaultLanguage, t]
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={{ xs: 6 }}
      px={3}
      py={2}
      sx={{ border: "1px solid rgba(0,0,0,0.2)", borderRadius: 3 }}
    >
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          pb={0}
          sx={{ display: "flex", gap: 1 }}
          style={{ paddingTop: "10px" }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold" }}
          >
            {t("word_language")}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            sx={{ textTransform: "capitalize" }}
          >
            {lang}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{ display: "flex", gap: 1 }}
          style={{ paddingTop: "10px" }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold" }}
          >
            {t("of_users")}
          </Typography>
          <Typography component="div" variant="body1">
            {isLoading ? <Skeleton sx={{ width: 50 }} /> : numberOfUsers}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{ display: "flex", gap: 1 }}
          style={{ paddingTop: "10px" }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold" }}
          >
            {t("date_created")}
          </Typography>
          <Typography component="div" variant="body1">
            {memoizedDate}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClinicInfoSection;
