import { useState, useEffect } from "react";

import { breakpoints } from "../styles/breakpoints";

const useIsMobile = (deviceSize) => {
  const [isMobile, setIsMobile] = useState(
    deviceSize.width <= breakpoints.small
  );

  useEffect(() => {
    const mobile = deviceSize.width <= breakpoints.small;
    setIsMobile(mobile);
  }, [deviceSize.width]);

  return isMobile;
};

export default useIsMobile;
