// Library methods
import {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from "lodash";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import DeleteClinicModal from "./DeleteClinicModal";
import ClinicsCard from "./ClinicsCard";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import PageIntro from "../../../components/UI/PageIntro";
import ListHeader from "../../../components/UI/ListHeader";
import Loader from "../../../components/UI/Loader";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";

// Utilities
import useClinic from "../../../hooks/useClinic";
import {
  addClinic,
  deleteClinic,
  updateClinic,
} from "../../../services/Clinic";
import { getComparator } from "../../../utils/tableHelper";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import LayoutHeightContext from "../../../contexts/LayoutHeight";
import ClinicModalShared from "../../../components/UI/ClinicModalShared";
import { getAllTimeZoneInfo } from "../../../utils/dateHelper";

const ClinicsList = ({
  isLoading,
  selectedForDelete,
  setSelectedForDelete,
  setSelectedClinic,
  setClinicAdded,
}) => {
  // internationalization
  const { t } = useTranslation();

  // screen height
  const { height } = useWindowDimensions();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // states init
  const [order, setOrder] = useState("asc");
  const [orderByOptions, setOrderByOptions] = useState(["name"]);
  const [page, setPage] = useState(1);

  // form field states init
  const [clinicName, setClinicName] = useState("");
  const [clinicTZ, setClinicTZ] = useState({ name: "", utcOffset: "" });
  const [editingClinic, setEditingClinic] = useState({});

  // state for add and delete patients modals
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  // clinic add toast states
  const [successToast, setSuccessToast] = useState(false);
  const [failureToast, setFailureToast] = useState(false);

  // clinic delete toast states
  const [deleteSuccessToast, setDeleteSuccessToast] = useState(false);
  const [deleteFailureToast, setDeleteFailureToast] = useState(false);

  // state for the search bar
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState("");

  const [maxNumOfRows, setMaxNumOfRows] = useState(1);
  const clinicsCardRef = useRef(null);
  const [clinicsCardHeight, setClinicsCardHeight] = useState(0);

  const { navbarHeight, footerHeight } = useContext(LayoutHeightContext);
  const [isRefSet, setIsRefSet] = useState(false);

  const allTimezoneInfo = useMemo(() => getAllTimeZoneInfo(), []);

  // filter change
  const onFilterChange = (event) => {
    if (page > 1) setPage(1);
    setSearchTerm(event.target.value);
  };

  // clear filter
  const clearSearchFilter = (event) => {
    setSearchTerm("");
  };

  // clinic info for dropdown
  const { allClinicInfo, setAllClinicInfo } = useClinic();

  const clearForm = useCallback(() => {
    setClinicName("");
    setClinicTZ({ name: "", utcOffset: "" });
  }, []);
  // add a clinic
  const insertClinic = useCallback(async () => {
    const newRow = {
      name: clinicName,
      // language: language,
      username: clinicName,
      timezone: clinicTZ.name,
    };

    // close modal
    setAddModal(false);

    // add to api
    try {
      // get token
      const token = await getAccessTokenSilently();
      const res = await addClinic(token, newRow);

      // get id generated from api
      const id = res.id;
      newRow.id = id;

      // add to table
      setAllClinicInfo((prev) => [newRow, ...prev]);

      // set clinic added to true to trigger refetch
      setClinicAdded(true);

      // success toast
      setSuccessToast(true);
    } catch (error) {
      // failure toast
      setFailureToast(true);
    }

    clearForm();
  }, [
    clinicName,
    clinicTZ,
    getAccessTokenSilently,
    setAllClinicInfo,
    clearForm,
  ]);

  const editClinic = useCallback(
    async () => {
      const newRow = {
        ...editingClinic,
        name: clinicName,
        // language: language,
        username: clinicName,
        timezone: clinicTZ.name,
      };

      setEditModal(false);

      try {
        if (!editingClinic?.id) throw new Error("invalid id");
        // get token
        const token = await getAccessTokenSilently();
        await updateClinic(token, editingClinic.id, newRow);

        // update the array with new data
        setAllClinicInfo((prev) =>
          prev.map((clinic) => (clinic.id === newRow.id ? newRow : clinic))
        );

        // success toast
        setSuccessToast(true);
      } catch (error) {
        // failure toast
        setFailureToast(true);
      }

      clearForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearForm, clinicName, clinicTZ, getAccessTokenSilently, editingClinic]
  );

  // cancel add clinic modal
  const cancelAdd = useCallback(() => {
    // close modal
    setAddModal(false);

    clearForm();
  }, [clearForm]);

  const cancelEdit = useCallback(() => {
    // close modal
    setEditModal(false);

    clearForm();
  }, [clearForm]);

  // delete selected clinics
  const deleteClinics = async () => {
    // close delete Modal
    setDeleteModal(false);

    const deletePromises = selectedForDelete.map(async (clinicId) => {
      const token = await getAccessTokenSilently();
      await deleteClinic(token, clinicId);
    });

    try {
      await Promise.all(deletePromises);
      setDeleteSuccessToast(true);
    } catch (error) {
      setDeleteFailureToast(true);
    }

    // delete from table
    setAllClinicInfo((prev) => {
      if (prev?.length) {
        return prev.filter((clinic) => !selectedForDelete.includes(clinic.id));
      }
      return prev;
    });

    // clear selected
    setSelectedForDelete([]);
  };

  // handle sort Request
  const handleRequestSort = (event, property) => {
    const isAsc =
      orderByOptions.length &&
      orderByOptions[0] === property[0] &&
      order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByOptions(property);
  };

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  // handles select all
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n.id);
      setSelectedForDelete(newSelecteds);
      return;
    }
    setSelectedForDelete([]);
  };

  // handle checkbox clicks
  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedForDelete.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedForDelete, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedForDelete.slice(1));
    } else if (selectedIndex === selectedForDelete.length - 1) {
      newSelected = newSelected.concat(selectedForDelete.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedForDelete.slice(0, selectedIndex),
        selectedForDelete.slice(selectedIndex + 1)
      );
    }

    setSelectedForDelete(newSelected);
  };

  // handle on close of add clinic modal
  const handleAddClose = useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        setAddModal(false);
        clearForm();
      }
    },
    [clearForm]
  );

  const handleEditClose = useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        setEditModal(false);
        clearForm();
      }
    },
    [clearForm]
  );

  // handle on close of delete clinic modal
  const handleDeleteClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setDeleteModal(false);
    }
  };

  const isSelected = (id) => selectedForDelete.indexOf(id) !== -1;

  // head rows
  const headCells = [
    {
      id: "name",
      label: t("clinics_table_name"),
      gridSize: 10,
      // orderUsing: ["name", "language"],
      orderUsing: ["name"],
    },
    // {
    //   id: "language",
    //   label: t("language"),
    //   gridSize: 5,
    //   orderUsing: ["language", "name"],
    // },
  ];

  // Filtered rows
  const filteredRows = useMemo(() => {
    return allClinicInfo
      ?.slice()
      ?.filter((row) => {
        if (
          searchTerm.length &&
          row.name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
        )
          return false;
        return true;
      })
      ?.sort(getComparator(order, orderByOptions));
  }, [allClinicInfo, order, orderByOptions, searchTerm]);

  const numsOfRenderedRows = Math.max(maxNumOfRows, 6);
  const rowsToRender = useMemo(() => {
    return itemsRendering(filteredRows, page, numsOfRenderedRows);
  }, [filteredRows, numsOfRenderedRows, page]);

  //  calculate the number of rendered rows
  useEffect(() => {
    if (clinicsCardRef.current) {
      const throttledFunc = throttle(() => {
        setClinicsCardHeight(clinicsCardRef.current.offsetHeight);
      }, 1000);
      throttledFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefSet, clinicsCardRef.current, clinicsCardHeight]);

  // calculate the number of rendered rows
  useEffect(() => {
    if (height && clinicsCardHeight) {
      // 32: padding of the row *2
      setMaxNumOfRows(
        Math.floor(
          (height - navbarHeight - footerHeight - clinicsCardHeight * 2) /
            (clinicsCardHeight + 32)
        )
      );
      const pageCount = pagesCount(filteredRows, Math.max(maxNumOfRows, 6));
      if (pageCount < page) setPage(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumOfRows, page, clinicsCardHeight, height]);

  const handleCardClick = useCallback(
    (event, clinic) => {
      setSelectedClinic(clinic);
      setIsActive(clinic.id);
    },
    [setSelectedClinic]
  );

  const handleEditClick = useCallback(
    (clinic) => {
      setEditModal(true);
      setClinicName(clinic.name);
      const timezone = allTimezoneInfo.find((tz) => {
        return `${tz.name} (${tz.utcOffset})`.includes(clinic.timezone);
      });
      setClinicTZ(timezone);
      setEditingClinic(clinic);
    },
    [allTimezoneInfo]
  );

  const handleReference = (val) => {
    setIsRefSet(val);
  };

  const ListElement = () =>
    isLoading ? (
      <Loader />
    ) : (
      <Grid container mt={3}>
        <Grid item xs={12}>
          <ListHeader
            headCells={headCells}
            numSelected={selectedForDelete.length}
            order={order}
            orderBy={orderByOptions?.[0] ?? "name"}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows.length}
            checkBoxGrid={2}
          />
          {rowsToRender.length > 0 ? (
            <Box mt={1}>
              {rowsToRender.map((row, i) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <ClinicsCard
                    key={i}
                    row={row}
                    isSelected={isItemSelected}
                    headCells={headCells}
                    handleCheckboxClick={handleCheckboxClick}
                    handleCardClick={handleCardClick}
                    isActive={isActive}
                    handleReference={handleReference}
                    ref={i === 0 ? clinicsCardRef : null}
                    handleEditClick={handleEditClick}
                  />
                );
              })}
            </Box>
          ) : (
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_clinics")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    );

  return (
    <>
      <PageIntro
        pageTitle={t("clinics_management_title")}
        addButtonText={t("admin_create_clinics")}
        addButtonOnClick={() => setAddModal(true)}
        deleteButtonOnClick={() => setDeleteModal(true)}
        onFilterChange={onFilterChange}
        clearSearchFilter={clearSearchFilter}
        selectedRows={selectedForDelete}
      />

      {ListElement()}

      <PaginationWrapper
        page={page}
        count={pagesCount(filteredRows, numsOfRenderedRows)}
        onChange={onPaginationChange}
      />

      {/* Add clinic modal */}
      <ClinicModalShared
        open={addModal}
        onClose={handleAddClose}
        onConfirm={() => insertClinic()}
        onCancel={() => cancelAdd()}
        clinicName={clinicName}
        setClinicName={setClinicName}
        clinicTZ={clinicTZ}
        setClinicTZ={setClinicTZ}
        title={"clinic_add_title"}
        confirmButtonTitle={"button_add"}
        allTimezoneInfo={allTimezoneInfo}
      />

      {/* Edit clinic modal */}
      <ClinicModalShared
        open={editModal}
        onClose={handleEditClose}
        onConfirm={() => editClinic()}
        onCancel={() => cancelEdit()}
        clinicName={clinicName}
        setClinicName={setClinicName}
        clinicTZ={clinicTZ}
        setClinicTZ={setClinicTZ}
        title={"clinic_edit_title"}
        confirmButtonTitle={"button_edit"}
        allTimezoneInfo={allTimezoneInfo}
      />

      {/* Delete Clinic modal */}
      <DeleteClinicModal
        open={deleteModal}
        onClose={handleDeleteClose}
        onConfirm={() => deleteClinics()}
        onCancel={() => setDeleteModal(false)}
      />

      {/* Add clinic success/failure toasts */}
      <SnackbarMessage
        open={successToast}
        onClose={() => setSuccessToast(false)}
        success
        text={t("clinics_add_success")}
      />
      <SnackbarMessage
        open={failureToast}
        onClose={() => setFailureToast(false)}
        text={t("clinics_add_error")}
      />

      {/* Delete clinic success/failure toasts */}
      <SnackbarMessage
        open={deleteSuccessToast}
        onClose={() => setDeleteSuccessToast(false)}
        success
        text={t("clinics_delete_success")}
      />
      <SnackbarMessage
        open={deleteFailureToast}
        onClose={() => setDeleteFailureToast(false)}
        text={t("clinics_delete_error")}
      />
    </>
  );
};

export default ClinicsList;
