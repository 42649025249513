export const config = (accessToken, signal) => ({
  headers: { Authorization: injectBearer(accessToken) },
  signal: signal,
});
export const pdfConfig = (signal) => ({
  signal: signal,
});

export const injectBearer = (accessToken) => `Bearer ${accessToken}`;

export const isResponseValid = (response) => {
  const data = response.data;
  if (!data.state) throw new Error(response.statusText);
  return data.data;
};

export const webServiceUrl = process.env.REACT_APP_WEB_SERVICE;
