// Styles
import { GridTypes } from "../../../utils/examReportsHelper";
import styles from "./index.module.css";

const axes = ["YNeg", "YPos", "XNeg", "XPos"];

const ChartCross = ({
  height = 312,
  width = 312,
  borderWidth = 2,
  tickSize = 6,
  tickNum = 3,
  gridType = GridTypes.G10,
}) => {
  const gridDic = {
    [GridTypes.G10]: "10",
    [GridTypes.G24]: "30",
    [GridTypes.G24C]: "30",
    [GridTypes.G30]: "30",
    [GridTypes.G120]: "80",
    [GridTypes.F120]: "60",
  };
  const renderCheckPoints = (axis) => {
    return [...Array(tickNum)].map((_, index) => (
      <div
        key={`${axis}${index + 1}`}
        className={styles[`checkPoint${axis}`]}
        style={{ "--index": index + 1, "--tick-num": tickNum }}
      />
    ));
  };

  return (
    <div
      style={{
        "--cross-esterman-height": `${height}px`,
        "--cross-esterman-width": `${width}px`,
        "--cross-esterman-border-width": `${borderWidth}px`,
        "--cross-esterman-tick-size": `${tickSize}px`,
        "--half-height": `calc((${height}px - ${borderWidth}px) / 2)`,
        "--half-width": `calc((${width}px - ${borderWidth}px) / 2)`,
      }}
    >
      <div className={styles.posX1}>{gridDic[gridType]}</div>
      <div className={styles.vl} />
      <div className={styles.hl} />
      <div className={styles.posX2}>{gridDic[gridType]}</div>
      {axes.map((axis) => renderCheckPoints(axis))}
    </div>
  );
};

export default ChartCross;
