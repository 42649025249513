// Library methods
import { useState } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import PageIntro from "../../../components/UI/PageIntro";
import DevicesCard from "../../../components/UI/DevicesCard";
import ExpandDevicesModal from "../../../components/UI/ExpandDevicesModal";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import Loader from "../../../components/UI/Loader";

// Utilities
import useClinic from "../../../hooks/useClinic";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";

// import LayoutHeightContext from "../../../contexts/LayoutHeight";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import { useDevices } from "../../../hooks/useDevices";

const DashboardDevicesList = ({
  rows,
  isLoading,
  patients,
  devicesWithExams,
  heightExceptDevicesList,
  connection,
}) => {
  // internationalization
  const { t } = useTranslation();

  // clinic context
  const { clinicId } = useClinic();

  const {
    unlinkLoading,
    linkLoading,
    unlinkDeviceModal,
    linkDeviceModal,
    unlinkSuccessMessage,
    unlinkFailureMessage,
    linkSuccessMessage,
    linkFailureMessage,
    handleUnlinkDeviceClick,
    handleLinkDeviceClick,
    checkIfDeviceHasExamInProgress,
  } = useDevices(connection, clinicId, patients, devicesWithExams);

  // states init
  const [page, setPage] = useState(1);
  const [check, setCheck] = useState("all");

  const [expandDevicesModal, setExpandDevicesModal] = useState(false);

  const { height } = useWindowDimensions();

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  // handle on close of expand devices modal
  const handleExpandDevicesClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setExpandDevicesModal(false);
    }
  };

  const selectedDevicesRows = () => {
    if (check === "linked") {
      return rows?.filter((row) => row?.associatedExam);
    } else if (check === "unlinked") {
      return rows?.filter((row) => !row?.associatedExam);
    }
    return rows;
  };

  const selectedDevicesRowsArray = selectedDevicesRows();

  const rowsToRender = itemsRendering(
    selectedDevicesRowsArray,
    page,
    selectedDevicesRowsArray?.length
  );

  const listHeight = Math.floor(height - heightExceptDevicesList - 130);
  const listMinHeight = 420;
  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="20vh" />
    ) : (
      <Grid
        container
        mt={2}
        spacing={3}
        mb={2}
        sx={
          rowsToRender.length > 3
            ? {
                overflowY: { xs: "hidden", sm: "scroll" },
                overflowX: { xs: "scroll", sm: "none" },
                flexWrap: { xs: "nowrap", sm: "wrap" },
                maxHeight: {
                  md: listHeight > listMinHeight ? listHeight : listMinHeight,
                  xs: listMinHeight,
                },
              }
            : {}
        }
      >
        {rowsToRender.length > 0 ? (
          rowsToRender.map((row) => {
            return (
              <Grid
                item
                sx={{ marginRight: { sm: "5px" }, flexWrap: "wrap" }}
                key={row.id}
              >
                <DevicesCard
                  key={row.id}
                  row={row}
                  handleLinkDeviceClick={handleLinkDeviceClick}
                  handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                  isExamInProgress={checkIfDeviceHasExamInProgress(row.id)}
                  devicesWithExams={devicesWithExams}
                  unlinkLoading={unlinkLoading}
                  linkLoading={linkLoading}
                  isDashboard={true}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_devices")}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );

  const ListElementExpanded = () =>
    isLoading ? (
      <Loader containerHeight="19.5vh" />
    ) : (
      <Grid container mt={3} spacing={3} mb={2}>
        {rows.length > 0 ? (
          rows.map((row) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={row.id}>
                <DevicesCard
                  key={row.id}
                  row={row}
                  handleLinkDeviceClick={handleLinkDeviceClick}
                  handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                  isExamInProgress={checkIfDeviceHasExamInProgress(row.id)}
                  devicesWithExams={devicesWithExams}
                  unlinkLoading={unlinkLoading}
                  linkLoading={linkLoading}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_devices")}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );

  return (
    <>
      <PageIntro
        rows={rows}
        // pageTitle={isLoading ? "Devices" : `Devices (${rows.length})`}
        pageTitle={t("devices")}
        showAddButton={false}
        showDeleteButton={false}
        showFilterInput={false}
        desktopMarginBottom={-2}
        setCheck={setCheck}
        expandButtonOnClick={() => setExpandDevicesModal(true)}
      />

      {ListElement()}

      <PaginationWrapper
        page={page}
        count={pagesCount(rows, 3)}
        onChange={onPaginationChange}
        shouldShrink
        display="none"
      />

      {/* Unlink device exam modal */}
      {unlinkDeviceModal}

      {/* Link device exam modal */}
      {linkDeviceModal}
      {/* Expand devices modal */}

      {/* Expand devices modal */}
      <ExpandDevicesModal
        open={expandDevicesModal}
        onClose={handleExpandDevicesClose}
        onCancel={() => setExpandDevicesModal(false)}
      >
        {ListElementExpanded()}
      </ExpandDevicesModal>

      {/* Unlink device success/failure toasts */}
      {unlinkSuccessMessage}
      {unlinkFailureMessage}
      {linkSuccessMessage}
      {linkFailureMessage}
    </>
  );
};

export default DashboardDevicesList;
