import { useContext, useEffect, useState } from "react";
import { ValidatePDFContext } from "../contexts/ValidatePDFContext";
import { ReportInfoEachSectionContext } from "../contexts/ReportInfoEachSectionContext";

const useReportInfoFontSize = () => {
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { hasFourColumns } = useContext(ReportInfoEachSectionContext);
  const [dynamicFontSize, setDynamicFontSize] = useState(
    hasFourColumns ? 14 : 15
  );
  useEffect(() => {
    const handleResize = () => {
      const letterScaleFactor =
        forBackendPdf || window.innerWidth < 1300 || window.innerWidth > 1920
          ? 1
          : window.innerWidth / 1800;
      setDynamicFontSize((hasFourColumns ? 14 : 15) * letterScaleFactor);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [forBackendPdf, hasFourColumns]);
  return { dynamicFontSize };
};

export default useReportInfoFontSize;
