// Library methods
import React from "react";
import { useTranslation } from "react-i18next";

const ResultChart = ({
  stateData = {
    colors: [],
    row1: Array.from({ length: 16 }, -1),
    row2: Array.from({ length: 16 }, (_, i) => i + 1),
  },
}) => {
  const { t } = useTranslation();
  const basePoints = [
    { x: 85, y: 145 },
    { x: 125, y: 122 },
    { x: 165, y: 100 },
    { x: 195, y: 85 },
    { x: 220, y: 75 },
    { x: 250, y: 78 },
    { x: 300, y: 95 },
    { x: 350, y: 130 },
    { x: 365, y: 190 },
    { x: 345, y: 230 },
    { x: 310, y: 260 },
    { x: 275, y: 270 },
    { x: 245, y: 270 },
    { x: 210, y: 260 },
    { x: 180, y: 245 },
    { x: 150, y: 225 },
  ];

  const baseLinePoints = [
    { xs: 230, ys: 275, xe: 180, ye: 85, label: t("Protan") },
    { xs: 195, ys: 265, xe: 210, ye: 70, label: t("Deutan") },
    { xs: null, ys: null, xe: 355, ye: 160, label: t("Tritan") },
  ];

  // Function to calculate the angle in degrees
  const calculateAngleInDeg = (x1, y1, x2, y2) => {
    const angleRad = Math.atan2(y2 - y1, x2 - x1);
    const angleDeg = (angleRad * 180) / Math.PI;
    return angleDeg;
  };

  // Define all points and lines that is to be displayed on the chart.
  const rate = 1.1; // This is to adjust the layout scale
  const allFixedPoints = basePoints.map((point) => {
    return { x: point.x * rate, y: point.y * rate };
  });

  const linePoints = baseLinePoints.map((point) => {
    return {
      xs: point.xs === null ? null : point.xs * rate,
      ys: point.ys === null ? null : point.ys * rate,
      xe: point.xe * rate,
      ye: point.ye * rate,
      label: point.label,
    };
  });

  const radius = 5 * rate;
  const width = 480 * rate;
  const height = 350 * rate;

  let skippedIndexes = new Set(); // A sorted numbers not having the same one
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    >
      {allFixedPoints.map((point, index) => (
        <circle
          key={index}
          cx={point.x}
          cy={point.y}
          r={radius}
          fill={stateData?.colors?.[index] ?? "gray"}
        />
      ))}
      {/* Fixed lines */}
      {linePoints.map((point, index) => {
        const angleToEnd = Math.atan2(
          point.ye - (point.ys ?? allFixedPoints[allFixedPoints.length - 1].y),
          point.xe - (point.xs ?? allFixedPoints[allFixedPoints.length - 1].x)
        );
        const startX =
          point.xs ??
          allFixedPoints[allFixedPoints.length - 1].x +
            radius * Math.cos(angleToEnd);
        const startY =
          point.ys ??
          allFixedPoints[allFixedPoints.length - 1].y +
            radius * Math.sin(angleToEnd);

        // This is to rotate texts
        const angleInDeg = calculateAngleInDeg(
          startX,
          startY,
          point.xe,
          point.ye
        );

        return (
          <g key={index}>
            <line
              x1={startX}
              y1={startY}
              x2={point.xe}
              y2={point.ye}
              stroke="black"
              strokeWidth="0.5"
              strokeDasharray="3"
            />
            <text
              x={(startX + point.xe) / 1.55}
              y={(startY + point.ye) / (index === 1 ? 1.9 : 2.1)}
              transform={`rotate(${angleInDeg}, ${(startX + point.xe) / 2}, ${
                (startY + point.ye) / 2
              })`}
              textAnchor="middle"
              alignmentBaseline="middle"
              fontSize={13}
            >
              {point.label}
            </text>
          </g>
        );
      })}

      {/* Dynamic lines */}
      {allFixedPoints.map((point, index) => {
        if (index + 1 >= allFixedPoints.length || skippedIndexes.has(index))
          return null;

        const currentPointIndex = stateData.row2[index];
        let nextPointIndex = -1;
        let searchIndex = index + 1;

        while (searchIndex < allFixedPoints.length) {
          if (
            stateData.row2[searchIndex] !== -1 &&
            !skippedIndexes.has(searchIndex)
          ) {
            nextPointIndex = stateData.row2[searchIndex];
            break;
          } else skippedIndexes.add(searchIndex);
          searchIndex++;
        }

        if (nextPointIndex === -1) return null;

        const angleToNext = Math.atan2(
          allFixedPoints[nextPointIndex].y -
            allFixedPoints[currentPointIndex].y,
          allFixedPoints[nextPointIndex].x - allFixedPoints[currentPointIndex].x
        );
        const xs =
          allFixedPoints[currentPointIndex].x + radius * Math.cos(angleToNext);
        const ys =
          allFixedPoints[currentPointIndex].y + radius * Math.sin(angleToNext);

        const angleToCurrent = Math.atan2(
          allFixedPoints[currentPointIndex].y -
            allFixedPoints[nextPointIndex].y,
          allFixedPoints[currentPointIndex].x - allFixedPoints[nextPointIndex].x
        );
        const xe =
          allFixedPoints[nextPointIndex].x + radius * Math.cos(angleToCurrent);
        const ye =
          allFixedPoints[nextPointIndex].y + radius * Math.sin(angleToCurrent);

        return (
          <line
            key={index}
            x1={xs}
            y1={ys}
            x2={xe}
            y2={ye}
            stroke="blue"
            strokeWidth="0.5"
          />
        );
      })}
      {allFixedPoints.map((point, index) => {
        // Calculate angle for each point
        const offSetVariable = -15;
        const angle = (index / 16) * 2 * Math.PI;
        const baseOffsetX = offSetVariable * Math.cos(angle);
        const baseOffsetY = offSetVariable * Math.sin(angle);
        const textOffsetX = index === 0 ? baseOffsetX * 2 : baseOffsetX;
        const textOffsetY =
          index === 0
            ? baseOffsetY - 10
            : index < 10
              ? baseOffsetY / 1.5
              : baseOffsetY;
        return (
          <text
            key={index}
            x={point.x + textOffsetX}
            y={point.y + textOffsetY}
            textAnchor="middle"
            fontSize="12"
            fill="black"
          >
            {index === 0 ? t("Reference Cap") : index}
          </text>
        );
      })}
    </svg>
  );
};

export default ResultChart;
