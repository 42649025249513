// Library methods
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Utilities
import { calculateAge } from "../../../../utils/examReportsHelper";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import EditButton from "../../../../components/UI/EditButton";
import { getLocalTime } from "../../../../utils/dateHelper";

const PatientCard = forwardRef(
  (
    {
      row,
      isSelected,
      isActive,
      headCells,
      handleCheckboxClick,
      handleEditClick,
      handleCardClick,
    },
    ref
  ) => {
    // internationalization
    const { t } = useTranslation();
    const sexTitles = {
      Male: t("gender_male"),
      Female: t("gender_female"),
      Undefined: t("word_na"),
    };

    // Translate language property in the patient to specified language.
    const lang = row?.language
      ? "language_" +
        row.language.charAt(0).toLowerCase() +
        row.language.slice(1)
      : "-";

    const nextScheduledExamDate = useMemo(() => {
      if (row?.nextScheduledExamDate)
        return getLocalTime(row.nextScheduledExamDate);
      return "-";
    }, [row]);

    // sx specific are for small mobile screens
    return (
      <Card
        elevation={0}
        key={row.id}
        sx={{
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor:
            isActive === row.id
              ? "#202338"
              : !isSelected
                ? "#282C3C"
                : "#E2772E",
          color: "white",
          opacity: isActive === row.id ? "1" : !isSelected ? "0.4" : "1",
          height: { sm: 52 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={(event) => handleCardClick(event, row.id)}
        ref={ref}
      >
        <Grid
          container
          py={2}
          sx={{
            display: { xs: "grid", sm: "flex" },
            gridTemplateColumns: {
              xs: "10% 75% 10%",
              sm: "none",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={1.5} sm={1}>
            <CustomCheckbox
              checkedFill={isActive === row.id ? "white" : "#E2772E"}
              checkedStroke={isActive === row.id ? "#202338" : "white"}
              checked={isSelected}
              onClick={(event) => {
                event.stopPropagation();
                handleCheckboxClick(event, row.id);
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={9.5}
            sm={10}
            display={{ sx: "block", sm: "flex" }}
          >
            {headCells.map((cell, index) => (
              <Grid item xs={12} sm={cell.gridSize} key={index}>
                <Typography
                  sx={{
                    display: { xs: "block", sm: "none" },
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "1rem",
                    },
                  }}
                >
                  <b>{cell.label}:</b>{" "}
                  {index === 0
                    ? `${row.firstName} ${row.lastName}`
                    : index === 1
                      ? row?.age ?? calculateAge(row.dateOfBirth)
                      : index === 2
                        ? sexTitles[row.sex]
                        : index === 3
                          ? t(lang)
                          : nextScheduledExamDate}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    display: { xs: "none", sm: "block" },
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "1rem",
                    },
                    textAlign:
                      index === 4 && nextScheduledExamDate === "-"
                        ? "center"
                        : undefined,
                  }}
                >
                  {index === 0
                    ? `${row.firstName} ${row.lastName}`
                    : index === 1
                      ? row?.age ?? calculateAge(row.dateOfBirth)
                      : index === 2
                        ? sexTitles[row.sex]
                        : index === 3
                          ? t(lang)
                          : nextScheduledExamDate}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={1}>
            <EditButton handleEditClick={() => handleEditClick(row)} />
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default PatientCard;
