import axios from "axios";
import {
  config,
  isResponseValid,
  webServiceUrl,
} from "../../utils/servicesHelper";

const getDevices = async (accessToken) =>
  axios
    .get(`${webServiceUrl}/devices`, config(accessToken))
    .then((response) => {
      const devices = isResponseValid(response);
      return devices;
    })
    .catch((error) => {
      throw error;
    });

const getClinicDevices = async (accessToken, clinicId, signal) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/devices`,
      config(accessToken, signal)
    )
    .then((response) => {
      const devices = isResponseValid(response);
      return devices;
    })
    .catch((error) => {
      throw error;
    });

const updateDevice = async (accessToken, deviceId, deviceData) =>
  axios
    .put(
      `${webServiceUrl}/devices/${deviceId}`,
      deviceData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const assignDevice = async (accessToken, deviceId, clinicData) =>
  axios
    .post(
      `${webServiceUrl}/devices/${deviceId}/clinic`,
      clinicData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const unassignDevice = async (accessToken, deviceId) =>
  axios
    .delete(`${webServiceUrl}/devices/${deviceId}/clinic`, config(accessToken))
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const linkExamToDevice = async (accessToken, clinicId, deviceId, examData) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/devices/${deviceId}/exam`,
      examData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const unlinkExamFromDevice = async (accessToken, clinicId, deviceId) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/devices/${deviceId}/exam`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getClinicDevicesById = async (accessToken, deviceId) =>
  axios
    .get(`${webServiceUrl}/devices/${deviceId}/clinic`, config(accessToken))
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

export {
  getDevices,
  getClinicDevices,
  updateDevice,
  assignDevice,
  unassignDevice,
  linkExamToDevice,
  unlinkExamFromDevice,
  getClinicDevicesById,
};
