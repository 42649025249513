import { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

const ExamTypeContext = createContext();
const EyeContext = createContext();
const AlgorithmContext = createContext();
const ExamUtilContext = createContext({ examUtils: [] });
const PositionContext = createContext();

const ExamTypeProvider = ({ children }) => {
  const vf = "visual-field";
  const color = "color-vision";
  const contrast = "contrast-sensitivity";
  const va = "visual-acuity";
  const examTypes = {
    typeVisualField: "VisualField",
    // esterman: "Esterman",
    typeColorVision: "ColorVision",
    typeContrastSensitivity: "ContrastSensitivity",
    TYPE_VISUAL_ACUITY: "VisualAcuity",
    path: {
      VisualField: vf,
      ColorVision: color,
      ContrastSensitivity: contrast,
      Esterman: "esterman",
      FULL_120: "full-120",
      VisualAcuity: va,
    },
    sectionsPath: {
      visualFieldSections: vf,
      colorVisionSections: color,
      contrastSensitivitySections: contrast,
      visualAcuitySections: va,
      // Esterman: "esterman", -> visualField
    },
    titles: {
      VisualField: "report_visual_field",
      ColorVision: "color_vision_title",
      ContrastSensitivity: "contrast_sensitivity_title",
      Esterman: "esterman_report_title",
      FULL_120: "word_single_field_analysis",
      VisualAcuity: "visual_acuity_title",
    },
  };

  return (
    <ExamTypeContext.Provider value={examTypes}>
      {/* {children} */}
      <EyeInfoProvider>
        <AlgorithmProvider>
          <ExamUtilProvider>{children}</ExamUtilProvider>
        </AlgorithmProvider>
      </EyeInfoProvider>
    </ExamTypeContext.Provider>
  );
};

const EyeInfoProvider = ({ children }) => {
  const eye = {
    eyeLeft: "Left",
    eyeRight: "Right",
    eyeBoth: "Both",
  };
  return <EyeContext.Provider value={eye}>{children}</EyeContext.Provider>;
};

const AlgorithmProvider = ({ children }) => {
  const algorithms = {
    pellirobson: "Pelli Robson",
    tumbling: "Tumbling E",
    esterman: "Esterman",
    full120: "FULL_120",
    landoltC: "Landolt C",
    snellen: "Snellen",
    SCREEN_FAST: "SCREEN_FAST",
    FAST_THRESHOLD: "FAST_THRESHOLD",
    STANDARD_THRESHOLD: "STANDARD_THRESHOLD",
    FULL_THRESHOLD: "FULL_THRESHOLD",
    ESTERMAN_FAST: "ESTERMAN_FAST",
    FULL_120: "FULL_120",
    PELLI_ROBSON: "PELLI_ROBSON",
    TUMBLING_E: "TUMBLING_E",
    LANDOLT_C: "LANDOLT_C",
    SNELLEN: "SNELLEN",
  };
  const positions = {
    near: "Near",
    far: "Far",
    NEAR: "NEAR",
    FAR: "FAR",
  };

  return (
    <AlgorithmContext.Provider value={algorithms}>
      <PositionContext.Provider value={positions}>
        {children}
      </PositionContext.Provider>
    </AlgorithmContext.Provider>
  );
};

const ExamUtilProvider = ({ children }) => {
  const { t } = useTranslation();
  const { TYPE_VISUAL_ACUITY, titles, path } = useContext(ExamTypeContext);
  const { full120, tumbling, TUMBLING_E } = useContext(AlgorithmContext);

  // This is for exam slider card to add/edit exams
  const examUtils = [
    { label: t("exams_column_visual_field"), value: "visual-field", type: "" },
    {
      label: t("single_threshold_title"),
      value: "full-120",
      // algorithm
      type: full120,
      displayType: "Single",
    },
    {
      label: t("esterman_report_title"),
      value: "esterman",
      type: "ESTERMAN_FAST",
      displayType: "Fast",
    },
    {
      label: t("color_vision_title"),
      value: "color-vision",
      type: "D_15",
      displayType: "D-15",
    },
    {
      label: t("contrast_sensitivity_title"),
      value: "contrast-sensitivity",
      type: "PELLI_ROBSON",
      displayType: "Pelli Robson",
    },
    {
      label: t(titles[TYPE_VISUAL_ACUITY]),
      value: path[TYPE_VISUAL_ACUITY],
      type: TUMBLING_E,
      displayType: tumbling,
    },
  ];

  return (
    <ExamUtilContext.Provider value={{ examUtils: examUtils }}>
      {children}
    </ExamUtilContext.Provider>
  );
};
export {
  ExamTypeContext,
  ExamTypeProvider,
  EyeContext,
  EyeInfoProvider,
  AlgorithmContext,
  AlgorithmProvider,
  ExamUtilContext,
  PositionContext,
};
