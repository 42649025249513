// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Assets
import LessThan05 from "../../../../../assets/images/deviation-patterns/LessThan05.png";
import LessThan1 from "../../../../../assets/images/deviation-patterns/LessThan1.png";
import LessThan2 from "../../../../../assets/images/deviation-patterns/LessThan2.png";
import LessThan5 from "../../../../../assets/images/deviation-patterns/LessThan5.png";

const DeviationChartLegend = () => (
  <>
    <Box display="flex" alignItems="center" mb={0.5}>
      <img
        src={LessThan5}
        alt="Deviation percentile less than 5 representation"
        width="15"
        height="18"
      />
      <Typography ml={2} variant="body1" noWrap>
        {"< 5%"}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mb={0.5}>
      <img
        src={LessThan2}
        alt="Deviation percentile less than 2 representation"
        width="15"
        height="18"
      />
      <Typography ml={2} variant="body1" noWrap>
        {"< 2%"}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mb={0.5}>
      <img
        src={LessThan1}
        alt="Deviation percentile less than 1 representation"
        width="15"
        height="18"
      />
      <Typography ml={2} variant="body1" noWrap>
        {"< 1%"}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center">
      <img
        src={LessThan05}
        alt="Deviation percentile less than 0.5 representation"
        width="15"
        height="18"
      />
      <Typography ml={2} variant="body1" noWrap>
        {"< 0.5%"}
      </Typography>
    </Box>
  </>
);

export default DeviationChartLegend;
