import SvgIcon from "@mui/material/SvgIcon";
import { Checkbox } from "@mui/material";

const CommonCoordination = "3";
const CheckboxSize = "18";
const CheckboxBorderRadius = "3px";

/**
 * Customized designs for icons.
 */
const CustomUncheckedIcon = ({
  unCheckedFill = "white",
  unCheckedStroke = "black",
}) => (
  <SvgIcon>
    <rect
      x={CommonCoordination}
      y={CommonCoordination}
      width={CheckboxSize}
      height={CheckboxSize}
      fill={unCheckedFill}
      strokeWidth="1"
      stroke={unCheckedStroke}
      rx={CheckboxBorderRadius}
      ry={CheckboxBorderRadius}
    />
  </SvgIcon>
);

const CustomCheckedIcon = ({
  checkedFill = "black",
  checkedStroke = "white",
}) => (
  <SvgIcon>
    <rect
      x={CommonCoordination}
      y={CommonCoordination}
      width={CheckboxSize}
      height={CheckboxSize}
      fill={checkedFill}
      strokeWidth="1.5"
      stroke={checkedFill}
      rx={CheckboxBorderRadius}
      ry={CheckboxBorderRadius}
    />
    <path
      d="M6 12l3 3 7-7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      stroke={checkedStroke}
      fill="none"
    />
  </SvgIcon>
);

/**
 * Customized Checkbox
 */
const CustomCheckbox = ({
  checked,
  onChange,
  onClick,
  checkedFill,
  checkedStroke,
  uncheckedFill,
  uncheckedStroke,
}) => {
  return (
    <Checkbox
      icon={
        <CustomUncheckedIcon
          uncheckedFill={uncheckedFill}
          uncheckedStroke={uncheckedStroke}
        />
      }
      checkedIcon={
        <CustomCheckedIcon
          checkedFill={checkedFill}
          checkedStroke={checkedStroke}
        />
      }
      checked={checked}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

export default CustomCheckbox;
