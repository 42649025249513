import { useTranslation } from "react-i18next";
import ClinicCard from "./ClinicCard";
import ListHeader from "../../../components/UI/ListHeader";
import { useCallback, useMemo, useState } from "react";
import { getComparator } from "../../../utils/tableHelper";
import { Grid, createTheme, useMediaQuery } from "@mui/material";

const ClinicsList = ({ clinicsInfo = [] }) => {
  const orderByClinicName = ["clinicName"];
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderByOptions, setOrderByOptions] = useState([orderByClinicName]);
  const theme = createTheme({});
  // This checks if the screen size is below the 'sm' breakpoint
  const isScreenSm = useMediaQuery(theme.breakpoints.down("md"));

  const headCells = [
    {
      id: 0,
      label: t("clinic_modal_clinic_name"),
      gridSize: 3,
      propertyName: "clinicName",
      orderUsing: ["clinicName"],
      isClinicName: true,
    },
    {
      id: 1,
      label: t("total_patients"),
      gridSize: 3,
      propertyName: "totalPatients",
      orderUsing: ["totalPatients"],
    },
    {
      id: 2,
      label: t("total_exams"),
      gridSize: 3,
      propertyName: "totalOfTotalExams",
      tooltipProperty: "totalExams",
      orderUsing: ["totalOfTotalExams"],
    },
    {
      id: 3,
      label: t("terminated_exams"),
      gridSize: 3,
      propertyName: "totalExamsTerminated",
      tooltipProperty: "examsTerminated",
      orderUsing: ["totalExamsTerminated"],
    },
    {
      id: 4,
      label: t("created_patients"),
      gridSize: 6,
      propertyName: "patientsCreated",
      orderUsing: ["patientsCreated"],
    },

    {
      id: 5,
      label: t("created_exams"),
      gridSize: 6,
      propertyName: "totalExamsCreated",
      tooltipProperty: "examsCreated",
      orderUsing: ["totalExamsCreated"],
    },
  ];

  const headCellsGroup = [headCells.slice(0, 4), headCells.slice(4)];

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc =
        orderByOptions.length &&
        orderByOptions[0] === property[0] &&
        order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderByOptions(property);
    },
    [order, orderByOptions]
  );

  const rowsToRender = useMemo(() => {
    return clinicsInfo.sort(getComparator(order, orderByOptions));
  }, [clinicsInfo, order, orderByOptions]);

  const ListHeaderComponent = (cells) => (
    <ListHeader
      headCells={cells}
      order={order}
      orderBy={orderByOptions?.[0] ?? "clinicName"}
      onRequestSort={handleRequestSort}
      rowCount={rowsToRender.length}
      showCheckbox={false}
      needTextAlignCenter={true}
      shouldTakeUpSpace={false}
    />
  );
  return (
    <Grid minHeight={"70vh"}>
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Grid item xs={7.5}>
          {ListHeaderComponent(headCellsGroup[0])}
        </Grid>
        {!isScreenSm && <Grid xs={0.5}></Grid>}
        <Grid item xs={4}>
          {ListHeaderComponent(headCellsGroup[1])}
        </Grid>
      </Grid>
      {rowsToRender.map((clinicInfo, index) => {
        return (
          <ClinicCard
            key={index}
            clinicInfo={clinicInfo}
            headCells={headCells}
          />
        );
      })}
    </Grid>
  );
};

export default ClinicsList;
