import ReportSectionContent from "../../../components/UI/ReportSectionContent";
import EyeReport from "./EyeReport";

const ContrastSensitivityReport = () => {
  return (
    <>
      <ReportSectionContent EyeReport={<EyeReport />} />
    </>
  );
};

export default ContrastSensitivityReport;
