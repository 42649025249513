// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// invalid page for users without a clinic
export default function UnauthorizedPdf() {
  const errorMessage = (
    <>
      <Typography variant="h5">Unauthorized request</Typography>
    </>
  );

  const errorText = (
    <Typography variant="h3" mb={4}>
      Error!
    </Typography>
  );

  return (
    <div>
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={10}
        >
          {errorText}
          {errorMessage}
        </Box>
      </Box>
    </div>
  );
}
