// Library methods
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Components
import PatientsList from "../../containers/Patients/PatientsList";
import ExamsList from "../../containers/Patients/ExamsList";
import DevicesList from "../../containers/Patients/DevicesList";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";

// Utilities
import useClinic from "../../hooks/useClinic";
import { getPatients } from "../../services/Patient";
import { getExams } from "../../services/Exam";
import { getClinicDevices } from "../../services/Device";
import { sortByDate } from "../../utils/dateHelper";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import LayoutHeightContext from "../../contexts/LayoutHeight";
import { useSignalR } from "../../hooks/useSignalR";
import { useDeviceAppVersion } from "../../contexts/DeviceContext";
import { LiveUpdateStatusProvider } from "../../contexts/LiveUpdateStatusContext";
import LiveUpdateModal from "../../components/UI/LiveUpdateModal";
import { getClinics } from "../../services/Clinic";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";

const Patients = () => {
  // preselected user, in case the access to this page comes from the report page
  const location = useLocation();
  const locationState = location.state;
  const fromReport = locationState?.fromReport || false;

  // routing
  const navigate = useNavigate();

  // clinic context
  const { clinicId, clinicName, setClinicSettings } = useClinic();

  // auth
  const { getAccessTokenSilently } = useAuth0();

  // init state
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState(
    locationState?.user ? [locationState?.user] : []
  );
  const [patients, setPatients] = useState([]);
  const [exams, setExams] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicesOptions, setDevicesOptions] = useState([]);

  // indicates if the data has been fetched, or if it is still being fetched
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [isLoadingExams, setIsLoadingExams] = useState(true);
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);
  const [isLoadingSignalR, setIsLoadingSignalR] = useState(false);

  // signalR related states
  const [devicesWithExams, setDevicesWithExams] = useState([]);

  // page states
  const [patientsTablePage, setPatientsTablePage] = useState(
    fromReport || false
      ? parseInt(sessionStorage.getItem("patientsListPage"), 10) || 1
      : 1
  );
  const [examsTablePage, setExamsTablePage] = useState(
    fromReport || false
      ? parseInt(sessionStorage.getItem("examsListPage"), 10) || 1
      : 1
  );
  const { navbarHeight, footerHeight } = useContext(LayoutHeightContext);
  const devicesListRef = useRef(null);
  const [devicesListHeight, setDevicesListHeight] = useState(null);

  useDeviceAppVersion(devices);

  // new app version warning for devices
  // const [newAppVersionWarningPopup, setNewAppVersionWarningPopup] =
  //   useState(false);
  // const [deviceAppVersion, setDeviceAppVersion] = useState(null);

  // handles the device list updated event
  const handleDeviceListUpdated = useCallback(async (data) => {
    // get all devices
    const devicesData = JSON.parse(data);

    // get the devices from this clinic with associated exams
    const associatedDevicesList = devicesData?.data?.filter(
      (device) => device.associatedExam !== undefined
    );
    const devicesWithoutExams = devicesData?.data?.filter(
      (device) => device.associatedExam === undefined
    );
    setDevices(devicesData?.data || []);
    setDevicesWithExams(associatedDevicesList);
    setDevicesOptions(devicesWithoutExams);
  }, []);

  // signalR setup
  const { connection, reconnectedRef } = useSignalR(
    clinicId,
    handleDeviceListUpdated,
    setIsLoadingSignalR
  );
  // online status
  const isOnline = useOnlineStatus(connection, clinicId);

  useEffect(() => {
    console.log(`Online status changed. Now online: ${isOnline}`);
    if (isOnline) {
      if (!reconnectedRef.current) return;
      const timeoutId = setTimeout(() => {
        setIsLoadingSignalR(false);
      }, 2000); // Delay setting the state by 2 sec to sync with signalR reconnection

      return () => clearTimeout(timeoutId);
    } else setIsLoadingSignalR(true);
  }, [isOnline, reconnectedRef]);

  // method that fetches patients data
  const fetchPatientsCall = useCallback(
    async (signal) => {
      setIsLoadingPatients(true);
      if (!clinicName) navigate("/invalid");
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        // get patients list
        const patientsList = await getPatients(token, clinicId, signal);
        setPatients(patientsList);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoadingPatients(false);
      }
    },
    [clinicId, clinicName, getAccessTokenSilently, navigate]
  );

  // method that fetches devices data
  const fetchDevices = useCallback(
    async (signal) => {
      if (!clinicName) navigate("/invalid");
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        // get the devices from this clinic
        const devicesData = await getClinicDevices(token, clinicId, signal);

        // devices without an associated exam
        const devicesWithoutExam = devicesData.filter(
          (device) => device.associatedExam === undefined
        );

        // devices with an associated exam
        const devicesWithExam = devicesData.filter(
          (device) => device.associatedExam !== undefined
        );

        // set the states
        setDevices(devicesData);
        setDevicesOptions(devicesWithoutExam);
        setDevicesWithExams(devicesWithExam);
      } catch (e) {
        //console.log(e);
      }
    },
    [clinicId, clinicName, getAccessTokenSilently, navigate]
  );

  // method that does the devices data fetching, showing the loading indicator
  const fetchDevicesCall = useCallback(
    async (signal) => {
      try {
        setIsLoadingDevices(true);
        await fetchDevices(signal);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoadingDevices(false);
      }
    },
    [fetchDevices]
  );

  // method that fetches exams data
  const fetchExams = useCallback(
    async (signal) => {
      if (!clinicName) navigate("/invalid");
      if (selectedPatients.length !== 1) return;
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        // get the patient and the patient's exams
        const examsData = await getExams(
          token,
          clinicId,
          selectedPatients[0],
          signal
        );
        setExams(sortByDate(examsData, "creationDate"));
      } catch (e) {
        //console.log(e);
      }
    },
    [clinicId, clinicName, getAccessTokenSilently, navigate, selectedPatients]
  );

  // method that does the exam data fetching, showing the loading indicator
  const fetchExamsCall = useCallback(
    async (signal) => {
      try {
        setIsLoadingExams(true);
        await fetchExams(signal);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoadingExams(false);
      }
    },
    [fetchExams]
  );

  // initial data fetching
  useEffect(() => {
    const controller = new AbortController();
    fetchPatientsCall(controller.signal);
    fetchDevicesCall(controller.signal);
    return () => {
      controller.abort();
    };
  }, [fetchPatientsCall, fetchDevicesCall]);

  // exams data fetching
  useEffect(() => {
    const controller = new AbortController();
    if (selectedPatients.length !== 1) {
      setExams([]);
      return;
    }
    fetchExamsCall(controller.signal);
    return () => {
      controller.abort();
    };
  }, [fetchExamsCall, selectedPatients]);

  // setting page data except the time when first rendering (mount) occurs.
  const handleExamsTablePageUpdate = useCallback(() => {
    sessionStorage.setItem("patientsListPage", patientsTablePage);
    sessionStorage.setItem("examsListPage", 1);
    setExamsTablePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatients]);

  useUpdateEffect(handleExamsTablePageUpdate, [selectedPatients]);

  // track url state
  useEffect(() => {
    sessionStorage.setItem("previousURL", JSON.stringify(location.pathname));
    sessionStorage.setItem(
      "clinicDetails",
      JSON.stringify({
        id: clinicId,
        name: clinicName,
      })
    );
  }, [location.pathname, clinicId, clinicName]);

  // clinic settings
  useEffect(() => {
    const fetchClinicSettings = async () => {
      try {
        const token = await getAccessTokenSilently();

        const { settings } = await getClinics(token, clinicId);

        if (settings) {
          setClinicSettings(settings);
        }
      } catch (e) {}
    };
    fetchClinicSettings();
  }, [clinicId, getAccessTokenSilently, setClinicSettings]);

  // getting the data for the selected patient
  const selectedPatient = patients.find(
    (patient) => patient.id === selectedPatients[0]
  );

  // navigate to the exam's report page
  const handleViewReport = (patientId, examId) => {
    navigate(`/patients/${patientId}/exam/${examId}`);
  };

  return (
    <LiveUpdateStatusProvider>
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 6, lg: 6 }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: "column",
              sm: "column",
              ls: "column",
              md: "row",
            },
            // sus
            minHeight: {
              md: "640px",
            },
            height: {
              md: `calc(100vh - ${footerHeight}px - ${navbarHeight}px - ${devicesListHeight}px)`,
            },
          }}
        >
          <Grid item xs={12} sm={12} md={4.5}>
            <PatientsList
              isLoading={isLoadingPatients}
              rows={patients}
              setRows={setPatients}
              page={patientsTablePage}
              setPage={setPatientsTablePage}
              selectedForDelete={selectedForDelete}
              setSelectedForDelete={setSelectedForDelete}
              setSelectedPatients={setSelectedPatients}
              selectedPatients={selectedPatients}
              heightExceptListsContainer={
                navbarHeight + footerHeight + devicesListHeight
              }
              fromReport={fromReport}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            display="flex"
            justifyContent="center"
          >
            <Divider
              sx={{
                maxBorderRightWidth: { xs: 1600, sm: 1200 },
                marginTop: 2,
                borderColor: "#E2772E90",
                height: { xs: 5, sm: 5, md: "93%" },
                borderRightWidth: { md: 3 },
                maxWidth: { xs: 1600, sm: 1200, md: 0 },
                display: { xs: "none", sm: "block" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <ExamsList
              connection={connection}
              isLoading={isLoadingExams || isLoadingDevices}
              selectedPatients={selectedPatients}
              patients={patients}
              patient={selectedPatient}
              patientId={selectedPatient?.id}
              patientName={`${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
              rows={exams}
              setRows={setExams}
              devicesOptions={devicesOptions}
              refetchDevices={fetchDevices}
              refetchExams={fetchExams}
              refetchPatients={fetchPatientsCall}
              devicesWithExams={devicesWithExams}
              handleViewReport={handleViewReport}
              page={examsTablePage}
              setPage={setExamsTablePage}
              heightExceptListsContainer={
                navbarHeight + footerHeight + devicesListHeight
              }
              isLoadingSignalR={isLoadingSignalR}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          mt={2}
          px={{ xs: 2, sm: 2, md: 6, lg: 6 }}
          mx={{ xs: -2, sm: -4, md: -6, lg: -6 }}
          sx={{ backgroundColor: "rgba(0,0,0,0.05)" }}
          // sx={{ backgroundColor: "rgba(0,0,10)" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DevicesList
                ref={devicesListRef}
                isLoading={isLoadingDevices}
                rows={devices}
                refetchDevices={fetchDevices}
                refetchExams={fetchExams}
                patients={patients}
                devicesWithExams={devicesWithExams}
                connection={connection}
                onHeightChange={(height) => {
                  setDevicesListHeight(height);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Live update modal */}
      <ValidatePDFContext.Provider value={{ forBackendPdf: false }}>
        <LiveUpdateModal
          connection={connection}
          refetchDevices={fetchDevices}
          refetchExams={fetchExams}
          rows={exams}
          isLoadingSignalR={isLoadingSignalR}
          devicesWithExams={devicesWithExams}
        />
      </ValidatePDFContext.Provider>
    </LiveUpdateStatusProvider>
  );
};

export default Patients;
