const EstermanRing = ({ height, width }) => (
  <div
    style={{
      backgroundColor: "#fff",
      border: "0.1px solid black",
      opacity: 0.15,
      height,
      borderRadius: "50%",
      MozBorderRadius: "50%",
      WebkitBorderRadius: "50%",
      width,
    }}
  />
);

export default EstermanRing;
