// Components
import Triangle from "./Triangle";
import ChartCross from "../ChartCross";
import Loader from "../../../components/UI/Loader";

// Styles
import styles from "./index.module.css";

// Utilities
import {
  GridTypes,
  calculateXPaddingNumberGraph,
  calculateYPaddingNumberGraph,
} from "../../../utils/examReportsHelper";
import {
  possibleChartPoints30,
  possibleChartPoints24Left,
  possibleChartPoints24Right,
} from "../../../utils/numberChartHelper";
import Circle from "../Circle";
import ColoredCircle from "../ColoredCircle";
import Square from "../Square";
import { ReportEachSectionContext } from "../../../contexts/CommentContext";
import { useContext } from "react";
import { EyeContext } from "../../../contexts/ExamPropertyContext";

const NumberGraph = ({
  pressedPoints,
  gridType,
  shouldDisplayTriangle = true,
  isNotRlFast = true,
  isLiveUpdate = null,
  eyeLiveUpdate = null,
}) => {
  const { eyeReport } = useContext(ReportEachSectionContext);
  const { eyeRight, eyeLeft } = useContext(EyeContext);
  const examAllPoints =
    gridType === "G_30_2"
      ? possibleChartPoints30
      : (eyeLiveUpdate ?? eyeReport) === eyeLeft
        ? possibleChartPoints24Left
        : possibleChartPoints24Right;

  const is24C = gridType === GridTypes.G24C;

  // Show loader while data is not yet processed
  if (pressedPoints.length === 0) {
    return <Loader containerHeight="312px" />;
  }

  return (
    <main className={styles.container}>
      <ChartCross gridType={gridType} />

      {/* blind spot */}
      {/* the 10-2 grid type exam shouldn't display it */}
      {shouldDisplayTriangle && (
        <div
          style={{
            position: "absolute",
            marginTop: 124,
            // If it's the left eye, the triangle should be placed at the left side of the grid. If it's right, at the right side of the grid.
            marginLeft:
              140 + ((eyeLiveUpdate ?? eyeReport) === eyeLeft ? -71 : 91),
          }}
        >
          <Triangle />
        </div>
      )}

      {/* initially, drawing a line for the values that does not have a representation on the pressedPoints array */}
      {isNotRlFast &&
        examAllPoints.map((spot, i) => {
          // check if the current points value has a representation on the pressed points array (with valid decibelValue)
          const hasValue = pressedPoints
            .filter((point) => point.decibelValue !== null) // disconsider the points that has a decibelValue equal to null
            .find((point) => point.x === spot.x && point.y === spot.y);
          if (hasValue) return null;
          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingNumberGraph(spot.x),
                paddingTop: calculateYPaddingNumberGraph(spot.y),
              }}
            >
              <p
                style={{
                  fontSize:
                    is24C &&
                    (!Number.isInteger(spot.x) || !Number.isInteger(spot.y))
                      ? "10px"
                      : "13px",
                  marginTop: "0px",
                }}
              >
                <span style={{ marginLeft: "2px" }}>-</span>
              </p>
            </div>
          );
        })}
      {/* mapping the not completed visual spots. Display an orange circle by default, but display a green circle on the "current visual spot" */}
      {isNotRlFast &&
        pressedPoints
          .filter((spot) => !spot.completed && spot.decibelValue !== null) // considering the non completed ones, with a valid decibelValue/intensity
          .map((spot, i) => {
            return (
              <div
                key={i}
                style={{
                  position: "absolute",
                  paddingLeft: calculateXPaddingNumberGraph(spot.x),
                  paddingTop: calculateYPaddingNumberGraph(spot.y),
                }}
              >
                <p
                  style={{
                    fontSize:
                      is24C &&
                      (!Number.isInteger(spot.x) || !Number.isInteger(spot.y))
                        ? "10px"
                        : "13px",
                    marginTop: "0px",
                  }}
                >
                  {!isLiveUpdate ? (
                    "-"
                  ) : (
                    <span
                      style={{
                        height: "8px",
                        width: "8px",
                        marginLeft: "2px",
                        backgroundColor: !spot.current ? "orange" : "green",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                  )}
                </p>
              </div>
            );
          })}
      {/* mapping the completed visual spots */}
      {isNotRlFast &&
        pressedPoints
          .filter((spot) => spot.completed)
          .map((spot, i) => {
            const decibelValue = spot.decibelValue;
            const ml = calculateXPaddingNumberGraph(spot.x);
            const mt = calculateYPaddingNumberGraph(spot.y);
            return (
              <div
                key={i}
                style={{
                  position: "absolute",
                  paddingLeft: is24C
                    ? 0 < spot.x && spot.x < 1
                      ? ml + 4
                      : !Number.isInteger(spot.x) && spot.y === -2
                        ? ml + 2
                        : eyeReport === eyeLeft &&
                            spot.x === -1 &&
                            spot.y === -2
                          ? ml - 1
                          : eyeReport === eyeRight &&
                              spot.x === 1 &&
                              spot.y === -2
                            ? ml + 2
                            : ml
                    : ml,
                  paddingTop: is24C
                    ? !Number.isInteger(spot.x) && spot.y === -2
                      ? mt + 4
                      : ((eyeReport === eyeLeft && spot.x === -1) ||
                            (eyeReport === eyeRight && spot.x === 1)) &&
                          spot.y === -2
                        ? mt + 2
                        : spot.y === -1.33
                          ? mt + 3
                          : mt
                    : mt,
                }}
              >
                <p
                  style={{
                    fontSize: is24C
                      ? !Number.isInteger(spot.x) && spot.y === -2
                        ? "9px"
                        : ((eyeReport === eyeLeft && spot.x === -1) ||
                              (eyeReport === eyeRight && spot.x === 1)) &&
                            spot.y === -2
                          ? "11px"
                          : !Number.isInteger(spot.x) ||
                              !Number.isInteger(spot.y)
                            ? "10px"
                            : "13px"
                      : "13px",
                    marginTop: "0px",
                  }}
                >
                  {Math.floor(decibelValue) >= 0
                    ? Math.floor(decibelValue)
                    : "<0"}
                </p>
              </div>
            );
          })}

      {!isNotRlFast &&
        pressedPoints
          // .filter((spot) => spot.seen === false && spot.completed)
          .filter((spot) => spot.seen === false && spot.completed)
          .map((spot, i) => {
            return (
              <div
                key={i}
                style={{
                  position: "absolute",
                  paddingLeft: calculateXPaddingNumberGraph(spot.x),
                  paddingTop: calculateYPaddingNumberGraph(spot.y),
                }}
              >
                <Square />
              </div>
            );
          })}
      {/* mapping the not completed visual spots. Display an orange circle by default, but display a green circle on the "current visual spot" */}
      {!isNotRlFast &&
        pressedPoints
          .filter((spot) => !spot.completed && spot.decibelValue) // considering the non completed ones, with a valid decibelValue/intensity
          .map((spot, i) => {
            return (
              <div
                key={i}
                style={{
                  position: "absolute",
                  paddingLeft: calculateXPaddingNumberGraph(spot.x),
                  paddingTop: calculateYPaddingNumberGraph(spot.y),
                }}
              >
                {!isLiveUpdate ? (
                  "-"
                ) : (
                  <ColoredCircle isOrange={!spot.current} />
                )}
              </div>
            );
          })}
      {/* mapping the completed visual spots (live update)*/}
      {/* It's used for report as a `seen` */}
      {!isNotRlFast &&
        pressedPoints
          .filter((spot) => spot.seen)
          .map((spot, i) => {
            return (
              <div
                key={i}
                style={{
                  position: "absolute",
                  paddingLeft: calculateXPaddingNumberGraph(spot.x),
                  paddingTop: calculateYPaddingNumberGraph(spot.y),
                }}
              >
                <Circle />
              </div>
            );
          })}
    </main>
  );
};

export default NumberGraph;
