// MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { ReportStatus } from "../../../utils/examReportsHelper";
import { useEffect, useRef, useState } from "react";

const PrintButton = ({
  isLoading,
  handleDownloadPdf = (type) => {},
  fileInfo,
  reportInfoError,
  fileType = "pdf",
}) => {
  const { t } = useTranslation();
  const [firstloadingDone, setFirstloadingDone] = useState(null);
  const firstLoadingIndicator = useRef(true);
  const customOptions = {
    pdf: { title: t("word_pdf") },
    dicom: { title: t("word_dicom") },
  };

  // This is to ensure the user is under the first loading before they receive report info data.
  useEffect(() => {
    if (firstLoadingIndicator.current === false) return;
    let eitherReportInfoDataIsReady = false;
    Object.keys(fileInfo).forEach((type) => {
      if (fileInfo?.[type]?.reportInfoData) eitherReportInfoDataIsReady = true;
    });
    if (eitherReportInfoDataIsReady) {
      firstLoadingIndicator.current = false;
      setFirstloadingDone(true);
    }
  }, [fileInfo, fileInfo?.[fileType], fileType]);

  return (
    <Button
      variant="outlined"
      // disabled={reportInfoStatus !== ReportStatus.Ready || isLoading}
      disabled={
        fileInfo?.[fileType]?.reportInfoData?.status !== ReportStatus.Ready ||
        isLoading ||
        reportInfoError
      }
      sx={{
        width: 250,
        height: 50,
        color: "#202338",
        borderRadius: 30,
        borderColor: "#202338",
        borderWidth: 2,
        backgroundColor: "rgba(0,0,0,0)",
        "&:hover": {
          color: "white",
          backgroundColor: "#202338",
          borderColor: "#202338",
        },
        ...(isLoading ||
        reportInfoError ||
        fileInfo?.[fileType]?.reportInfoData?.status !== ReportStatus.Ready
          ? { pointerEvents: "none" }
          : {}),
      }}
      onClick={() => {
        handleDownloadPdf(fileType);
      }}
    >
      {/* reportInfo requests error is handled here */}
      {reportInfoError ||
      fileInfo?.[fileType]?.hasBlobStorageError ||
      fileInfo?.[fileType]?.reportInfoData?.status === ReportStatus.Failed ? (
        <>
          <Typography
            variant="h6"
            className="normal-case"
            sx={{ fontWeight: "regular" }}
          >
            {t("word_failed")}
          </Typography>
        </>
      ) : fileInfo?.[fileType]?.reportInfoData?.status ===
          ReportStatus.Unavailable ||
        // reportInfoData is gonna be null before receiving the reportInfo endpoint response
        // That's why we need to check if it's under the first loading or not
        (firstloadingDone && !fileInfo?.[fileType]?.reportInfoData?.status) ? (
        <>
          <Typography
            variant="h6"
            className="normal-case"
            sx={{ fontWeight: "regular" }}
          >
            {t("word_unavailable")}
          </Typography>
        </>
      ) : fileInfo?.[fileType]?.reportInfoData?.status ===
          ReportStatus.Pending ||
        fileInfo?.[fileType]?.reportInfoData?.status ===
          ReportStatus.InProgress ||
        isLoading ||
        !firstloadingDone ? (
        <>
          <Typography
            variant="h6"
            className="normal-case"
            sx={{ fontWeight: "regular" }}
          >
            {t("word_creating") + " " + fileType?.toUpperCase()}
          </Typography>
          <CircularProgress
            size={24}
            sx={{ ml: 1, color: "#9e9e9e" }}
            disableShrink
          />
        </>
      ) : (
        <>
          <DownloadIcon sx={{ mr: 1 }} />
          <Typography
            variant="h6"
            className="normal-case"
            sx={{ fontWeight: "regular" }}
          >
            {customOptions[fileType].title}
          </Typography>
          {fileInfo?.[fileType]?.isDownloading && (
            <CircularProgress
              size={24}
              sx={{ ml: 1, color: "#9e9e9e" }}
              disableShrink
            />
          )}
        </>
      )}
    </Button>
  );
};

export default PrintButton;
