// MUI Components
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackbarMessage = ({
  open,
  onClose,
  success,
  text,
  autoHideDuration = 3000,
}) => (
  <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
    <Alert
      onClose={onClose}
      severity={success ? "success" : "error"}
      sx={{ width: "100%" }}
    >
      {text}
    </Alert>
  </Snackbar>
);

export default SnackbarMessage;
