import { createContext, useContext, useState, useEffect } from "react";
import { debounce } from "lodash";

const WindowDimensionsContext = createContext(null);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const WindowDimensionsProvider = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 50);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowDimensionsContext.Provider value={windowDimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export const useWindowDimensions = () => {
  const context = useContext(WindowDimensionsContext);
  if (context === null) {
    throw new Error(
      "useWindowDimensions must be used within a WindowDimensionsProvider"
    );
  }
  return context;
};
