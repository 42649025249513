// Library methods
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, useMediaQuery } from "@mui/material";

// MUI Components
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// Components

import {
  CommentContext,
  ReportEachSectionContext,
} from "../../../contexts/CommentContext";
import CommentCard from "./CommentCard";

// Utilities

const CommentsList = ({ isVisualField, isEsterman = false }) => {
  const { t } = useTranslation();
  const { comments } = useContext(ReportEachSectionContext);
  const theme = createTheme();
  const isWiderThanSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const conditionTitles = useContext(CommentContext);
  const maxHeight = 120;

  return (
    <Grid container>
      {(isVisualField || isEsterman) && <Grid item xs={1}></Grid>}
      {comments?.length > 0 ? (
        <>
          <Grid
            container
            item
            xs={isVisualField ? 8 : isEsterman ? 11 : 12}
            sm={isVisualField ? 9 : isEsterman ? 11 : 12}
            border={1}
            maxHeight={maxHeight}
            overflow="auto"
          >
            {comments.map((comment, index) => (
              <CommentCard
                key={index}
                //   username={comment.username}
                username="Sample user"
                creationDate={comment.creationDate}
                comment={comment.content}
                conditions={comment.conditions}
                // otherConditions={
                //   comment.otherConditions ? comment.otherConditions : null
                // }
                prevConditions={
                  index !== comments.length - 1
                    ? comments[index + 1].conditions
                    : []
                }
                // prevOtherConditions={
                //   index !== sortedComments.length - 1
                //     ? sortedComments[index + 1].otherConditions
                //     : null
                // }
              />
            ))}
          </Grid>
          {isVisualField && (
            <Grid
              item
              xs={3}
              sm={2}
              border={1}
              borderLeft={0}
              height={maxHeight}
            >
              {/* CommentConditon section */}
              {Object.keys(conditionTitles).length && (
                <List>
                  {Object.keys(conditionTitles)
                    .slice(0, -1)
                    .map((key, index) => (
                      <ListItem
                        key={key}
                        disablePadding
                        sx={{ marginLeft: 0.5 }}
                      >
                        <Checkbox
                          checked={comments[0].conditions.includes(key)}
                          // checked={comments[comments.length - 1].conditions.includes(
                          //   key
                          // )}
                          disabled
                          style={{ padding: 0 }}
                        />
                        <ListItemText
                          primary={conditionTitles[key]}
                          primaryTypographyProps={{
                            paddingLeft: isWiderThanSmallScreen ? 1 : 0,
                            fontSize: isWiderThanSmallScreen ? 16 : 14,
                          }}
                        />
                      </ListItem>
                    ))}
                </List>
              )}
              {/* {otherConditions && Object.keys(otherConditions).length && ( */}
              {/* <List disablePadding>
            {Object.keys().map((key, index) => (
              <ListItem key={key} disablePadding>
                <ListItemText
                  primary={key}
                  primaryTypographyProps={{
                    paddingLeft: isWiderThanSmallScreen ? 1 : 0.5,
                    fontSize: isWiderThanSmallScreen ? 16 : 14,
                  }}
                />
              </ListItem>
            ))}
          </List> */}
              {/* )} */}
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={isVisualField ? 11 : 12} pt={2} pb={2} border={1}>
          <Typography textAlign="center">{t("no_comments")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CommentsList;
