// Library methods
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirm,
  ModalPaperProps,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

import SearchBox from "../SearchBox";

const LinkDeviceModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  devicesOptions = [],
  editExamDevice,
  setEditExamDevice,
  loading,
}) => {
  // internationalization
  const { t } = useTranslation();

  const formattedDevicesOptions = useMemo(
    () =>
      devicesOptions.map((option) => {
        return { name: option?.alias, val: option?.id };
      }),
    [devicesOptions]
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = false;
    devicesOptions.forEach((option) => {
      if (option?.id === editExamDevice?.val) isIncluded = true;
    });
    return !isIncluded;
  }, [devicesOptions, editExamDevice?.val]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faLink} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("device_link_modal_title")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* Device selector */}
          <SearchBox
            data={formattedDevicesOptions}
            state={editExamDevice}
            setState={setEditExamDevice}
            label={t("device_link_modal_devices")}
            propertyBit={4}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          disabled={shouldDisable || loading}
          onClick={onConfirm}
        >
          {t("button_link")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkDeviceModal;
