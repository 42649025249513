import { FormControlLabel, Switch } from "@mui/material";
import { ModalCustomSwitch } from "../../../styles/muiStylesHelper";

const LicensingSwitch = ({
  isOn,
  setSelectedClinicLicensing,
  propertyName,
  label,
}) => {
  // translation
  return (
    <FormControlLabel
      sx={{
        marginBottom: 0.5,
        color: isOn ? "#000" : "#ACA8A8",
        "& .MuiFormControlLabel-label": { fontWeight: "600" },
      }}
      control={
        <Switch
          checked={isOn}
          onChange={(e) => {
            setSelectedClinicLicensing((prev) => {
              const safePrev = prev || {};
              return {
                ...safePrev,
                [propertyName]: e.target.checked,
              };
            });
            // setSelectedClinic((prev) => {
            //   const safePrev = prev || {};
            //   const safeLicensing = safePrev?.licensing ?? {};

            //   return {
            //     ...safePrev,
            //     licensing: {
            //       ...safeLicensing,
            //       canVideoStream: canVideoStream,
            //     },
            //   };
            // });
          }}
          sx={{
            ...ModalCustomSwitch(),
          }}
        />
      }
      label={label}
    />
  );
};
export default LicensingSwitch;
