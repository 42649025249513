// Library methods
import { useContext, useMemo } from "react";

// MUI Components
import Grid from "@mui/material/Grid";

// Components
import EyeReport from "./EyeReport";

// Utilities
import {
  CommentTitleProvider,
  ReportEachSectionContext,
} from "../../../contexts/CommentContext";
import { ReportDataContext } from "../../../contexts/ReportDataContext";

const ColorVisionReport = () => {
  const {
    sectionId,
    sectionId2,
    examReport,
    examReport2,
    eyeReport,
    eyeReport2,
    duration,
    duration2,
    comments,
    comments2,
  } = useContext(ReportDataContext);

  const ReportSectionContent = useMemo(() => {
    return (
      <CommentTitleProvider>
        <Grid container sx={{ display: "flex" }} columnSpacing={4}>
          {examReport && (
            <ReportEachSectionContext.Provider
              value={{
                sectionId: sectionId,
                examReport: examReport,
                eyeReport: eyeReport,
                duration: duration,
                comments: comments,
              }}
            >
              <Grid item xs={12} lg={6} pt={4}>
                <EyeReport />
              </Grid>
            </ReportEachSectionContext.Provider>
          )}
          {examReport2 && (
            <ReportEachSectionContext.Provider
              value={{
                sectionId: sectionId2,
                examReport: examReport2,
                eyeReport: eyeReport2,
                duration: duration2,
                comments: comments2,
              }}
            >
              <Grid item xs={12} lg={6} pt={4}>
                <EyeReport />
              </Grid>
            </ReportEachSectionContext.Provider>
          )}
        </Grid>
      </CommentTitleProvider>
    );
  }, [
    comments,
    comments2,
    duration,
    duration2,
    examReport,
    examReport2,
    eyeReport,
    eyeReport2,
    sectionId,
    sectionId2,
  ]);
  return <>{ReportSectionContent}</>;
};

export default ColorVisionReport;
