import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import blackout from "../../../../assets/images/grayscale/blackLive.jpeg";

const LiveStreamImage = ({ src, alt, deviceStageType, hmdInProximity }) => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(0);

  const updateImageSize = () => {
    if (imageRef.current) {
      const width = imageRef.current.offsetWidth;
      setImageWidth(width);
    }
  };

  const inCalibration = useMemo(
    () => deviceStageType === "Calibration",
    [deviceStageType]
  );

  useEffect(() => {
    updateImageSize();
    window.addEventListener("resize", updateImageSize);

    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  return (
    <div
      className="image-container"
      style={{ width: "100%", position: "relative" }}
    >
      <img
        ref={imageRef}
        src={inCalibration || !hmdInProximity ? blackout : src}
        alt={alt}
        style={{ width: "100%", height: imageWidth / 1.9 }}
      />
      {(inCalibration || !hmdInProximity) && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            // Offset by half the width and height of the element itself for true centering
            transform: "translate(-50%, -50%)",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
            textAlign: "center",
          }}
        >
          {!hmdInProximity ? (
            t("hmd_in_proximity")
          ) : inCalibration ? (
            t("calibration_in_progress")
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default LiveStreamImage;
