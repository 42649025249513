import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { getLocale } from "./localeHelper";
import themeInfo from "../styles/themeInfo";

const setupTheme = (direction, language) => {
  const theme = createTheme(
    {
      direction,
      ...themeInfo,
    },
    getLocale(language).mui
  );
  return responsiveFontSizes(theme);
};

export { setupTheme };
