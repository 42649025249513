const combineInt8Array = (a, b, offset) => {
  const c = new Int8Array(a.length);
  c.set(a);
  c.set(b, offset);
  return c;
};

const combineFloat32Array = (a, b) => {
  const c = new Float32Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
};

const byteToInt32 = (byte, offset) => {
  return (
    (byte[offset] & 255) +
    ((byte[offset + 1] & 255) << 8) +
    ((byte[offset + 2] & 255) << 16) +
    ((byte[offset + 3] & 255) << 24)
  );
};

const byteToInt16 = (byte, offset) => {
  return (byte[offset] & 255) + ((byte[offset + 1] & 255) << 8);
};

export { combineInt8Array, combineFloat32Array, byteToInt32, byteToInt16 };
