// Library methods
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import CommentsList from "../CommentsList";

import { ReportDataContext } from "../../../contexts/ReportDataContext";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";
import { getCurrentSectionKey } from "../../../utils/examReportsHelper";
import { sectionCompletionState } from "../../../hooks/useExamStatus";
import Watermark from "../Watermark";
import { ErrorMessageComponent } from "../ErrorMessage";
import { ReportEachSectionContext } from "../../../contexts/CommentContext";

// This component is for visual acuity and contrast sensitivity
const EyeReportContent = (props) => {
  const { ReportInfo, ExamGraph } = props;

  const { t } = useTranslation();
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { exam } = useContext(ReportDataContext);
  const { examReport } = useContext(ReportEachSectionContext);

  // algorithm version number
  const algorithmVersion = examReport?.exam?.version?.substring(0, 3);

  const currentExamSectionsKey = useMemo(
    () => getCurrentSectionKey(exam),
    [exam]
  );

  const isTerminated = useMemo(() => {
    if (
      !currentExamSectionsKey?.length ||
      !exam?.[currentExamSectionsKey[0]]?.length
    )
      return false;
    const eye = examReport?.exam?.eye;
    const section = exam?.[currentExamSectionsKey[0]].find(
      (section) => section.eye === eye
    );
    return (
      section && section.completionState === sectionCompletionState.Terminated
    );
  }, [currentExamSectionsKey, exam, examReport?.exam?.eye]);

  // build the report content
  return (
    <>
      {ReportInfo}

      {examReport?.postProcessing ? (
        <>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ my: { sx: 0, sm: 0, md: 5 } }}
            spacing={3}
          >
            {isTerminated && <Watermark py={10} />}
            {ExamGraph}
          </Grid>
          <Grid container item xs={12} mt={2}>
            <CommentsList isVisualField={false} />
          </Grid>
          {!forBackendPdf && (
            <Grid
              container
              item
              display="flex"
              xs={12}
              flexDirection="row"
              justifyContent={"end"}
              mt={2}
            >
              <Grid item xs={12}>
                <Typography textAlign={"right"}>
                  {t("version")}: {algorithmVersion}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </>
  );
};

export default EyeReportContent;
