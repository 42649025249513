// Library methods
import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

// Utilities
import styles from "./index.module.css";

const TumblingE = ({ examData }) => {
  const [displayedDirections, setDisplayedDirections] = useState([]);

  useEffect(() => {
    const directions = examData?.state?.directions ?? [];
    let newDisplayedDirections = [];

    newDisplayedDirections = directions.flatMap((dir) =>
      dir.letters.map((char) => ({
        color:
          char?.State === "GuessedCorrectly"
            ? "#13A321"
            : char?.State === "Skipped"
              ? "#ACA8A8"
              : char?.State === "None"
                ? "#202338"
                : "#B21515",
        value: char?.Value,
      }))
    );

    setDisplayedDirections(newDisplayedDirections);
  }, [examData?.state?.directions]);

  const getDirChosen = useCallback(
    (value) =>
      value === "UP"
        ? "270deg"
        : value === "DOWN"
          ? "90deg"
          : value === "LEFT"
            ? "180deg"
            : value === "RIGHT"
              ? "0deg"
              : null,
    []
  );

  return (
    <>
      <Grid className={styles.gridContainer}>
        {displayedDirections?.length > 0 &&
          displayedDirections.map((letter, index) => {
            const value = letter?.value;
            const directionChosen = getDirChosen(value);

            return (
              <Grid item key={index} className={styles.gridItem}>
                <Typography
                  variant="h5"
                  display={"inline-block"}
                  color={letter?.color}
                  fontWeight={600}
                  fontSize={"3rem"}
                  margin={"0 .4rem"}
                >
                  <span
                    style={{
                      display: "inline-block",
                      transform: `rotate(${directionChosen})`,
                    }}
                  >
                    E
                  </span>
                </Typography>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default TumblingE;
