// Library methods
import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";

// Components
import Loader from "../../components/UI/Loader";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// Utilities
import useClinic from "../../hooks/useClinic";
import getUserFromToken from "../../utils/tokenHelper";
import { login } from "../../services/Auth";
import { getClinics } from "../../services/Clinic";
import { languageMap } from "../../i18n/languages";

const Callback = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const { setClinicId, setClinicName, setAllClinicInfo, setClinicSettings } =
    useClinic();

  const navigate = useNavigate();

  // translation
  const { i18n } = useTranslation();

  // get previous url
  let previousUrl = "";
  let clinicDetails = "";
  try {
    previousUrl = JSON.parse(sessionStorage.getItem("previousURL"));
    clinicDetails = JSON.parse(sessionStorage.getItem("clinicDetails"));
  } catch (error) {
    console.log(error);
  }

  // confirmation methods
  const handlePortalLanguage = useCallback(
    (language) => {
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  useEffect(() => {
    const getClinicInfo = async () => {
      try {
        const token = await getAccessTokenSilently();
        const { hasRole, isAdmin } = getUserFromToken(token);

        // if user has no role
        if (!hasRole) navigate("/invalid");
        else if (isAdmin) {
          // if user is an admin
          const clinicsList = await getClinics(token);

          if (clinicsList.length > 0) {
            const clinicId = clinicDetails?.id ?? clinicsList[0].id;
            const clinicName = clinicDetails?.name ?? clinicsList[0].name;

            const { settings } = await getClinics(token, clinicId);

            if (settings) {
              // get language key from value
              const getObjectKey = (obj, value) => {
                return Object.keys(obj).find((key) => obj[key].val === value);
              };

              handlePortalLanguage(
                getObjectKey(languageMap, settings.clinicDefaultLanguage)
              );
              setClinicSettings(settings);
            }

            setClinicId(clinicId);
            setClinicName(clinicName);
            setAllClinicInfo(clinicsList);

            if (previousUrl === "/patients") navigate("/patients");
            else navigate("/admin/clinics");
          } else navigate("/invalid");
        } else {
          // if user is a clinic role user
          const data = { email: "clinic@gmail.com" };
          const clinicInfo = await login(token, data);

          if (clinicInfo.length > 0) {
            setClinicId(clinicDetails?.id ?? clinicInfo[0].id);
            setClinicName(clinicDetails?.name ?? clinicInfo[0].name);
            setAllClinicInfo(clinicInfo);
            navigate("/patients");
          } else navigate("/invalid");
        }
      } catch (error) {
        navigate("/invalid");
      }
    };

    getClinicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, logout, navigate, handlePortalLanguage]);

  return (
    <Box sx={PageContainerWrapper()}>
      <Loader />
    </Box>
  );
};

export default Callback;
