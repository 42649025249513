// Library methods
import { jwtDecode } from "jwt-decode";

const getUserFromToken = (token) => {
  const decoded = jwtDecode(token);
  const roles = decoded["https://callback.retinalogik/roles"];
  const hasRole = roles?.length > 0;
  const isAdmin = hasRole && roles.includes("Admin");

  return {
    roles,
    hasRole,
    isAdmin,
  };
};

export default getUserFromToken;
