// Library methods
import { useContext, useMemo } from "react";

// MUI Components
import Grid from "@mui/material/Grid";

// Components
import EyeReport from "./EyeReport";

// Utilities
import {
  ReportEachSectionContext,
  CommentTitleProvider,
} from "../../../contexts/CommentContext";
import { ReportDataContext } from "../../../contexts/ReportDataContext";

const SingleThresholdReport = () => {
  const {
    sectionId,
    sectionId2,
    examReport,
    examReport2,
    eyeReport,
    eyeReport2,
    durationStartTime,
    durationStartTime2,
    duration,
    duration2,
    comments,
    comments2,
  } = useContext(ReportDataContext);

  const ReportSectionContent = useMemo(() => {
    return (
      <CommentTitleProvider>
        <Grid container sx={{ display: "flex" }} columnSpacing={4}>
          {examReport && (
            <Grid item xs={12} lg={6} pt={4}>
              <ReportEachSectionContext.Provider
                value={{
                  sectionId: sectionId,
                  examReport: examReport,
                  eyeReport: eyeReport,
                  durationStartTime: durationStartTime,
                  duration: duration,
                  comments: comments,
                }}
              >
                <EyeReport />
              </ReportEachSectionContext.Provider>
            </Grid>
          )}
          {examReport2 && (
            <Grid item xs={12} lg={6} pt={4}>
              <ReportEachSectionContext.Provider
                value={{
                  sectionId: sectionId2,
                  examReport: examReport2,
                  eyeReport: eyeReport2,
                  durationStartTime: durationStartTime2,
                  duration: duration2,
                  comments: comments2,
                }}
              >
                <EyeReport />
              </ReportEachSectionContext.Provider>
            </Grid>
          )}
        </Grid>
      </CommentTitleProvider>
    );
  }, [
    comments,
    comments2,
    duration,
    duration2,
    durationStartTime,
    durationStartTime2,
    examReport,
    examReport2,
    eyeReport,
    eyeReport2,
    sectionId,
    sectionId2,
  ]);

  return <>{ReportSectionContent}</>;
};

export default SingleThresholdReport;
