import axios from "axios";
import {
  config,
  isResponseValid,
  webServiceUrl,
} from "../../utils/servicesHelper";

const login = (accessToken, data) =>
  axios
    .post(`${webServiceUrl}/auth/clinic`, data, config(accessToken))
    .then((response) => {
      const clinicInfo = isResponseValid(response);
      return clinicInfo;
    })
    .catch((error) => {
      throw error;
    });

export { login };
