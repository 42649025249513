// Library methods
import React from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import { Box } from "@mui/material";

const Watermark = ({ py = 20, fs = "3em" }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        item
        position={"absolute"}
        width={"90%"}
        py={py}
        zIndex={100}
        color={"#abb6c490"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        fontSize={fs}
        bgcolor={"transparent"}
        mx={"auto"}
      >
        {t("word_terminated")}
      </Box>
    </>
  );
};

export default Watermark;
