import React from "react";

const ProgressBar = ({ value, max = 100 }) => {
  const width = (value / max) * 100;

  return (
    <div className="w-full bg-gray-100 h-4 dark:bg-gray-200">
      <div
        className="bg-gradient-to-r from-skyblue to-maxBlue  h-4"
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
