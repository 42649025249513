// Library methods
import { useCallback, useEffect, useMemo, useState } from "react";

// MUI Components
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Apartment from "@mui/icons-material/Apartment";

// Utilities
import useClinic from "../../../hooks/useClinic";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getClinics } from "../../../services/Clinic";
import { allLanguageMap, languageMap } from "../../../i18n/languages";
import { getComparator } from "../../../utils/tableHelper";
import SearchBox from "../SearchBox";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const ClinicSwitcher = () => {
  // clinic context
  const {
    clinicName,
    setClinicId,
    setClinicName,
    allClinicInfo,
    setClinicLang,
    setPatientDefaultLang,
    setClinicSettings,
  } = useClinic();

  const { t, i18n } = useTranslation();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // theme
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up("sm"));

  // states init
  const [menu, setMenu] = useState(null);
  const [tmpClinic, setTmpClinic] = useState({});

  // routing
  const navigate = useNavigate();

  // clinic menu handling
  const clinicMenuClick = (event) => setMenu(event.currentTarget);
  const clinicMenuClose = () => setMenu(null);

  // handles the clinic selection

  const changeClinicLanguage = useCallback(
    async (id) => {
      try {
        const token = await getAccessTokenSilently();
        const { settings } = await getClinics(token, id);
        const getObjectKey = (obj, value) => {
          return Object.keys(obj).find((key) => obj[key].val === value);
        };
        const enKey = "en";
        if (settings) {
          setClinicSettings(settings);
        }
        if (settings?.clinicDefaultLanguage) {
          const langKey = getObjectKey(
            languageMap,
            settings.clinicDefaultLanguage
          );
          i18n.changeLanguage(langKey);
          setClinicLang(langKey);
        } else {
          i18n.changeLanguage(enKey);
          setClinicLang(enKey);
        }

        if (settings?.patientDefaultLanguage)
          setPatientDefaultLang(
            getObjectKey(allLanguageMap, settings.patientDefaultLanguage)
          );
        else setPatientDefaultLang(null);
      } catch (e) {}
    },
    [
      getAccessTokenSilently,
      i18n,
      setClinicLang,
      setPatientDefaultLang,
      setClinicSettings,
    ]
  );

  const handleClinicChange = useCallback(() => {
    if (!tmpClinic?.id || !tmpClinic?.name) return;
    setClinicId(tmpClinic.id);
    setClinicName(tmpClinic.name);
    navigate("/");
    clinicMenuClose();
    changeClinicLanguage(tmpClinic.id);
  }, [
    changeClinicLanguage,
    navigate,
    setClinicId,
    setClinicName,
    tmpClinic.id,
    tmpClinic.name,
  ]);

  const sortedAllClinicInfo = useMemo(
    () => allClinicInfo?.sort(getComparator("asc", ["name"])),
    [allClinicInfo]
  );

  useEffect(() => {
    handleClinicChange();
  }, [handleClinicChange, tmpClinic]);

  useEffect(() => {
    if (!menu) setTmpClinic({});
  }, [menu]);

  return allClinicInfo.length > 0 ? (
    <>
      {/* clinic switcher button */}
      <Button
        onClick={clinicMenuClick}
        disabled={allClinicInfo.length <= 1}
        sx={{
          display: { xs: "block", sm: "inline" },
          minWidth: { xs: 0, sm: 64 },
          mx: { xs: 0, sm: 0, md: 2, lg: 4 },
          color: "#E7EBF8",
          fontSize: 20,
          ...(Boolean(menu) && {
            fontWeight: "bold",
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }),
          "&:hover": {
            ...(Boolean(menu) && {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            }),
          },
        }}
      >
        <div className="flex space-x-2 items-center">
          <Apartment sx={{ color: "#E7EBF8" }} />
          <Typography
            variant="button"
            sx={{
              color: "#E7EBF8",
              display: { xs: "none", sm: "none", md: "block" },
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            {clinicName}
          </Typography>
        </div>
      </Button>

      {/* clinic switcher menu, when open */}
      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={clinicMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: isMediumOrLarger
              ? "20vw"
              : isSmallOrLarger
                ? "50vw"
                : "80vw",
          },
        }}
      >
        <Grid container display={"flex"} justifyContent={"center"}>
          <SearchBox
            data={sortedAllClinicInfo}
            state={tmpClinic}
            setState={setTmpClinic}
            label={t("clinic_modal_clinic_name")}
            mb={0}
            maxHeight="40vh"
            defaultIsListVisible={true}
            parentOpen={Boolean(menu)}
          />
        </Grid>
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default ClinicSwitcher;
