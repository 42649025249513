// Library methods
import { Link as RouterLink } from "react-router-dom";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Breadcrumb = ({ isLoading, patientId, patientName }) => {
  return (
    <Box display="flex" alignItems="center" mt={4} mb={1}>
      <Link
        color="inherit"
        component={RouterLink}
        to="/patients"
        state={{ user: patientId, fromReport: true }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <ArrowBackIosIcon className="mr-3 cursor-pointer" fontSize="small" />
      </Link>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          className="capitalize"
          to="/patients"
          state={{ user: patientId, fromReport: true }}
          sx={{ display: "flex" }}
        >
          Patients
          {isLoading ? (
            <Skeleton sx={{ width: 50, ml: 1 }} />
          ) : (
            ` (${patientName})`
          )}
        </Link>
        <Typography color="text.primary" className="capitalize">
          Report
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
