import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const EditButton = ({ handleEditClick }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mr: { sm: 1 },
        justifyContent: { xs: "center", sm: "end" },
      }}
    >
      {/* edit */}
      <Tooltip title={t("patients_edit_modal_title")}>
        <span>
          <IconButton
            sx={{
              color: "white",
              padding: "4px",
            }}
            onClick={() => {
              handleEditClick();
            }}
          >
            <FontAwesomeIcon size="sm" icon={faEdit} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default EditButton;
