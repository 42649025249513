import axios from "axios";
import {
  config,
  injectBearer,
  isResponseValid,
  webServiceUrl,
} from "../../utils/servicesHelper";

const addClinic = async (accessToken, clinicData) =>
  axios
    .post(`${webServiceUrl}/clinics`, clinicData, config(accessToken))
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getClinics = async (accessToken, clinicId) =>
  axios
    .get(
      `${webServiceUrl}/clinics${clinicId ? `/${clinicId}` : ""}`,
      config(accessToken)
    )
    .then((response) => {
      const exams = isResponseValid(response);
      return exams;
    })
    .catch((error) => {
      throw error;
    });

const deleteClinic = async (accessToken, clinicId) =>
  axios
    .delete(`${webServiceUrl}/clinics/${clinicId}`, config(accessToken))
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateClinic = async (accessToken, clinicId, clinicData) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}`,
      clinicData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateClinicLicensing = async (accessToken, clinicId, licensingData) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/licensing`,
      licensingData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getClinicUsers = async (accessToken, clinicId, signal) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/access`,
      config(accessToken, signal)
    )
    .then((response) => {
      const clinicUsers = isResponseValid(response);
      return clinicUsers;
    })
    .catch((error) => {
      throw error;
    });

const addClinicUsers = async (accessToken, clinicId, usersData) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/access`,
      usersData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const deleteClinicUsers = async (accessToken, clinicId, userData) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/access/${userData}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateClinicSettings = async (
  accessToken,
  clinicId,
  clinicSettingsData
) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/settings`,
      clinicSettingsData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getAdminDashboard = async (accessToken, params, signal) =>
  axios
    .get(`${webServiceUrl}/dashboard`, {
      headers: { Authorization: injectBearer(accessToken) },
      params: params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

export {
  addClinic,
  getClinics,
  deleteClinic,
  updateClinic,
  updateClinicLicensing,
  getClinicUsers,
  addClinicUsers,
  deleteClinicUsers,
  updateClinicSettings,
  getAdminDashboard,
};
