/**
 * Styles for the border of an outlined TextField element.
 */
const CustomTextFieldOutlined = (custom = {}) => ({
  ...custom,
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#e7ebf8" },
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1rem",
    fontSize: "0.8rem",
  },
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
});

/**
 * Styles for a TextField element.
 */
const CustomTextFieldOutlinedInputProps = {
  backgroundColor: "#20233810",
  color: "#202338",
  borderRadius: "5px",
  height: "40px",
};

/**
 * Styles for a Select element.
 */
const CustomSelectProps = () => ({
  backgroundColor: "#20233810",
  color: "#202338",
  borderRadius: "5px",
  height: "40px",
});

/**
 * Styles for a modal's dialog actions (cancel and confirm buttons area, on the bottom of the modal).
 */

const ModalDialogActions = () => ({
  padding: 3,
  display: "flex",
  justifyContent: "center",
  gap: 1.25,
});

/**
 * Styles for a modal's cancel Button.
 */
const ModalCustomCancel = () => ({
  color: "#282C3C",
  lineHeight: "14px",
  backgroundColor: "#FFFFFA",
  border: "0.5px solid #282C3C",
  boxShadow: "3px 4px 6px -1px rgba(0, 0, 0, 0.05)",
  borderRadius: "5px",
  padding: "5px 20px",
  height: "34px",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#20233820",
    borderColor: "#20233810",
  },
  "&.MuiButton-root": {
    textTransform: "capitalize",
  },
});

/**
 * Styles for a modal's confirm Button.
 */
const ModalCustomConfirm = () => ({
  color: "white",
  borderColor: "#E2772E",
  backgroundColor: "#E2772E",
  height: "34px",
  fontSize: "14px",
  padding: "5px 20px",
  "&:hover": {
    color: "white",
    backgroundColor: "#CB6B29",
    borderColor: "#CB6B29",
  },
  "&.Mui-disabled": {
    color: "white",
    backgroundColor: "#E2772E70",
    borderColor: "#E2772E70",
  },
  "&.MuiButton-root": {
    textTransform: "capitalize",
  },
});

/**
 * Styles for add exam modal's Button.
 */
const ModalCustomOutlined = (type) => ({
  color: type === "outlined" ? "white" : type === "fill" ? "white" : "#141414",
  borderColor: type === "outlined" ? "white" : "none",
  backgroundColor:
    type === "outlined" ? "none" : type === "fill" ? "#E2772E" : "#DADADA",
  height: "34px",
  fontSize: "10px",
  fontWeight: "600",
  padding: "2px",
  marginLeft: "3px",
  width: "35px",
  "&:hover": {
    color: "white",
    backgroundColor: "#CB6B29",
    borderColor: "#CB6B29",
  },
  "&.Mui-disabled": {
    color: "#141414",
    backgroundColor: "#DADADA",
    borderColor: "#DADADA",
  },
  "&.MuiButton-root": {
    textTransform: "capitalize",
  },
});

/**
 * Styles for a modal's confirm Button.
 */
const ModalCustomSwitch = () => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#E2772E",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#E2772E",
  },
});

/**
 * Styles for a modal's delete Button.
 */
const ModalCustomConfirmDelete = () => ({
  color: "white",
  borderColor: "#C62828",
  backgroundColor: "#D74141",
  height: "34px",
  fontSize: "14px",
  padding: "5px 20px",
  "&:hover": {
    color: "white",
    backgroundColor: "#C62828",
    borderColor: "#D74141",
  },
  "&.MuiButton-root": {
    textTransform: "capitalize",
  },
});

/**
 * Style to be injected into the ModalCustomConfirm (or, ModalCustomConfirmDelete) style, when the button has an icon.
 * Use this, when the "confirm action" button within the modal, has an icon.
 * Example on how it should be used:
 *
 *      sx={{
 *        ...ModalCustomConfirm(),
 *        ...ModalCustomConfirmIconHandler(),
 *      }}
 */
const ModalCustomConfirmIconHandler = () => ({
  gap: 1,
  display: "flex",
  alignItems: "center",
});

/**
 * Styles for a modal's add Button.
 */
const ModalCustomAdd = () => ({
  px: 4,
  color: "#FFFFFA",
  borderRadius: "5px",
  background: "#D37628",
  width: "94px",
  height: "34px",
  fontSize: "10px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "white",
    backgroundColor: "#E2772E",
  },
  "&.Mui-disabled": {
    color: "white",
    backgroundColor: "#E2772E70",
    borderColor: "#E2772E70",
  },
  "&.MuiButton-root": {
    textTransform: "capitalize",
  },
});

/**
 * Styles for a modal's Paper element ().
 */
const ModalPaperProps = {
  backgroundColor: "#FFFFFA",
  borderRadius: { sm: 0, md: "5px" },
  maxWidth: { xs: "282px", sm: "395.51px" },
};

/**
 * Used to apply a some default styling to the page wrapper container.
 */
const PageContainerWrapper = (custom = { minHeight: "100%", flexGrow: 1 }) => ({
  ...custom,
  overflow: "hidden",
  display: "block",
  position: "relative",
});

export {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  CustomSelectProps,
  ModalCustomCancel,
  ModalCustomAdd,
  ModalDialogActions,
  ModalCustomConfirm,
  ModalCustomConfirmDelete,
  ModalCustomConfirmIconHandler,
  ModalPaperProps,
  PageContainerWrapper,
  ModalCustomOutlined,
  ModalCustomSwitch,
};
