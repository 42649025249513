// Library methods

// Components
import ReportInfo from "./ReportInfo";
// import CommentsList from "../../../../components/UI/CommentsList";
import PelliRobson from "../../../../components/UI/PelliRobson";
import TumblingE from "../../../../components/UI/TumblingE";
import { useContext } from "react";
import { AlgorithmContext } from "../../../../contexts/ExamPropertyContext";
import { ReportDataContext } from "../../../../contexts/ReportDataContext";
import { getExamType } from "../../../../utils/examReportsHelper";
import EyeReportContent from "../../../../components/UI/EyeReportContent";
import { ReportEachSectionContext } from "../../../../contexts/CommentContext";

const EyeReport = () => {
  // const { t } = useTranslation();
  const { pellirobson } = useContext(AlgorithmContext);
  // const { forBackendPdf } = useContext(ValidatePDFContext);
  const { exam } = useContext(ReportDataContext);
  const { examReport } = useContext(ReportEachSectionContext);

  // algorithm version number
  // const algorithmVersion = examReport?.exam?.version?.substring(0, 3);
  const examAlgorithm = getExamType(exam);

  if (!examReport) return null;
  // build the report content
  return (
    <div className="retinalogik-section">
      {examAlgorithm === pellirobson ? (
        <EyeReportContent
          ReportInfo={<ReportInfo />}
          ExamGraph={<PelliRobson examData={examReport} />}
        />
      ) : (
        <EyeReportContent
          ReportInfo={<ReportInfo />}
          ExamGraph={<TumblingE examData={examReport} />}
        />
      )}
    </div>
  );
};

export default EyeReport;
