import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChartLegendExceptVisualField = ({ isLiveUpdate = null }) => {
  const { t } = useTranslation();
  return (
    <Grid item display="flex" ml={!isLiveUpdate && 2}>
      <Typography>
        {" "}
        {t("word_legend")}:{" "}
        <span style={{ color: "#E2772E" }}>
          {isLiveUpdate ? t("current") : t("word_terminated")}
        </span>{" "}
        <span style={{ color: "#13A321" }}>{t("word_seen")}</span>{" "}
        <span style={{ color: "#B21515" }}>{t("word_not_seen")}</span>{" "}
        <span style={{ color: "#ACA8A8" }}>{t("word_skipped")}</span>{" "}
      </Typography>
      {/* <Typography> Legend:</Typography> */}
    </Grid>
  );
};

export default ChartLegendExceptVisualField;
