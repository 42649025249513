import { useEffect, useRef } from "react";

// Called except the time when first rendering (mount) occurs.
const useUpdateEffect = (callback, dependencies) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      callback();
    } else {
      hasMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...dependencies]);
};

export default useUpdateEffect;
