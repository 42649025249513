// Library methods
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

const SignOutModal = ({ open, onClose, onConfirm, onCancel }) => {
  // internationalization
  const { t } = useTranslation();

  // modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: { ...ModalPaperProps, width: 400 },
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faSignOut} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("word_sign_out")}?
          </Typography>
        </Box>
        <Box padding={1}>
          <Grid container px={{ xs: 0, sm: 0 }} py={{ xs: 2, sm: 2 }}>
            <Grid item xs={12}>
              <Typography noWrap sx={{ fontSize: 20 }}>
                {t("sign_out_modal_text")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          onClick={onConfirm}
        >
          {t("word_sign_out")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignOutModal;
