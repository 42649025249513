// Library methods

import SharedConfirmation from "../../SharedConfirmation";

const LiveUpdateNavigationModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  loading,
}) => {
  return (
    <SharedConfirmation
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      loading={loading}
      title={"button_view_report"}
      description={"navigate_report_description"}
      confirmationButtonText={"word_view"}
      cancelText={"button_close"}
    />
  );
};

export default LiveUpdateNavigationModal;
