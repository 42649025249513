import { createContext, useCallback, useState } from "react";

const LiveUpdateStatusContext = createContext({
  exam: {},
  open: false,
  deviceId: "",
  // devicesWithExams: [],
  setExam: () => {},
  setOpen: () => {},
  setDeviceId: () => {},
  // setDevicesWithExams: () => {},
  handleLiveUpdateModalClose: (event, reason) => {},
  handleViewLiveUpdateClick: (exam, deviceId) => {},
});

const LiveUpdateStatusProvider = ({ children }) => {
  const [exam, setExam] = useState(null);
  const [open, setOpen] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  // const [devicesWithExams, setDevicesWithExams] = useState([]);
  //   const { getExamStatus } = useExamStatus()

  //   const status = useMemo(() => getExamStatus(sele), [devicesWithExams])
  //   const examStatus = useCallback(() => getExamStatus(selectedLiveUpdateExam, devicesWithExams), [devicesWithExams, getExamStatus, selectedLiveUpdateExam])
  const handleLiveUpdateModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleViewLiveUpdateClick = useCallback(
    (exam, deviceId) => {
      setExam(exam);
      setDeviceId(deviceId);
      setOpen(true);
    },
    [setOpen, setExam, setDeviceId]
  );

  return (
    <LiveUpdateStatusContext.Provider
      value={{
        exam: exam,
        open: open,
        deviceId: deviceId,
        // devicesWithExams: devicesWithExams,
        setExam: setExam,
        setOpen: setOpen,
        setDeviceId: setDeviceId,
        // setDevicesWithExams: setDevicesWithExams,
        handleLiveUpdateModalClose: handleLiveUpdateModalClose,
        handleViewLiveUpdateClick: handleViewLiveUpdateClick,
      }}
    >
      {children}
    </LiveUpdateStatusContext.Provider>
  );
};

export { LiveUpdateStatusContext, LiveUpdateStatusProvider };
