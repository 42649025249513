// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirmDelete,
  ModalCustomConfirmIconHandler,
  ModalPaperProps,
  ModalCustomAdd,
} from "../../../styles/muiStylesHelper";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

const SharedConfirmation = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  loading,
  title,
  description,
  confirmationButtonText,
  cancelText,
  isUnlink = false,
}) => {
  // internationalization
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          {isUnlink ? (
            <FontAwesomeIcon icon={faLinkSlash} size="lg" />
          ) : (
            <PlagiarismOutlinedIcon fontSize="inherit" />
          )}

          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t(title)}
          </Typography>
        </Box>
        <Box padding={1}>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            {t(description)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          sx={ModalCustomCancel}
          onClick={onCancel}
          style={{ marginRight: "1.25rem" }}
        >
          {t(cancelText)}
        </Button>
        <Button
          variant="outlined"
          disabled={loading}
          sx={
            isUnlink
              ? {
                  ...ModalCustomConfirmDelete(),
                  ...ModalCustomConfirmIconHandler(),
                }
              : {
                  ...ModalCustomAdd(),
                  ...ModalCustomConfirmIconHandler(),
                  fontSize: "14px",
                }
          }
          onClick={onConfirm}
          color={isUnlink ? "error" : undefined}
        >
          {t(confirmationButtonText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharedConfirmation;
