import { createContext, useContext, useEffect, useState } from "react";

const DeviceAppVersionContext = createContext({
  minimumCompatibleVersion: "", // Backend ensure this is latest app version
  newAppVersionWarningPopup: false,
  openInstruction: false,
  setMinimumCompatibleVersion: () => {},
  setNewAppVersionWarningPopup: () => {},
  setOpenInstruction: () => {},
});

const DeviceAppVersionProvider = ({ children }) => {
  const [newAppVersionWarningPopup, setNewAppVersionWarningPopup] =
    useState(false);
  const [minimumCompatibleVersion, setMinimumCompatibleVersion] =
    useState(null);
  const [openInstruction, setOpenInstruction] = useState(null);
  return (
    <DeviceAppVersionContext.Provider
      value={{
        minimumCompatibleVersion: minimumCompatibleVersion,
        newAppVersionWarningPopup: newAppVersionWarningPopup,
        openInstruction: openInstruction,
        setMinimumCompatibleVersion: setMinimumCompatibleVersion,
        setNewAppVersionWarningPopup: setNewAppVersionWarningPopup,
        setOpenInstruction: setOpenInstruction,
      }}
    >
      {children}
    </DeviceAppVersionContext.Provider>
  );
};
const useDeviceAppVersion = (devices) => {
  const context = useContext(DeviceAppVersionContext);
  const { setMinimumCompatibleVersion, setNewAppVersionWarningPopup } = context;

  // Inform clinics that new app version for devices released.
  useEffect(() => {
    if (devices?.length) {
      devices.forEach((device) => {
        if (device?.minimumCompatibleVersion) {
          setMinimumCompatibleVersion(device.minimumCompatibleVersion);
          return;
        }
      });
    } else setNewAppVersionWarningPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return context;
};

export {
  DeviceAppVersionContext,
  DeviceAppVersionProvider,
  useDeviceAppVersion,
};
