import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
} from "../../../styles/muiStylesHelper";
import useIsMobile from "../../../hooks/useIsMobile";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import { useMemo } from "react";

const CustomDatePicker = ({
  date,
  birthDateError = 0,
  scheduledError = 0,
  labelTitle = "patients_modal_birth",
  height = 7,
  handleChange = () => {},
  shouldDisable = false,
}) => {
  const { t } = useTranslation();
  const dimensions = useWindowDimensions();
  const isMobile = useIsMobile(dimensions);
  const PickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker;
  const disableFuture = labelTitle === "patients_modal_birth";

  const commonProps = useMemo(
    () => ({
      label: t(labelTitle),
      value: date ? new Date(date) : null,
      views: ["year", "month", "day"],
      format: "yyyy-MM-dd",
      disableFuture: disableFuture,
      disablePast: !disableFuture,
      maxDate: parseISO(new Date()),
      disabled: shouldDisable,
      onChange: handleChange,
      slotProps: {
        textField: {
          fullWidth: true,
          required: true,
          error: birthDateError > 0 || scheduledError,
          sx: {
            ...CustomTextFieldOutlined(),
            "& .MuiOutlinedInput-input": {
              height: height,
            },
            "& .MuiButtonBase-root": {
              color: "#545454",
            },
            ...CustomTextFieldOutlinedInputProps,
            marginBottom:
              birthDateError & 4 ? 3.5 : birthDateError ? 1 : undefined,
          },
          helperText:
            birthDateError & 1
              ? t("patients_modal_birth_error")
              : birthDateError & 2
                ? t("patient_modal_invalid_birth_error")
                : birthDateError & 4
                  ? t("patient_modal_incompatible_birth_date_error")
                  : scheduledError
                    ? t("scheduled_error")
                    : null,
          color: "warning",
          inputProps: { "data-testid": "custom-date-picker" },
        },
      },
    }),
    [
      t,
      labelTitle,
      date,
      disableFuture,
      handleChange,
      birthDateError,
      height,
      scheduledError,
      shouldDisable,
    ]
  );
  return <PickerComponent {...commonProps} />;
};

export default CustomDatePicker;
