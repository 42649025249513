// these values reflect the enum defined on the device handler (signalR)
export const DeviceStages = {
  ClinicLogin: 0,
  PatientLogin: 1,
  Confirmation: 2,
  Tutorial: 4,
  Exam: 8,
  Pause: 16,
  Congratulations: 32,
  Calibration: 64,
};

// these values don't reflect the enum defined on the device handler (signalR)
// these values are based on the timeline displayed on the live update modal
// these values need to in order in terms of occurence, unlike the DeviceStages enum
export const DeviceStagesTimeline = {
  PatientLogin: 0,
  Confirmation: 1,
  Calibration: 2,
  Tutorial: 3,
  Exam: 4,
  Completed: 5,
  Terminated: 6,
};

// method to get the active step to be displayed on the timeline component within the live update modal
export const getActiveStep = (exam, possibleSteps, examStatus) => {
  if (!exam || !examStatus) return { index: -1, value: -1 };

  const index = possibleSteps.findIndex(
    (stepValue) => stepValue === examStatus.timelineStage
  );
  return { index, value: examStatus.timelineStage };
};

// method to get the texts for all stage options within the timeline component displayed within the live update modal
export const getDeviceStagesTimelineText = (
  stage,
  isExamStage,
  wordPatientLogin,
  wordConfirmation,
  wordTutorial,
  wordExam,
  wordExamExtra,
  wordCompleted,
  wordCalibration
) => {
  if (stage === DeviceStagesTimeline.PatientLogin) return wordPatientLogin;
  if (stage === DeviceStagesTimeline.Confirmation) return wordConfirmation;
  if (stage === DeviceStagesTimeline.Tutorial) {
    if (isExamStage) return `${wordTutorial} (${wordExamExtra})`;
    return wordTutorial;
  }
  if (stage === DeviceStagesTimeline.Calibration) return wordCalibration;
  if (stage === DeviceStagesTimeline.Exam) {
    if (isExamStage) return `${wordExam} (${wordExamExtra})`;
    return wordExam;
  }
  if (stage === DeviceStagesTimeline.Completed) return wordCompleted;

  return undefined;
};
