// Library methods
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
  ModalDialogActions,
} from "../../../../styles/muiStylesHelper";
import { localizeDate } from "../../../../utils/localeHelper";
import { formatDate } from "../../../../utils/dateHelper";

const DeviceHistoryModal = ({ open, deviceHistory, onClose, onCancel }) => {
  // internationalization
  const { t } = useTranslation();

  const sortedDeviceHistory = deviceHistory.sort((a, b) =>
    b.assignmentDate.localeCompare(a.assignmentDate)
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        sx: { ...ModalPaperProps, minWidth: 500, maxHeight: { md: "65%" } },
      }}
    >
      <DialogContent>
        <Box sx={{ padding: 1 }}>
          <Typography
            id="modal-modal-title"
            style={{ fontWeight: "700" }}
            variant="h5"
            component="h2"
          >
            {t("device_history_modal_title")}
          </Typography>
        </Box>
        {sortedDeviceHistory.length === 0 && (
          <Box my={4} display="flex" justifyContent="center">
            <Typography
              noWrap
              variant="body1"
              sx={{ textTransform: "capitalize" }}
              color="text.secondary"
            >
              {t("device_history_modal_empty")}
            </Typography>
          </Box>
        )}
        {sortedDeviceHistory.length > 0 && (
          <Box>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.5,
                },
              }}
            >
              {sortedDeviceHistory.map((device, index) => {
                const displayConnector = index !== deviceHistory.length - 1;
                return (
                  <TimelineItem key={device.id}>
                    <TimelineOppositeContent align="right" variant="body2">
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "600" }}
                      >
                        {device?.clinic?.name}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      {displayConnector && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      {device?.unassignmentDate && (
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                        >
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "500" }}
                          >
                            {t("device_history_modal_unassignment_date")}
                          </Typography>
                          <Typography variant="body2" ml={{ xs: 0, sm: 1 }}>
                            {formatDate(localizeDate(device?.unassignmentDate))}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        mt={0.5}
                      >
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "500" }}
                        >
                          {t("device_history_modal_assignment_date")}
                        </Typography>
                        <Typography variant="body2" ml={{ xs: 0, sm: 1 }}>
                          {formatDate(localizeDate(device?.assignmentDate))}
                        </Typography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceHistoryModal;
