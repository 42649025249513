// MUI Components
import Grid from "@mui/material/Grid";

// Components
import EyeReport from "./EyeReport";

// Utilities
import {
  CommentTitleProvider,
  ReportEachSectionContext,
} from "../../../contexts/CommentContext";
import { ReportDataContext } from "../../../contexts/ReportDataContext";
import { useContext, useMemo } from "react";

const EstermanReport = () => {
  const { examReport, sectionId, comments } = useContext(ReportDataContext);
  const ReportSectionContent = useMemo(() => {
    return (
      <CommentTitleProvider>
        <Grid container sx={{ display: "flex" }} columnSpacing={4}>
          <ReportEachSectionContext.Provider
            value={{ comments: comments, sectionId: sectionId }}
          >
            {examReport && (
              <Grid item xs={12} lg={6} pt={4}>
                <EyeReport />
              </Grid>
            )}
          </ReportEachSectionContext.Provider>
        </Grid>
      </CommentTitleProvider>
    );
  }, [comments, examReport, sectionId]);

  return <>{ReportSectionContent}</>;
};

export default EstermanReport;
