// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Styles
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  ModalCustomCancel,
  ModalCustomConfirm,
  ModalPaperProps,
  ModalDialogActions,
} from "../../../../styles/muiStylesHelper";

// Utilities
import { allLanguageMap } from "../../../../i18n/languages";
import { useCallback, useMemo } from "react";
import SearchBox from "../../../../components/UI/SearchBox";
import CustomDatePicker from "../../../../components/UI/CustomDatePIcker";
import { getLocale } from "../../../../utils/localeHelper";

const EditPatientModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  firstName,
  lastName,
  birthDate,
  birthDateError,
  sex,
  language,
  healthCareId,
  setFirstName,
  setLastName,
  setBirthDate,
  setBirthDateError,
  setSex,
  setLanguage,
  setHealthCareId,
  checkPatientName,
  setExistingPatient,
  sortedLanguageList = [],
  genderData = [],
}) => {
  // internationalization
  const { t, i18n } = useTranslation();

  const handleChange = useCallback(
    (newValue) => {
      const today = new Date();
      // Set time to the start of the day to avoid time zone issues
      today.setHours(0, 0, 0, 0);
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() - 120);
      maxDate.setHours(0, 0, 0, 0);
      const newValueDate = new Date(newValue);
      const isDateValid = !isNaN(newValueDate.getTime());

      if (!isDateValid) {
        setBirthDateError(2);
      } else if (newValueDate > today) {
        setBirthDateError(1);
      } else if (newValueDate < maxDate) {
        setBirthDateError(4);
      } else {
        setBirthDateError(0);
        setBirthDate(newValue);
      }
    },
    [setBirthDate, setBirthDateError]
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = 0;
    Object.keys(allLanguageMap).forEach((key) => {
      if (t(allLanguageMap[key]?.val) === language?.val) isIncluded++;
    });
    genderData.forEach((gender) => {
      if (gender?.name === sex?.name) isIncluded++;
    });

    return !(firstName && lastName && birthDate && sex) || isIncluded !== 2;
  }, [t, birthDate, firstName, language, lastName, sex, genderData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faUserEdit} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("patients_edit_modal_title")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* First Name and Last Name fields */}
          <Box display={"flex"}>
            <TextField
              id="outlined-basic"
              value={firstName}
              onChange={(e) => {
                setExistingPatient(null);
                setFirstName(e.target.value);
              }}
              onBlur={() => checkPatientName(firstName, lastName)}
              label={t("patients_edit_modal_first_name")}
              variant="outlined"
              fullWidth
              sx={() =>
                CustomTextFieldOutlined({ mt: "1.25rem", mr: "1.25rem" })
              }
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              color="warning"
            />
            <TextField
              id="outlined-basic"
              value={lastName}
              onChange={(e) => {
                setExistingPatient(null);
                setLastName(e.target.value);
              }}
              onBlur={() => checkPatientName(firstName, lastName)}
              label={t("patients_edit_modal_last_name")}
              variant="outlined"
              fullWidth
              sx={() => CustomTextFieldOutlined({ my: "1.25rem" })}
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              color="warning"
            />
          </Box>

          <TextField
            id="outlined-basic"
            value={healthCareId}
            onChange={(e) => setHealthCareId(e.target.value)}
            label={t("patients_modal_health_care_id")}
            variant="outlined"
            fullWidth
            sx={() => CustomTextFieldOutlined({ mb: "1.25rem" })}
            InputProps={{
              sx: CustomTextFieldOutlinedInputProps,
            }}
            color="warning"
          />
          {/* Birtdate selector */}
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={getLocale(i18n.language).datepicker}
          >
            <CustomDatePicker
              date={birthDate}
              birthDateError={birthDateError}
              handleChange={handleChange}
            />
          </LocalizationProvider>

          {/* Gender selector */}
          <SearchBox
            data={genderData}
            state={sex}
            setState={setSex}
            label={t("patients_modal_sex")}
            propertyBit={2}
          />

          <SearchBox
            data={sortedLanguageList}
            state={language}
            setState={setLanguage}
            label={t("patients_modal_language")}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={ModalCustomConfirm}
          disabled={shouldDisable || birthDateError}
          onClick={onConfirm}
        >
          {t("button_edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPatientModal;
