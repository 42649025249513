// Library methods
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Utilities
import {
  getVisualField,
  getExamType,
  getExamPatientName,
  getExamAlgorithmTranslation,
} from "../../../../utils/examReportsHelper";
import { getLocalTime } from "../../../../utils/dateHelper";

const RecentlyCompletedExamCard = ({
  row,
  isSelected,
  headCells,
  handleViewReportClick,
}) => {
  // internationalization
  const { t } = useTranslation();

  // exam related helper values
  const examType = useMemo(() => getExamType(row), [row]);
  const examVisualField = useMemo(() => getVisualField(row), [row]);
  const isEsterman = useMemo(() => examType === "Esterman", [examType]);
  const examTranslation = useMemo(
    () => t(getExamAlgorithmTranslation(row)),
    [row, t]
  );
  const examAdditionalInfo = useMemo(() => {
    const shouldNotDisplayVisualField =
      examVisualField === "N.A" || examVisualField === "" || isEsterman;
    return shouldNotDisplayVisualField ? "" : " | " + examVisualField;
  }, [examVisualField, isEsterman]);

  const doneLocalTime = row?.completionDate
    ? getLocalTime(row?.completionDate)
    : "-";

  // sx specific are for small mobile screens
  return (
    <Card
      elevation={0}
      key={row.id}
      sx={{
        marginBottom: 2,
        borderRadius: 2,
        backgroundColor: !isSelected ? "#202338" : "#E2772E",
        color: "white",
        height: { xs: 89, xxs: 52, sm: 52 },
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        px={1}
        py={0}
        sx={{
          display: { xs: "grid", xxs: "flex", sm: "flex" },
          gridTemplateColumns: { xs: "5% 85% 10%", xxs: "none", sm: "none" },
          alignItems: "center",
        }}
      >
        <Grid item xxs={0} sm={0} md={0.5} />
        <Grid
          item
          xxs={11}
          sm={11}
          md={10.5}
          display={{ sx: "block", xxs: "flex", sm: "flex" }}
          flexDirection={{ xxs: "space-between", sm: "space-between" }}
        >
          <Grid item xs={12} xxs={4} sm={4}>
            <Typography
              sx={{
                display: { xs: "block", xxs: "none", sm: "none", md: "none" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              <b>{headCells[0].label}:</b> {getExamPatientName(row)}
            </Typography>
            <Typography
              noWrap
              sx={{
                display: { xs: "none", xxs: "block", sm: "block", md: "block" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              {getExamPatientName(row)}
            </Typography>
          </Grid>
          <Grid item xs={12} xxs={5} sm={5}>
            <Typography
              sx={{
                display: { xs: "block", xxs: "none", sm: "none", md: "none" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              <b>{headCells[1].label}:</b>
              {examTranslation}
              {examAdditionalInfo}
            </Typography>
            <Typography
              noWrap
              sx={{
                display: { xs: "none", xxs: "block", sm: "block", md: "block" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              {examTranslation}
              {examAdditionalInfo}
            </Typography>
          </Grid>
          <Grid item xs={12} xxs={3}>
            <Typography
              sx={{
                display: { xs: "block", xxs: "none", sm: "none", md: "none" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              <b>{headCells[2].label}:</b> {doneLocalTime.slice(0, 10)}
            </Typography>
            <Typography
              noWrap
              sx={{
                display: { xs: "none", xxs: "block", sm: "block", md: "block" },
                fontSize: { xs: "12px", sm: "12px", md: "16px", lg: "1rem" },
              }}
            >
              {doneLocalTime.slice(0, 10)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={headCells[3].gridSize} xxs={headCells[3].gridSize}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", xxs: "end" },
            }}
          >
            <Tooltip title={t("word_view_results")}>
              <span>
                <a
                  href={`/patients/${row?.patient?.id}/exam/${row?.id}`}
                  style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    // Prevent default behavior of <a>
                    e.preventDefault();
                    handleViewReportClick(row?.patient?.id, row?.id);
                  }}
                >
                  <IconButton
                    sx={{
                      color: "white",
                      padding: "4px",
                    }}
                  >
                    <PlagiarismOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </span>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RecentlyCompletedExamCard;
