// MUI Components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const Loader = ({
  containerHeight = "60vh",
  text = undefined,
  size = undefined,
  width = undefined,
  mt = undefined,
}) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ width: width, height: containerHeight }}
    gap={1}
  >
    <CircularProgress
      sx={{ color: "#eb6f05", mt: mt }}
      size={size}
      disableShrink
    />
    {text ? <Typography sx={{ color: "#eb6f05" }}>{text}</Typography> : null}
  </Grid>
);

export default Loader;
