export const possibleChartPoints30 = [
  { x: 1, y: 1 },
  { x: 2, y: 1 },
  { x: 3, y: 1 },
  { x: 4, y: 1 },
  { x: 5, y: 1 },
  { x: 1, y: 2 },
  { x: 2, y: 2 },
  { x: 3, y: 2 },
  { x: 4, y: 2 },
  { x: 5, y: 2 },
  { x: 1, y: 3 },
  { x: 2, y: 3 },
  { x: 3, y: 3 },
  { x: 4, y: 3 },
  { x: 1, y: 4 },
  { x: 2, y: 4 },
  { x: 3, y: 4 },
  { x: 1, y: 5 },
  { x: 2, y: 5 },

  { x: -1, y: 1 },
  { x: -2, y: 1 },
  { x: -3, y: 1 },
  { x: -4, y: 1 },
  { x: -5, y: 1 },
  { x: -1, y: 2 },
  { x: -2, y: 2 },
  { x: -3, y: 2 },
  { x: -4, y: 2 },
  { x: -5, y: 2 },
  { x: -1, y: 3 },
  { x: -2, y: 3 },
  { x: -3, y: 3 },
  { x: -4, y: 3 },
  { x: -1, y: 4 },
  { x: -2, y: 4 },
  { x: -3, y: 4 },
  { x: -1, y: 5 },
  { x: -2, y: 5 },

  { x: 1, y: -1 },
  { x: 2, y: -1 },
  { x: 3, y: -1 },
  { x: 4, y: -1 },
  { x: 5, y: -1 },
  { x: 1, y: -2 },
  { x: 2, y: -2 },
  { x: 3, y: -2 },
  { x: 4, y: -2 },
  { x: 5, y: -2 },
  { x: 1, y: -3 },
  { x: 2, y: -3 },
  { x: 3, y: -3 },
  { x: 4, y: -3 },
  { x: 1, y: -4 },
  { x: 2, y: -4 },
  { x: 3, y: -4 },
  { x: 1, y: -5 },
  { x: 2, y: -5 },

  { x: -1, y: -1 },
  { x: -2, y: -1 },
  { x: -3, y: -1 },
  { x: -4, y: -1 },
  { x: -5, y: -1 },
  { x: -1, y: -2 },
  { x: -2, y: -2 },
  { x: -3, y: -2 },
  { x: -4, y: -2 },
  { x: -5, y: -2 },
  { x: -1, y: -3 },
  { x: -2, y: -3 },
  { x: -3, y: -3 },
  { x: -4, y: -3 },
  { x: -1, y: -4 },
  { x: -2, y: -4 },
  { x: -3, y: -4 },
  { x: -1, y: -5 },
  { x: -2, y: -5 },
];

export const possibleChartPoints24Left = [
  { x: 1, y: 1 },
  { x: 2, y: 1 },
  { x: 3, y: 1 },
  { x: 4, y: 1 },
  { x: 5, y: 1 },
  { x: 1, y: 2 },
  { x: 2, y: 2 },
  { x: 3, y: 2 },
  { x: 4, y: 2 },
  { x: 1, y: 3 },
  { x: 2, y: 3 },
  { x: 3, y: 3 },
  { x: 1, y: 4 },
  { x: 2, y: 4 },

  { x: -1, y: 1 },
  { x: -2, y: 1 },
  { x: -3, y: 1 },
  { x: -4, y: 1 },
  { x: -1, y: 2 },
  { x: -2, y: 2 },
  { x: -3, y: 2 },
  { x: -4, y: 2 },
  { x: -1, y: 3 },
  { x: -2, y: 3 },
  { x: -3, y: 3 },
  { x: -1, y: 4 },
  { x: -2, y: 4 },

  { x: 1, y: -1 },
  { x: 2, y: -1 },
  { x: 3, y: -1 },
  { x: 4, y: -1 },
  { x: 5, y: -1 },
  { x: 1, y: -2 },
  { x: 2, y: -2 },
  { x: 3, y: -2 },
  { x: 4, y: -2 },
  { x: 1, y: -3 },
  { x: 2, y: -3 },
  { x: 3, y: -3 },
  { x: 1, y: -4 },
  { x: 2, y: -4 },

  { x: -1, y: -1 },
  { x: -2, y: -1 },
  { x: -3, y: -1 },
  { x: -4, y: -1 },
  { x: -1, y: -2 },
  { x: -2, y: -2 },
  { x: -3, y: -2 },
  { x: -4, y: -2 },
  { x: -1, y: -3 },
  { x: -2, y: -3 },
  { x: -3, y: -3 },
  { x: -1, y: -4 },
  { x: -2, y: -4 },
];

export const possibleChartPoints24Right = [
  { x: 1, y: 1 },
  { x: 2, y: 1 },
  { x: 3, y: 1 },
  { x: 4, y: 1 },
  { x: 1, y: 2 },
  { x: 2, y: 2 },
  { x: 3, y: 2 },
  { x: 4, y: 2 },
  { x: 1, y: 3 },
  { x: 2, y: 3 },
  { x: 3, y: 3 },
  { x: 1, y: 4 },
  { x: 2, y: 4 },

  { x: -1, y: 1 },
  { x: -2, y: 1 },
  { x: -3, y: 1 },
  { x: -4, y: 1 },
  { x: -5, y: 1 },
  { x: -1, y: 2 },
  { x: -2, y: 2 },
  { x: -3, y: 2 },
  { x: -4, y: 2 },
  { x: -1, y: 3 },
  { x: -2, y: 3 },
  { x: -3, y: 3 },
  { x: -1, y: 4 },
  { x: -2, y: 4 },

  { x: 1, y: -1 },
  { x: 2, y: -1 },
  { x: 3, y: -1 },
  { x: 4, y: -1 },
  { x: 1, y: -2 },
  { x: 2, y: -2 },
  { x: 3, y: -2 },
  { x: 4, y: -2 },
  { x: 1, y: -3 },
  { x: 2, y: -3 },
  { x: 3, y: -3 },
  { x: 1, y: -4 },
  { x: 2, y: -4 },

  { x: -1, y: -1 },
  { x: -2, y: -1 },
  { x: -3, y: -1 },
  { x: -4, y: -1 },
  { x: -5, y: -1 },
  { x: -1, y: -2 },
  { x: -2, y: -2 },
  { x: -3, y: -2 },
  { x: -4, y: -2 },
  { x: -1, y: -3 },
  { x: -2, y: -3 },
  { x: -3, y: -3 },
  { x: -1, y: -4 },
  { x: -2, y: -4 },
];

export const possibleChartPoints120 = [
  { x: 23, y: 36 },
  { x: 57, y: 21 },
  { x: 34, y: 21 },
  { x: 16.5, y: 21 },
  { x: 6, y: 21 },
  { x: 73, y: 10 },
  { x: 57, y: 10 },
  { x: 42, y: 10 },
  { x: 30, y: 10 },
  { x: 20, y: 10 },
  { x: 10, y: 10 },
  { x: 3, y: 10 },
  { x: 75, y: 3 },
  { x: 57, y: 3 },
  { x: 42, y: 3 },
  { x: 30, y: 3 },
  { x: 20, y: 3 },
  { x: 12.5, y: 3 },
  { x: 8, y: 3 },
  { x: 76, y: -3 },
  { x: 57, y: -3 },
  { x: 42, y: -3 },
  { x: 30, y: -3 },
  { x: 20, y: -3 },
  { x: 12.5, y: -3 },
  { x: 8, y: -3 },
  { x: 76, y: -8 },
  { x: 57, y: -8 },
  { x: 42, y: -8 },
  { x: 30, y: -8 },
  { x: 20, y: -8 },
  { x: 12.5, y: -8 },
  { x: 8, y: -8 },
  { x: 3, y: -8 },
  { x: 75, y: -13 },
  { x: 57, y: -13 },
  { x: 42, y: -13 },
  { x: 30, y: -13 },
  { x: 20, y: -13 },
  { x: 12.5, y: -13 },
  { x: 8, y: -13 },
  { x: 3, y: -13 },
  { x: 74, y: -21 },
  { x: 57, y: -21 },
  { x: 42, y: -21 },
  { x: 30, y: -21 },
  { x: 20, y: -21 },
  { x: 12.5, y: -21 },
  { x: 8, y: -21 },
  { x: 3, y: -21 },
  { x: 69, y: -30 },
  { x: 49, y: -30 },
  { x: 33, y: -30 },
  { x: 16.5, y: -30 },
  { x: 5, y: -30 },
  { x: 55, y: -43 },
  { x: 29, y: -43 },
  { x: 8, y: -43 },
  { x: 30, y: -52.5 },
  { x: 8, y: -56.5 },
  { x: -23, y: 36 },
  { x: -57, y: 21 },
  { x: -34, y: 21 },
  { x: -16.5, y: 21 },
  { x: -6, y: 21 },
  { x: -73, y: 10 },
  { x: -57, y: 10 },
  { x: -42, y: 10 },
  { x: -30, y: 10 },
  { x: -20, y: 10 },
  { x: -10, y: 10 },
  { x: -3, y: 10 },
  { x: -75, y: 3 },
  { x: -57, y: 3 },
  { x: -42, y: 3 },
  { x: -30, y: 3 },
  { x: -20, y: 3 },
  { x: -12.5, y: 3 },
  { x: -8, y: 3 },
  { x: -76, y: -3 },
  { x: -57, y: -3 },
  { x: -42, y: -3 },
  { x: -30, y: -3 },
  { x: -20, y: -3 },
  { x: -12.5, y: -3 },
  { x: -8, y: -3 },
  { x: -76, y: -8 },
  { x: -57, y: -8 },
  { x: -42, y: -8 },
  { x: -30, y: -8 },
  { x: -20, y: -8 },
  { x: -12.5, y: -8 },
  { x: -8, y: -8 },
  { x: -3, y: -8 },
  { x: -75, y: -13 },
  { x: -57, y: -13 },
  { x: -42, y: -13 },
  { x: -30, y: -13 },
  { x: -20, y: -13 },
  { x: -12.5, y: -13 },
  { x: -8, y: -13 },
  { x: -3, y: -13 },
  { x: -74, y: -21 },
  { x: -57, y: -21 },
  { x: -42, y: -21 },
  { x: -30, y: -21 },
  { x: -20, y: -21 },
  { x: -12.5, y: -21 },
  { x: -8, y: -21 },
  { x: -3, y: -21 },
  { x: -69, y: -30 },
  { x: -49, y: -30 },
  { x: -33, y: -30 },
  { x: -16.5, y: -30 },
  { x: -5, y: -30 },
  { x: -55, y: -43 },
  { x: -29, y: -43 },
  { x: -8, y: -43 },
  { x: -30, y: -52.5 },
  { x: -8, y: -56.5 },
];

export const getVisualFieldGrid = (gridType, eye) => {
  if (gridType === "G_30_2") return possibleChartPoints30;
  if (gridType === "G_120") return possibleChartPoints120;

  return eye === "Left"
    ? possibleChartPoints24Left
    : possibleChartPoints24Right;
};
