// Components
import VisualFieldReport from "../VisualFieldReport";
import EstermanReport from "../EstermanReport";
import ColorVisionReport from "../ColorVisionReport";
import ContrastSensitivityReport from "../ContrastSensitivityReport";
import SingleThresholdReport from "../SingleThresholdReport";
import Breadcrumb from "../../../components/UI/ReportBreadcrumb";
import Loader from "../../../components/UI/Loader";
import PageIntro from "../../../components/UI/PageIntro";

// Utilities
import { getExamType } from "../../../utils/examReportsHelper";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";
import PrintButton from "../../../components/UI/PrintButton";
import { ErrorMessageComponent } from "../../../components/UI/ErrorMessage";
import { useContext, useMemo } from "react";
import { ReportDataContext } from "../../../contexts/ReportDataContext";
import { CommentAPInfoContext } from "../../../contexts/CommentContext";
import {
  AlgorithmContext,
  ExamTypeContext,
} from "../../../contexts/ExamPropertyContext";
import VisualAcuityReport from "../VisualAcuityReport";
import { Grid, ThemeProvider, createTheme } from "@mui/material";

const ReportPanel = ({
  downloadPdf,
  shouldDisplayReport,
  fileInfo,
  reportInfoError,
  shouldDisplayDicomButton,
}) => {
  const bpTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1700,
      },
    },
  });
  const { TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  const { landoltC, snellen } = useContext(AlgorithmContext);
  const { isLoading, patientName, exam } = useContext(ReportDataContext);
  const { patientId } = useContext(CommentAPInfoContext);
  const examAlgorithm = getExamType(exam);

  const ReportComponent = useMemo(() => {
    switch (examAlgorithm) {
      case "Esterman":
        return <EstermanReport />;
      case "D-15":
        return <ColorVisionReport />;
      case "Pelli Robson":
      case "Tumbling E":
        return <ContrastSensitivityReport />;
      case TYPE_VISUAL_ACUITY:
      case landoltC:
      case snellen:
        return <VisualAcuityReport />;
      case "Single Threshold":
        return <SingleThresholdReport />;
      default:
        return <VisualFieldReport />;
    }
  }, [TYPE_VISUAL_ACUITY, examAlgorithm, landoltC, snellen]);

  return (
    <>
      <ValidatePDFContext.Provider value={{ forBackendPdf: false }}>
        {isLoading ? (
          <Loader />
        ) : (
          <ThemeProvider theme={bpTheme}>
            <PageIntro
              pageTitle="Report"
              showBreadcrumb
              BreadcrumbComponent={
                <Breadcrumb
                  isLoading={isLoading}
                  patientId={patientId}
                  patientName={patientName}
                />
              }
              showAddButton={false}
              showDeleteButton={false}
              showFilterInput={false}
              desktopMarginTop={3}
              desktopMarginBottom={3}
            />
            {shouldDisplayReport && (
              <Grid container display={"flex"}>
                <Grid item>
                  <PrintButton
                    isLoading={
                      isLoading ||
                      (fileInfo?.pdf && fileInfo?.pdf.hasBlobStorageError)
                    }
                    handleDownloadPdf={downloadPdf}
                    fileInfo={fileInfo}
                    reportInfoError={reportInfoError}
                  />
                </Grid>
                {shouldDisplayDicomButton && (
                  <Grid item ml={{ xs: 0, sm: 1, md: 2 }}>
                    <PrintButton
                      isLoading={
                        isLoading ||
                        (fileInfo?.dicom &&
                          fileInfo?.dicom?.hasBlobStorageError)
                      }
                      handleDownloadPdf={downloadPdf}
                      fileInfo={fileInfo}
                      reportInfoError={reportInfoError}
                      fileType={"dicom"}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {!shouldDisplayReport ? <ErrorMessageComponent /> : ReportComponent}
          </ThemeProvider>
        )}
      </ValidatePDFContext.Provider>
    </>
  );
};

export default ReportPanel;
