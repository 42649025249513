// MUI Components
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

const PaginationWrapper = ({
  page,
  count,
  onChange,
  shouldShrink = false,
  display,
}) => (
  <Grid
    container
    pb={4}
    direction="row"
    alignContent="center"
    justifyContent="center"
  >
    <Pagination
      data-testid="pagination"
      page={page}
      count={count || 0}
      size={"small"}
      onChange={onChange}
      {...(shouldShrink && {
        siblingCount: 0,
        boundaryCount: 0,
      })}
      sx={{ display: display }}
    />
  </Grid>
);

export default PaginationWrapper;
