import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ModalCustomCancel,
  ModalCustomConfirm,
} from "../../../styles/muiStylesHelper";

export const TermSwitcher = ({ term, setTerm }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Button
        onClick={() => setTerm(0)}
        sx={{
          ...(term === 0 ? ModalCustomConfirm() : ModalCustomCancel()),
          mr: 1.5,
          fontSize: { xs: 12 },
        }}
      >
        {t("Today")}
      </Button>
      <Button
        onClick={() => setTerm(7)}
        sx={{
          ...(term === 7 ? ModalCustomConfirm() : ModalCustomCancel()),
          mr: 1.5,
          fontSize: { xs: 12 },
        }}
      >
        {i18n.language === "fr"
          ? `7 ${t("word_last")} ${t("word_days")}`
          : `${t("word_last")} 7 ${t("word_days")}`}
      </Button>
    </>
  );
};
