// MUI Components
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({ errorBit = 0 }) => {
  const { t } = useTranslation();
  const messages = {
    0: {
      baseText: t("report_error_help"),
      splitBy: "email",
    },
    1: {
      baseText: t("post_processing_error_help"),
      splitBy: "email",
    },
  };
  const helpMessageParts = messages[errorBit].baseText.split(
    messages[errorBit].splitBy
  );
  return (
    <>
      {!errorBit && <Typography variant="h5">{t("report_error")}</Typography>}
      <Typography variant="h5">
        {helpMessageParts[0]}
        <b>support@retinalogik.ca</b>
        {helpMessageParts[1]}
      </Typography>
    </>
  );
};

const ErrorMessageComponent = ({ errorBit = 0 }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="50vh">
      <Grid
        item
        jisplay="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <ErrorMessage errorBit={errorBit} />
      </Grid>
    </Grid>
  );
};

export { ErrorMessage, ErrorMessageComponent };
