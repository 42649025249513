// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
// import { Divider } from "@mui/material";

// Utilities
import { getLocalDateAndTimeWithZone } from "../../../../utils/localeHelper";
import { calculateAge } from "../../../../utils/examReportsHelper";

const PatientInfoSection = ({ patient }) => {
  // internationalization
  const { t } = useTranslation();
  const sexTitles = {
    Male: t("gender_male"),
    Female: t("gender_female"),
    Undefined: t("word_na"),
  };
  return (
    <Grid
      item
      xs={9}
      display="flex"
      flexDirection="column"
      my={{ xs: 2, sm: 6 }}
      px={{ xs: 1, sm: 3 }}
      py={2}
      sx={{
        border: "1px solid rgba(0,0,0,0.2)",
        borderRadius: 3,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={2.5}
          sm={4}
          md={3}
          lg={4}
          sx={{ display: "flex", gap: 1 }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: { xs: 12, sm: 14, lg: 16 } }}
          >
            {t("exams_patient_age") + ":"}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            fontSize={{ xs: 12, sm: 14, lg: 16 }}
          >
            {patient?.age ?? calculateAge(patient.dateOfBirth)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={3.5}
          lg={4}
          sx={{ display: "flex", gap: 1 }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: { xs: 12, sm: 14, lg: 16 } }}
          >
            {t("patients_modal_sex") + ":"}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            fontSize={{ xs: 12, sm: 14, lg: 16 }}
          >
            {sexTitles[patient.sex]}
          </Typography>
        </Grid>
        <Grid
          item
          xs={5.5}
          sm={4}
          md={5.5}
          lg={4}
          sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: { xs: 12, sm: 14, lg: 16 } }}
          >
            {t("patients_edit_modal_birth") + ":"}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            fontSize={{ xs: 12, sm: 14, lg: 16 }}
          >
            {getLocalDateAndTimeWithZone(null, patient?.dateOfBirth)?.date ??
              "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInfoSection;
