// Library methods
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

// Components
import ClinicSwitcher from "../ClinicSwitcher";
import UserMenuWrapper from "../UserMenuWrapper";

// Assets
import eyeLogo from "../../../assets/images/logos/eyelogo.svg";
import TabSwitcher from "../TabSwitcher";
import { forwardRef, useContext, useEffect } from "react";
import useLayoutHeight from "../../../hooks/useLayoutHeight";
import NewAppVersionWarningPopup from "../NewAppVersionWarningPopup";
import { DeviceAppVersionContext } from "../../../contexts/DeviceContext";
import DeviceUpdateInstructionPopup from "../DeviceUpdateInstructionPopup";

const Navbar = forwardRef(
  ({ canReachPages = true, clickableLogo = true, onHeightChange }, ref) => {
    // internationalization
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const height = useLayoutHeight(ref);

    // device app version
    const { newAppVersionWarningPopup } = useContext(DeviceAppVersionContext);
    useEffect(() => {
      // Only call the onHeightChange callback if it's provided
      if (onHeightChange) {
        onHeightChange(height);
      }
    }, [height, onHeightChange]);

    const navbarPages = [
      {
        title: t("tab_dashboard"),
        path: "/dashboard",
      },
      {
        title: t("tab_patients"),
        path: "/patients",
      },
    ];

    return (
      <>
        <AppBar
          ref={ref}
          position="relative"
          elevation={4}
          sx={{
            backgroundColor: "#202338",
            color: "#282c3c",
            height: { xs: 42, sm: 85 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box
              component="img"
              sx={{
                flexGrow: 1,
                maxWidth: { xs: 47, sm: 73 },
                mx: { xs: 3, md: 5, lg: 5 },
                my: 3,
                display: { sm: "flex", md: "flex" },
                ...(clickableLogo ? { cursor: "pointer" } : {}),
              }}
              alt="RetinaLogik Logo"
              src={eyeLogo}
              {...(clickableLogo ? { onClick: () => navigate("/") } : {})}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              {canReachPages &&
                navbarPages.map((page) => (
                  <Button
                    key={page.title}
                    onClick={() => navigate(page.path)}
                    sx={{
                      my: 1.5,
                      mr: { sm: 3, md: 5, lg: 5 },
                      // mx: { md: 5, lg: 4 },
                      color: "#E7EBF8",
                      display: "block",
                      textTransform: "capitalize",
                      fontSize: 20,
                      ...(location.pathname === page.path && {
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textUnderlineOffset: 4,
                      }),
                      "&:hover": {
                        ...(location.pathname === page.path && {
                          textDecoration: "underline",
                          textUnderlineOffset: 4,
                        }),
                      },
                    }}
                  >
                    {page.title}
                  </Button>
                ))}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
              mr={2}
            >
              <Box py={{ xs: 1 }} display={"flex"} alignItems={"center"}>
                <ClinicSwitcher />
                <UserMenuWrapper canReachPages={canReachPages} />
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", sm: "none", md: "none" },
                  }}
                >
                  <TabSwitcher navbarPages={navbarPages} location={location} />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {newAppVersionWarningPopup && <NewAppVersionWarningPopup />}
        <DeviceUpdateInstructionPopup />
      </>
    );
  }
);
export default Navbar;
