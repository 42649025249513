// Styles
import styles from "./index.module.css";

const Triangle = () => (
  <div className={styles.triangleUp}>
    <div className={styles.innerTriangle}></div>
  </div>
);

export default Triangle;
