// Library Methods
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

// MUI Components
import { Button, FormControl, Grid, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { ClickAwayListener } from "@mui/base";

// Components
import ExamSliderCard from "../ExamSliderCard";

// Utilities
import { getLocale } from "../../../../utils/localeHelper";
import {
  CustomTextFieldOutlined,
  ModalCustomCancel,
  ModalCustomConfirm,
} from "../../../../styles/muiStylesHelper";
import useClinic from "../../../../hooks/useClinic";
import { ExamUtilContext } from "../../../../contexts/ExamPropertyContext";
import { getClinics } from "../../../../services/Clinic";
import CustomDatePicker from "../../../../components/UI/CustomDatePIcker";
import SearchBox from "../../../../components/UI/SearchBox";
import { FixationLossMethodOptions } from "../../../../utils/examReportsHelper";

const AddExamSlider = ({
  visible,
  onClose,
  examFieldType,
  setExamFieldType,
  onConfirm,
  examAlgorithm,
  visualGrid,
  stimulusSizeType,
  fixationType,
  requiresTutorial,
  scheduledDate,
  leftCheckBox,
  rightCheckBox,
  setExamAlgorithm,
  setVisualGrid,
  setRequiresTutorial,
  setScheduledDate,
  setLeftCheckBox,
  setLeftDpSphere,
  setLeftDpCylinder,
  setLeftDpAxis,
  setLeftLtlSphere,
  setLeftLtlCylinder,
  setLeftLtlAxis,
  setRightCheckBox,
  setRightDpSphere,
  setRightDpCylinder,
  setRightDpAxis,
  setRightLtlSphere,
  setRightLtlCylinder,
  setRightLtlAxis,
  devicesOptions = [],
  editExamDevice,
  setEditExamDevice,
  setStimulusSizeType,
  setFixationType,
  autoPause,
  setAutoPause,
  encouragement,
  setEncouragement,
  calibration,
  setCalibration,
  unlimitedTime,
  setUnlimitedTime,
  position,
  setPosition,
  sectionOrder,
  setSectionOrder,
  scheduledError,
  setScheduledError,
  binocular,
  setBinocular,
}) => {
  // internationalization
  const { t } = useTranslation();

  // algorithm context
  // const { path, titles, TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  // const { full120 } = useContext(AlgorithmContext);
  const { examUtils } = useContext(ExamUtilContext);

  // navigate
  const navigate = useNavigate();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // clinic context
  const { clinicId, clinicName } = useClinic();

  // visual field default values for the clinic
  const [vfExamDefaultValues, setVfExamDefaultValues] = useState(undefined);

  const formattedDevicesOptions = useMemo(
    () =>
      devicesOptions.map((option) => {
        return { name: option?.alias, val: option?.id };
      }),
    [devicesOptions]
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = false;
    devicesOptions.forEach((option) => {
      if (option?.id === editExamDevice?.val) isIncluded = true;
    });
    return (
      scheduledError ||
      !(
        examAlgorithm &&
        // visualGrid &&
        scheduledDate &&
        (leftCheckBox || rightCheckBox) &&
        requiresTutorial !== (undefined || "")
      ) ||
      (editExamDevice?.val && editExamDevice?.val !== "" && !isIncluded)
    );
  }, [
    devicesOptions,
    editExamDevice?.val,
    examAlgorithm,
    leftCheckBox,
    requiresTutorial,
    rightCheckBox,
    scheduledDate,
    scheduledError,
  ]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener("keydown", handleEscKey);
    } else {
      document.removeEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [visible, onClose]);

  // initial data fetching
  const fetchClinic = useCallback(async () => {
    if (!clinicName) navigate("/invalid");
    try {
      // get authToken
      const token = await getAccessTokenSilently();
      const { settings } = await getClinics(token, clinicId);

      if (settings) {
        setVfExamDefaultValues((prev) => ({
          ...(prev ? prev : {}),
          examAlgorithm: settings.visualFieldDefaultAlgorithm,
          visualGrid: settings.visualFieldDefaultGridType,
          stimulusSizeType: settings.visualFieldDefaultStimulusSizeType,
          requiresTutorial: settings.visualFieldDefaultRequiresTutorial,
          leftCheckBox:
            settings.visualFieldDefaultEye === "Left" ||
            settings.visualFieldDefaultEye === "Both",
          rightCheckBox:
            settings.visualFieldDefaultEye === "Right" ||
            settings.visualFieldDefaultEye === "Both",
          autoPause: settings.visualFieldDefaultAutoPause,
          encouragement: settings.visualFieldDefaultEncouragement,
          calibration: settings.visualFieldDefaultCalibration,
          fixationType: {
            fixationColorType: null,
            fixationPointShapeType:
              settings.visualFieldDefaultFixationPointShapeType,
            fixationLossMethod:
              settings?.visualFieldDefaultFixationLossMethodType ??
              FixationLossMethodOptions.EyeTracking,
          },
          rightFirst: settings?.examDefaultRightEyeFirst,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  }, [clinicId, clinicName, getAccessTokenSilently, navigate]);

  const handleChange = useCallback(
    (newValue) => {
      const isDateValid = !isNaN(new Date(newValue).getTime());
      const today = new Date();
      // Set time to the start of the day to avoid time zone issues
      today.setHours(0, 0, 0, 0);

      if (isDateValid && newValue >= today) {
        setScheduledError(0);
        setScheduledDate(newValue);
      } else {
        setScheduledError(1);
      }
    },
    [setScheduledDate, setScheduledError]
  );

  const fetchClinicCall = useCallback(async () => {
    try {
      await fetchClinic();
    } catch (e) {
      console.log(e);
    } finally {
    }
  }, [fetchClinic]);

  useEffect(() => {
    fetchClinicCall();
  }, [fetchClinicCall]);

  // If the modal is not open, don't render anything
  if (!visible) {
    return null;
  }

  return (
    <>
      {visible && (
        <Grid
          container
          backgroundColor="rgba(52, 64, 84, 0.7)"
          onClick={onClose}
          position="fixed"
          top={"0"}
          bottom={"0"}
          right={"0"}
          left={"0"}
          style={{
            cursor: "pointer",
            zIndex: 1,
            // transition: "all 30s ease-in-out",
          }}
        >
          {" "}
        </Grid>
      )}

      <Grid
        item
        height="100%"
        position="fixed"
        width={{ xs: "95%", sm: "80%" }}
        top={"0"}
        bottom={"0"}
        right={"0"}
        padding={{ xs: "1rem", sm: "2em", md: "2rem" }}
        sx={{
          transform: visible ? "translateX(0)" : "translateX(100%)",
          transition: "transform .8s ease-in-out",
          cursor: "auto",
          zIndex: 1000,
          backgroundColor: "#FFFFFF",
          boxShadow: "-20px 0px 25px rgba(0, 0, 0, 0.05)",
          overflowX: "hidden",
        }}
      >
        <Grid
          item
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          mb={2}
        >
          <FontAwesomeIcon icon={faCalendarPlus} size="lg" />
          <Typography style={{ fontWeight: "700" }} variant="h5" component="h2">
            {t("exams_modal_title")}
          </Typography>
        </Grid>

        <Grid
          item
          display={"flex"}
          sx={{
            justifyContent: {
              xs: "start",
              sm: "start",
              md: "end",
              lg: "end",
            },
          }}
        >
          <Grid
            item
            display={"flex"}
            sx={{
              gap: 3,
              float: {
                xs: "end",
                sm: "end",
                md: "right",
                lg: "right",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
              marginTop: { xs: 0, sm: 0, md: "-50px", lg: "-50px" },
            }}
          >
            <FormControl
              fullWidth
              sx={() =>
                CustomTextFieldOutlined({
                  mb: "1.25rem",
                })
              }
              color="warning"
            >
              {/* Scheduled Date */}
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={getLocale(i18next.language).datepicker}
              >
                <CustomDatePicker
                  date={scheduledDate}
                  labelTitle="exams_modal_scheduled_date"
                  height={30}
                  scheduledError={scheduledError}
                  handleChange={handleChange}
                />
              </LocalizationProvider>
            </FormControl>

            {/* Device selector */}
            <SearchBox
              data={formattedDevicesOptions}
              state={editExamDevice}
              setState={setEditExamDevice}
              propertyBit={4}
              label={
                formattedDevicesOptions.length
                  ? t("device_link_modal_devices")
                  : t("device_unavailable_modal_title")
              }
            />
          </Grid>
        </Grid>

        <Grid
          item
          display="flex"
          justifyContent="space-around"
          flexWrap={"wrap"}
        >
          {examUtils?.map((item) => (
            <ExamSliderCard
              key={item?.value}
              label={item?.label}
              examType={item}
              examFieldType={examFieldType}
              setExamFieldType={setExamFieldType}
              examAlgorithm={examAlgorithm}
              visualGrid={visualGrid}
              stimulusSizeType={stimulusSizeType}
              fixationType={fixationType}
              requiresTutorial={requiresTutorial}
              leftCheckBox={leftCheckBox}
              rightCheckBox={rightCheckBox}
              sectionOrder={sectionOrder}
              setExamAlgorithm={setExamAlgorithm}
              setVisualGrid={setVisualGrid}
              setRequiresTutorial={setRequiresTutorial}
              setLeftCheckBox={setLeftCheckBox}
              setLeftDpSphere={setLeftDpSphere}
              setLeftDpCylinder={setLeftDpCylinder}
              setLeftDpAxis={setLeftDpAxis}
              setLeftLtlSphere={setLeftLtlSphere}
              setLeftLtlCylinder={setLeftLtlCylinder}
              setLeftLtlAxis={setLeftLtlAxis}
              setRightCheckBox={setRightCheckBox}
              setRightDpSphere={setRightDpSphere}
              setRightDpCylinder={setRightDpCylinder}
              setRightDpAxis={setRightDpAxis}
              setRightLtlSphere={setRightLtlSphere}
              setRightLtlCylinder={setRightLtlCylinder}
              setRightLtlAxis={setRightLtlAxis}
              setStimulusSizeType={setStimulusSizeType}
              setFixationType={setFixationType}
              setSectionOrder={setSectionOrder}
              autoPause={autoPause}
              setAutoPause={setAutoPause}
              encouragement={encouragement}
              setEncouragement={setEncouragement}
              calibration={calibration}
              setCalibration={setCalibration}
              unlimitedTime={unlimitedTime}
              setUnlimitedTime={setUnlimitedTime}
              visualFieldExamDefaultValues={vfExamDefaultValues}
              position={position}
              setPosition={setPosition}
              binocular={binocular}
              setBinocular={setBinocular}
            />
          ))}
        </Grid>

        <Grid
          item
          sx={{
            padding: 3,
            display: "flex",
            justifyContent: "end",
            gap: 3,
          }}
        >
          <Button variant="text" sx={ModalCustomCancel} onClick={onClose}>
            {t("button_cancel")}
          </Button>
          <Button
            variant="outlined"
            sx={ModalCustomConfirm}
            disabled={shouldDisable}
            onClick={onConfirm}
          >
            {t("button_add")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddExamSlider;
