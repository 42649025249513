import { useState, useEffect } from "react";

const useLayoutHeight = (componentRef) => {
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const localRef = componentRef?.current ? componentRef?.current : null;

    // Function to update the component height
    const updateLayoutHeight = () => {
      if (componentRef && localRef) {
        const measuredHeight = localRef.offsetHeight;
        setHeight(measuredHeight);
      }
    };

    // Create a ResizeObserver to observe changes in the dimensions of the component
    const resizeObserver = new ResizeObserver(() => {
      updateLayoutHeight();
    });

    // Create a MutationObserver to observe changes in the DOM tree of the App component.
    const mutationObserver = new MutationObserver(() => {
      updateLayoutHeight();
    });

    // Observe the componentRef if it's available
    if (componentRef && localRef) {
      resizeObserver.observe(localRef);
      mutationObserver.observe(localRef, {
        childList: true,
        subtree: true,
      });
    }

    // Call updateLayoutHeight once on mount to ensure initial values are set
    updateLayoutHeight();

    // Clean up the observer when the component unmounts
    return () => {
      if (componentRef && localRef) {
        // resizeObserver.unobserve(localRef);
        resizeObserver.unobserve(localRef);
        mutationObserver.disconnect();
      }
    };
  }, [componentRef]);

  return height;
};

export default useLayoutHeight;
