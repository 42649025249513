// Library methods
import React, { useEffect, useState } from "react";

// MUI Components
import { Grid, Typography } from "@mui/material";

// Components
import VisualAcuityBar from "./VisualAcuityBar";

// Utilities
import styles from "./index.module.css";

const VisualAcuityGraph = ({
  examData,
  isLandoltC = null,
  isSnellen = null,
  isLiveUpdate = null,
}) => {
  const [displayedDirections, setDisplayedDirections] = useState([]);

  const lettersMap = isSnellen
    ? null
    : isLandoltC
      ? {
          E: "e",
          SE: "f",
          S: "g",
          SW: "h",
          W: "i",
          NW: "j",
          N: "k",
          NE: "l",
        }
      : {
          UP: "d",
          DOWN: "b",
          LEFT: "c",
          RIGHT: "a",
        };

  // Static values
  const eyeChartDenominators = [
    200, 100, 80, 63, 50, 40, 32, 25, 20, 15, 13, 10,
  ];

  useEffect(() => {
    const directions =
      examData?.state?.directions ?? examData?.state?.letters ?? [];
    let newDisplayedDirections = [];

    directions.forEach((dir) => {
      newDisplayedDirections.push(
        dir.letters.map((char) => ({
          color:
            char?.State === "GuessedCorrectly"
              ? "#13A321"
              : char?.State === "Skipped"
                ? "#ACA8A8"
                : char?.State === "None"
                  ? "#202338"
                  : "#B21515",
          value: lettersMap ? lettersMap[char?.Value] : char?.Value,
        }))
      );
    });
    if (
      examData?.state?.letterIndex >= 0 &&
      examData?.state?.rowIndex >= 0 &&
      newDisplayedDirections[examData.state.rowIndex][
        examData.state.letterIndex
      ]
    ) {
      newDisplayedDirections[examData.state.rowIndex][
        examData.state.letterIndex
      ]["color"] = "#E2772E";
    }
    setDisplayedDirections(newDisplayedDirections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    examData?.state?.directions,
    examData?.state?.letterIndex,
    examData?.state?.rowIndex,
  ]);

  return (
    <Grid container item>
      {displayedDirections?.length > 0 &&
        displayedDirections.map((dirs, index) => (
          <Grid container item key={index}>
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"right"}
              xs={2}
            >
              <Typography
                textAlign={"center"}
              >{`20/${eyeChartDenominators[index]}`}</Typography>
            </Grid>
            <Grid container item xs={8}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                position={index === 5 || index === 8 ? "relative" : "static"}
              >
                {dirs.map((letter, j) => {
                  const value = letter?.value;
                  return (
                    <Grid item key={j}>
                      <Typography
                        variant="h5"
                        display="inline-block"
                        color={letter?.color}
                        className={
                          isSnellen
                            ? styles.snellen_chart_font
                            : styles.eye_chart_font
                        }
                      >
                        {value}
                      </Typography>
                    </Grid>
                  );
                })}
                {(index === 5 || index === 8) && (
                  <VisualAcuityBar
                    index={index}
                    isLiveUpdate={isLiveUpdate}
                    // bgcolor={index === 1 ? "green" : "red"}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item display={"flex"} alignItems={"center"} xs={2}>
              <Typography textAlign={"right"}>{index + 1}</Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default VisualAcuityGraph;
