const ColoredCircle = ({ isOrange, size = 8 }) => (
  <div
    style={{
      backgroundColor: isOrange ? "orange" : "green",
      border: `1px solid ${isOrange ? "orange" : "green"}`,
      width: size,
      height: size,
      borderRadius: "50%",
      MozBorderRadius: "50%",
      WebkitBorderRadius: "50%",
    }}
  />
);

export default ColoredCircle;
