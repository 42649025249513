const themeInfo = {
  typography: {
    fontFamily: ["Raleway", "Helvetica", "Arial", "Verdana", "sans-serif"].join(
      ","
    ),
  },
  breakpoints: {
    values: {
      xs: 0,
      xxs: 350,
      sm: 750,
      md: 1300,
      lg: 1400,
      xl: 1700,
      xxl: 1850,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
};

export default themeInfo;
