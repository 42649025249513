// Library methods
import { useState, useEffect, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import ExpandDevicesModal from "../../../components/UI/ExpandDevicesModal";
import PageIntro from "../../../components/UI/PageIntro";
import DevicesCard from "../../../components/UI/DevicesCard";
import Loader from "../../../components/UI/Loader";

// Utilities
import useClinic from "../../../hooks/useClinic";
import { getComparator } from "../../../utils/tableHelper";

import useLayoutHeight from "../../../hooks/useLayoutHeight";
import { useDevices } from "../../../hooks/useDevices";

const DevicesList = forwardRef(
  (
    { rows, isLoading, patients, devicesWithExams, connection, onHeightChange },
    ref
  ) => {
    // internationalization
    const { t } = useTranslation();

    // clinic context
    const { clinicId } = useClinic();

    const {
      unlinkLoading,
      linkLoading,
      unlinkDeviceModal,
      linkDeviceModal,
      unlinkSuccessMessage,
      unlinkFailureMessage,
      linkSuccessMessage,
      linkFailureMessage,
      handleUnlinkDeviceClick,
      handleLinkDeviceClick,
      checkIfDeviceHasExamInProgress,
    } = useDevices(connection, clinicId, patients, devicesWithExams);

    // order values
    const order = "asc";
    const orderBy = ["alias"];

    // states init
    const [expandDevicesModal, setExpandDevicesModal] = useState(false);

    // state for the search bar
    const [searchTerm, setSearchTerm] = useState("");

    const [check, setCheck] = useState("all");

    const height = useLayoutHeight(ref);
    useEffect(() => {
      // Only call the onHeightChange callback if it's provided
      if (onHeightChange) {
        onHeightChange(height);
      }
    }, [height, onHeightChange]);

    // filter change
    const onFilterChange = (event) => {
      setSearchTerm(event.target.value);
    };

    // handle on close of expand devices modal
    const handleExpandDevicesClose = (event, reason) => {
      if (reason !== "backdropClick") {
        setExpandDevicesModal(false);
      }
    };

    // Filtered rows
    const filteredRows = useMemo(
      () =>
        rows
          .slice()
          .filter((row) => {
            if (
              searchTerm.length &&
              row.name.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
            )
              return false;
            return true;
          })
          .sort(getComparator(order, orderBy)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rows, searchTerm]
    );

    const selectedDevicesRows = () => {
      if (check === "linked") {
        return filteredRows?.filter((row) => row?.associatedExam);
      } else if (check === "unlinked") {
        return rows?.filter((row) => !row?.associatedExam);
      }
      return filteredRows;
    };

    const rowsToRender = selectedDevicesRows();

    const ListElement = () =>
      isLoading ? (
        <Loader containerHeight="19.5vh" />
      ) : (
        <Grid
          container
          mt={3}
          spacing={3}
          mb={2}
          pb={2}
          sx={{ overflowX: "scroll", flexWrap: "nowrap" }}
        >
          {rowsToRender.length > 0 ? (
            <>
              {rowsToRender.map((row) => {
                return (
                  <Grid item key={row.id}>
                    <DevicesCard
                      key={row.id}
                      row={row}
                      handleLinkDeviceClick={handleLinkDeviceClick}
                      handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                      isExamInProgress={checkIfDeviceHasExamInProgress(row.id)}
                      devicesWithExams={devicesWithExams}
                      unlinkLoading={unlinkLoading}
                      linkLoading={linkLoading}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={12}>
              <Box my={8} display="flex" justifyContent="center">
                <Typography
                  noWrap
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                  color="text.secondary"
                >
                  {t("word_no_devices")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      );

    const ListElementExpanded = () =>
      isLoading ? (
        <Loader containerHeight="19.5vh" />
      ) : (
        <Grid container mt={3} spacing={3} mb={2}>
          {filteredRows.length > 0 ? (
            filteredRows.map((row) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={row.id}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <DevicesCard
                    key={row.id}
                    row={row}
                    handleLinkDeviceClick={handleLinkDeviceClick}
                    handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                    isExamInProgress={checkIfDeviceHasExamInProgress(row.id)}
                    devicesWithExams={devicesWithExams}
                    unlinkLoading={unlinkLoading}
                    linkLoading={linkLoading}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Box my={8} display="flex" justifyContent="center">
                <Typography
                  noWrap
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                  color="text.secondary"
                >
                  {t("word_no_devices")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      );

    return (
      <>
        <Box ref={ref}>
          <PageIntro
            rows={rows}
            pageTitle={t("devices_title")}
            showAddButton={false}
            showDeleteButton={false}
            onFilterChange={onFilterChange}
            desktopMarginTop={2}
            desktopMarginBottom={-4}
            showExpandButton={true}
            showFilterInput={false}
            setCheck={setCheck}
            expandButtonOnClick={() => setExpandDevicesModal(true)}
          />
          {ListElement()}

          {/* Unlink device exam modal */}
          {unlinkDeviceModal}

          {/* Link device exam modal */}
          {linkDeviceModal}
          {/* Expand devices modal */}
          <ExpandDevicesModal
            open={expandDevicesModal}
            onClose={handleExpandDevicesClose}
            onCancel={() => setExpandDevicesModal(false)}
          >
            {ListElementExpanded()}
          </ExpandDevicesModal>

          {/* Unlink device success/failure toasts */}
          {unlinkSuccessMessage}
          {unlinkFailureMessage}
          {linkSuccessMessage}
          {linkFailureMessage}
        </Box>
      </>
    );
  }
);

export default DevicesList;
