// Library methods
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";

// MUI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import AddCommentModal from "../../../../../components/UI/AddCommentModal";

// Assets
import eyeLogo from "../../../../../assets/images/logos/eyelogo.png";

// Utilities
import {
  calculateAgeFromExamDate,
  FixationLossMethodOptions,
} from "../../../../../utils/examReportsHelper";
import { getLocalDateAndTimeWithZone } from "../../../../../utils/localeHelper";
import { ValidatePDFContext } from "../../../../../contexts/ValidatePDFContext";
import { useLiveReportData } from "../../../../../hooks/useLiveReporData";
import { ReportDataContext } from "../../../../../contexts/ReportDataContext";
import {
  ReportInfoHeaderContent,
  ReportInfoShared,
} from "../../../../../components/UI/ReportInfoShared";
import { ReportInfoEachSectionContext } from "../../../../../contexts/ReportInfoEachSectionContext";
import { availableLanguages } from "../../../../../i18n/languages";

const ReportInfo = () => {
  // internationalization
  const { t, i18n } = useTranslation();

  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { exam, patient, examReport, duration, clinicTimezone } =
    useContext(ReportDataContext);

  // handling live data
  const { falsePositivePercentage, falseNegative, fixationLossPercentage } =
    useLiveReportData(examReport);

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: ["Arial", "Verdana", "sans-serif"],
      },
    },
  });

  const isWiderThanSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const isEn = useMemo(() => {
    const en = availableLanguages[0];
    const language = i18n.language;
    return language.includes(en);
  }, [i18n.language]);

  const localDateAndTimeWithZone = useMemo(
    () =>
      examReport?.timing?.start
        ? getLocalDateAndTimeWithZone(
            forBackendPdf ? clinicTimezone : null,
            examReport.timing.start
          )
        : null,
    [clinicTimezone, examReport?.timing?.start, forBackendPdf]
  );
  const gridSizeData = useMemo(
    () => [
      { xs: 5.5, sm: 3, md: 2.8, lg: 2.8, xl: 2.8 },
      { xs: 6.5, sm: 3.6, md: 3.8, lg: isEn ? 3.8 : 4.8, xl: isEn ? 3.8 : 4.7 },
      { xs: 5.5, sm: 2.8, md: 2.8, lg: isEn ? 2.8 : 2.3, xl: isEn ? 2.8 : 2.3 },
      { xs: 6.5, sm: 2.6, md: 2.6, lg: isEn ? 2.6 : 2.1, xl: isEn ? 2.6 : 2.2 },
    ],
    [isEn]
  );
  const infoData = useMemo(
    () => [
      [
        {
          title: t("word_eye"),
          body: t("word_binocular"),
        },
        {
          title: t("word_sex"),
          body: exam?.patient?.sex,
        },
        {
          title: t("report_age"),
          body: exam?.age
            ? exam.age
            : calculateAgeFromExamDate(patient.dateOfBirth, exam?.creationDate),
        },
        {
          title: t("report_stimulus_size"),
          body: `${examReport?.exam?.stimulusSize}, ${t("ethnicity_white")}`,
        },
      ],
      [
        { title: t("report_stimulus_intensity"), body: "10db" },
        { title: t("report_test_mode"), body: t("report_single_intensity") },
        { title: t("report_binocular_fixation_loss"), body: t("word_false") },
        {
          title: t("fixation_loss_method"),
          body:
            examReport?.stats?.fixationLossMethod ===
            FixationLossMethodOptions.BlindSpot
              ? t("word_blind_spot")
              : t("eye_tracking"),
        },
      ],
      [
        { title: t("false_positive"), body: falsePositivePercentage },
        { title: t("false_negative"), body: falseNegative },
        { title: t("fixation_loss"), body: fixationLossPercentage },
      ],
      [
        {
          title: t("report_exam_date"),
          body: localDateAndTimeWithZone?.date ?? t("word_na"),
          isBlack: true,
        },
        {
          title: t("report_exam_time"),
          body: localDateAndTimeWithZone?.time ?? t("word_na"),
          isBlack: true,
        },
        { title: t("report_exam_duration"), body: duration },
      ],
    ],
    [
      duration,
      exam.age,
      exam?.creationDate,
      exam?.patient?.sex,
      examReport?.exam?.stimulusSize,
      examReport?.stats?.fixationLossMethod,
      falseNegative,
      falsePositivePercentage,
      fixationLossPercentage,
      localDateAndTimeWithZone?.date,
      localDateAndTimeWithZone?.time,
      patient.dateOfBirth,
      t,
    ]
  );
  return (
    <ReportInfoEachSectionContext.Provider value={{ hasFourColumns: true }}>
      <ThemeProvider theme={theme}>
        <Grid
          container
          columnSpacing={2}
          my={1}
          sx={{ display: "flex", alignItems: "start" }}
        >
          <Grid container item xs={12}>
            {forBackendPdf && (
              <Grid
                item
                xs={1}
                style={{
                  paddingLeft: 15,
                  alignItems: "center",
                }}
              >
                <img
                  src={eyeLogo}
                  alt="eye logo"
                  style={{ width: "80%", height: "auto" }}
                />
              </Grid>
            )}
            <Grid container item xs={forBackendPdf ? 11 : 12} mb={1.5}>
              <Grid
                item
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                border={1}
                xs={12}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    background: "black",
                    width: 70,
                    height: "100%",
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  mr={isWiderThanSmallScreen ? 2 : 1}
                  xs={1.5}
                  sm={1}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    OU
                  </Typography>
                </Grid>
                <ReportInfoHeaderContent
                  isWiderThanSmallScreen={isWiderThanSmallScreen}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            display="flex"
            justifyContent={{ xs: "space-around", md: "space-between" }}
          >
            <ReportInfoShared data={infoData} gridSizeData={gridSizeData} />
          </Grid>
        </Grid>
        <AddCommentModal isColorBlind={true} />
      </ThemeProvider>
    </ReportInfoEachSectionContext.Provider>
  );
};

export default ReportInfo;
