// Library methods
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import ReportInfo from "./ReportInfo";
import EstermanChartLegend from "./EstermanChartLegend";
import EstermanGraph from "../../../../components/UI/EstermanGraph";
import GazeGraph from "../../../../components/UI/GazeGraph";

// Utilities
import {
  getChartSpots,
  getGazeChartData,
} from "../../../../utils/examReportsHelper";
import CommentsList from "../../../../components/UI/CommentsList";
import { ValidatePDFContext } from "../../../../contexts/ValidatePDFContext";
import { ReportDataContext } from "../../../../contexts/ReportDataContext";
import Watermark from "../../../../components/UI/Watermark";
import { sectionCompletionState } from "../../../../hooks/useExamStatus";
import { ErrorMessageComponent } from "../../../../components/UI/ErrorMessage";
import useResizeObserver from "../../../../hooks/useResizeObserver";

const EyeReport = () => {
  const { t } = useTranslation();
  const { forBackendPdf } = useContext(ValidatePDFContext);

  const { exam, examReport, eyeReport, durationStartTime, duration } =
    useContext(ReportDataContext);

  const [chartData, setChartData] = useState({
    esterman: undefined,
    gaze: undefined,
  });

  const containerRef = useRef(null);
  const dimensions = useResizeObserver(containerRef);

  useEffect(() => {
    if (!examReport) return;

    const spots = getChartSpots(examReport);
    const { gazeSpots } = getGazeChartData(examReport);

    setChartData({
      esterman: spots,
      gaze: gazeSpots,
    });
  }, [examReport]);

  // should return no content when the eyeReport is empty
  if (eyeReport === "") return null;

  // algorithm version number
  const algorithmVersion = examReport?.exam?.version?.substring(0, 3);

  // build the report content
  return (
    <div className="retinalogik-section" ref={containerRef}>
      <ReportInfo />
      {examReport?.postProcessing ? (
        <>
          <Grid container sx={{ mt: { sx: 0, sm: 0, md: 5 } }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {exam?.visualFieldSections?.[0]?.completionState ===
                sectionCompletionState.Terminated && (
                <Watermark py={30} fs="5em" />
              )}
              <EstermanGraph
                pressedPoints={chartData.esterman || []}
                eye={eyeReport}
                gridType={examReport?.exam?.grid}
                containerSize={dimensions?.width}
              />
            </Grid>
            <Grid
              item
              xs={12}
              pt={4}
              sx={{ display: "flex", justifyContent: "left" }}
            >
              <EstermanChartLegend
                estermanPoints={chartData.esterman || []}
                t={t}
              />
            </Grid>
          </Grid>
          <Grid
            display="flex"
            sx={{ mt: { sx: 2, sm: 2, md: 8 } }}
            container
            item
            xs={12}
            mr={4}
            flexDirection="row"
          >
            <Grid item xs={1} display="flex" flexDirection="column">
              <Box height="50%" display="flex" alignItems="center">
                <Typography>{t("fixation")}</Typography>
              </Box>
              <Box height="50%" display="flex" alignItems="center">
                <Typography>{t("blink")}</Typography>
              </Box>
            </Grid>

            <Grid item xs={11}>
              <GazeGraph data={chartData.gaze || []} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography>{durationStartTime}</Typography>
                <Typography>{duration}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} mt={2}>
            <CommentsList isVisualField={false} isEsterman={true} />
          </Grid>
          {!forBackendPdf && (
            <Grid
              container
              item
              display="flex"
              xs={12}
              flexDirection="row"
              justifyContent={"end"}
              mt={2}
            >
              <Grid item xs={12}>
                <Typography textAlign={"right"}>
                  {t("version")}: {algorithmVersion}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default EyeReport;
