// MUI Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Loader from "../../../../components/UI/Loader";

const ClinicStatsCard = ({ data }) => {
  const theme = useTheme();
  const smallerThanSmcreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sizeStyle = smallerThanSmcreen ? "14px" : "16px";

  return (
    <Grid
      item
      xs={4}
      md={12}
      sx={{
        py: 2,
        px: 1,
        marginBottom: 2,
        marginRight: { xs: 1, md: 0 },
        borderRadius: 2,
        border: "1px solid rgba(0,0,0,0.2)",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mx: { xs: 0, sm: 2 },
          gap: 0.25,
        }}
      >
        <Box display="flex" mb={0.5}>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
          >
            {data?.title}
          </Typography>
        </Box>
        {Object.keys(data)?.map(
          (k) =>
            data[k]?.title && (
              <Box key={data[k]?.title} display="flex" sx={{ gap: 1 }}>
                <Typography
                  noWrap
                  component="div"
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "12px", sm: "16px" },
                  }}
                >
                  {data[k]?.title}
                </Typography>
                {!Number.isInteger(data[k]?.value) ? (
                  <Loader
                    containerHeight={sizeStyle}
                    size={sizeStyle}
                    width={sizeStyle}
                    mt={1}
                  />
                ) : (
                  <Typography
                    noWrap
                    component="div"
                    variant="body1"
                    sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                  >
                    {data[k]?.value}
                  </Typography>
                )}
              </Box>
            )
        )}
      </Grid>
    </Grid>
  );
};

export default ClinicStatsCard;
