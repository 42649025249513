// Library methods
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useMemo } from "react";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
} from "../../../styles/muiStylesHelper";
import { DeviceAppVersionContext } from "../../../contexts/DeviceContext";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

import controllerOverlaidImage from "../../../assets/images/instruction/PicoController (WithPicoButtonOverlay).jpeg";
import arborWifiImage from "../../../assets/images/instruction/ArborWiFiConnected (WithOverlay).jpeg";
import arborExitOverlaidImage from "../../../assets/images/instruction/ArborExitWiFi (WithOverlay).jpeg";
import arborVersionOverlaidImage from "../../../assets/images/instruction/RetinaLogikAssignmentUI (WithOverlay).jpeg";
import arborVersionLobbyImage from "../../../assets/images/instruction/ArborLobbyProd (WithOverlay).jpeg";

const DeviceUpdateInstructionPopup = () => {
  const { t } = useTranslation();
  // Modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { openInstruction, setOpenInstruction } = useContext(
    DeviceAppVersionContext
  );
  const handleClick = useCallback(() => {
    setOpenInstruction(false);
  }, [setOpenInstruction]);

  const updateInstructions = useMemo(
    () => [
      {
        header: "1",
        text: "device_update_instruction1",
        img: controllerOverlaidImage,
      },
      { header: "2", text: "device_update_instruction2", img: arborWifiImage },
      {
        header: "2a",
        text: "device_update_instruction2_1",
        img: arborExitOverlaidImage,
      },
      { header: "3", text: "device_update_instruction3", img: null },
      {
        header: "4",
        text: "device_update_instruction4",
        img: arborVersionLobbyImage,
      },
      {
        header: "",
        text: "device_update_instruction4_1",
        img: arborVersionOverlaidImage,
      },
      { header: "5", text: "device_update_instruction5", img: null },
    ],
    []
  );

  // Utility function to split text according to your specified criteria
  const splitText = useCallback(
    (instruction) => {
      const parts = t(instruction.text).split(/(?<=\.\s)(?=[\w\s]{2})/);

      return parts.map((sentence, index) => (
        <Typography key={index}>
          {index === 0 && instruction.header
            ? instruction.header + ". " + sentence
            : index
              ? `　${sentence}`
              : sentence}
        </Typography>
      ));
    },
    [t]
  );

  return (
    <Dialog
      open={openInstruction}
      onClose={handleClick}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          ...ModalPaperProps,
          maxWidth: { xs: "95%", sm: "70%", md: "40%" },
        },
      }}
    >
      <DialogContent>
        <Box sx={{ padding: 1 }}>
          <Typography
            id="modal-modal-title"
            className="text-left"
            variant="h5"
            component="h2"
          >
            {t("device_update_instruction_title")}
          </Typography>
          {updateInstructions.map((instruction, index) => (
            <div key={index}>
              <Typography
                id={`modal-modal-description-${index}`}
                sx={{ mt: 3 }}
              >
                {splitText(instruction)}
              </Typography>
              {instruction.img && (
                <div
                  style={{
                    width: "75%",
                    display: "flex",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                >
                  <img
                    width="100%"
                    src={instruction.img}
                    alt={instruction.text}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              )}
            </div>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          sx={ModalCustomCancel}
          onClick={handleClick}
          style={{ marginRight: "1.25rem" }}
        >
          {t("button_close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceUpdateInstructionPopup;
