import {
  fallbackLng,
  languageMap,
  availableLanguages,
} from "../i18n/languages";

import moment from "moment-timezone";

const getLocale = (lang) => {
  const language = availableLanguages.includes(lang) ? lang : fallbackLng[0];
  return languageMap[language];
};

const localizeDate = (date) => {
  if (date === null) return null;
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() - localDate.getTimezoneOffset() * 60000
  );
  return localDate;
};

const getLocalDateAndTimeWithZone = (timezone, dateTime) => {
  const localTimezone = timezone ?? moment.tz.guess();

  const time = moment(dateTime).tz(localTimezone);
  const date = time.format("YYYY-MM-DD");
  const timeWithZone = time.format("HH:mm (z)"); // Time with timezone abbreviation
  return { date: date, time: timeWithZone };
};

export { getLocale, localizeDate, getLocalDateAndTimeWithZone };
