// Library methods
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import ReportInfo from "./ReportInfo";
import CommentsList from "../../../../components/UI/CommentsList";
import ColorVisionGraph from "../../../../components/UI/ColorVisionGraph";
import { ValidatePDFContext } from "../../../../contexts/ValidatePDFContext";
import { ReportDataContext } from "../../../../contexts/ReportDataContext";
import { sectionCompletionState } from "../../../../hooks/useExamStatus";
import Watermark from "../../../../components/UI/Watermark";
import { ErrorMessageComponent } from "../../../../components/UI/ErrorMessage";
import ResultChart from "./ResultChart";
import { ReportEachSectionContext } from "../../../../contexts/CommentContext";

const EyeReport = () => {
  const { t } = useTranslation();

  const { forBackendPdf } = useContext(ValidatePDFContext);

  const { exam } = useContext(ReportDataContext);
  const { examReport } = useContext(ReportEachSectionContext);

  // algorithm version number
  const algorithmVersion = examReport?.exam?.version?.substring(0, 3);

  const isTerminated = useMemo(() => {
    if (!exam?.colorVisionSections?.length) return;
    const eye = examReport?.exam?.eye;
    const section = exam?.colorVisionSections.find(
      (section) => section.eye === eye
    );
    return (
      section && section.completionState === sectionCompletionState.Terminated
    );
  }, [exam?.colorVisionSections, examReport?.exam?.eye]);

  // only show report if there's data
  if (!examReport) return null;

  // build the report content
  return (
    <div className="retinalogik-section">
      <ReportInfo />
      {examReport?.postProcessing ? (
        <>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              {isTerminated && <Watermark />}
              <ResultChart stateData={examReport.state} />
            </Grid>
            <ColorVisionGraph data={examReport} />
          </Grid>
          <Grid container item xs={12} mt={2}>
            <CommentsList isVisualField={false} />
          </Grid>
          {!forBackendPdf && (
            <Grid
              container
              item
              display="flex"
              xs={12}
              flexDirection="row"
              justifyContent={"end"}
              mt={2}
            >
              <Grid item xs={12}>
                <Typography textAlign={"right"}>
                  {t("version")}: {algorithmVersion}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default EyeReport;
