// Library methods
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect } from "react";

// MUI Components
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Utilities
import useLayoutHeight from "../../../hooks/useLayoutHeight";
import packageJson from "../../../../package.json";
import { Box } from "@mui/material";

const Footer = forwardRef(({ onHeightChange }, ref) => {
  // internationalization
  const { t } = useTranslation();
  const height = useLayoutHeight(ref);

  useEffect(() => {
    // Only call the onHeightChange callback if it's provided
    if (onHeightChange) {
      onHeightChange(height);
    }
  }, [height, onHeightChange]);
  return (
    <Box className="w-full bg-baseDark text-white" ref={ref}>
      <Container
        maxWidth="xl"
        sx={{
          "& .MuiContainer-root": {
            marginLeft: 0,
          },
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "start",
            display: "flex",
            flexDirection: "row",
            my: 2,
          }}
        >
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">
              {`©${new Date().getFullYear()} Retinalogik Inc. ${t("all_rights_reserved")}`}
            </Typography>
            <a
              href={"https://docsend.com/view/sbk6xanvbkkc737q"}
              style={{ color: "white" }}
            >
              Privacy Policy
            </a>
          </Grid>
          <Grid item xs={12} sm={8} sx={{ paddingLeft: { xs: 0, sm: 1 } }}>
            <Typography variant="body2" fontSize={8} color="gray">
              {t("footer_terms_and_conditions")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: { xs: "left", sm: "right" } }}
            >
              {`v${packageJson.version || "3.0"}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

export default Footer;
