// Library methods
import SharedConfirmation from "../SharedConfirmation";

const UnlinkDeviceModal = ({ open, onClose, onConfirm, onCancel, loading }) => {
  return (
    <SharedConfirmation
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      loading={loading}
      title={"device_unlink_confirmation"}
      description={"device_unlink_description"}
      confirmationButtonText={"button_unlink"}
      cancelText={"button_cancel"}
      isUnlink={true}
    />
  );
};

export default UnlinkDeviceModal;
