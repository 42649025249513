import grayFull from "../assets/images/batteries/grayFull.svg";
import grayHigh from "../assets/images/batteries/grayHigh.svg";
import orangeMid from "../assets/images/batteries/orangeMid.svg";
import orange from "../assets/images/batteries/orange.svg";
import red from "../assets/images/batteries/red.svg";

const getBatteryIcon = (batteryStatus) => {
  if (batteryStatus >= 0.8 && batteryStatus <= 1) {
    return <img src={grayFull} alt="batery icon" />;
  } else if (batteryStatus >= 0.6 && batteryStatus <= 0.79) {
    return <img src={grayHigh} alt="batery icon" />;
  } else if (batteryStatus >= 0.4 && batteryStatus <= 0.59) {
    return <img src={orangeMid} alt="batery icon" />;
  } else if (batteryStatus >= 0.2 && batteryStatus <= 0.39) {
    return <img src={orange} alt="batery icon" />;
  } else if (batteryStatus >= 0 && batteryStatus <= 0.19) {
    return <img src={red} alt="batery icon" />;
  }
};

// Function to validate version format
const isValidVersion = (version) => {
  return /^\d+\.\d+\.\d+$/.test(version); // Regex to check "major.minor.patch" format
};
// Function to compare versions
const isVersionNewer = (oldVer, newVer) => {
  if (
    typeof oldVer !== "string" ||
    typeof newVer !== "string" ||
    !isValidVersion(oldVer) ||
    !isValidVersion(newVer)
  )
    return false;
  const oldParts = oldVer.split(".").map(Number);
  const newParts = newVer.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (newParts[i] > oldParts[i]) return true;
    if (newParts[i] < oldParts[i]) return false;
  }
  return false;
};

export { getBatteryIcon, isVersionNewer };
