// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

const ClinicModeModal = ({ open, onClose, onConfirm, onCancel }) => {
  // internationalization
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { ...ModalPaperProps, width: 400 },
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faUser} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("word_leave_admin")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          onClick={onConfirm}
        >
          {t("word_proceed")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClinicModeModal;
