// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  CustomTextFieldOutlined,
  // CustomSelectProps,
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirm,
  CustomTextFieldOutlinedInputProps,
} from "../../../styles/muiStylesHelper";

import SearchBox from "../SearchBox";
import { useMemo } from "react";

const ClinicModalShared = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  clinicName,
  setClinicName,
  clinicTZ,
  setClinicTZ,
  title,
  confirmButtonTitle,
  allTimezoneInfo = [],
}) => {
  // internationalization
  const { t } = useTranslation();

  const shouldDisable = useMemo(() => {
    let isIncluded = 0;
    allTimezoneInfo.forEach((tz) => {
      if (
        `${clinicTZ?.name} (${clinicTZ?.utcOffset})` ===
        `${tz.name} (${tz.utcOffset})`
      )
        isIncluded++;
    });
    return !clinicName || !clinicName?.trim()?.length || isIncluded !== 1;
  }, [allTimezoneInfo, clinicName, clinicTZ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { ...ModalPaperProps, width: 400 },
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faBuilding} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t(title)}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* clinic name field*/}
          <TextField
            id="outlined-basic"
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
            style={{ marginTop: "1.25rem" }}
            label={t("clinic_modal_clinic_name")}
            sx={() => CustomTextFieldOutlined({ mt: "1.25rem" })}
            InputProps={{
              sx: CustomTextFieldOutlinedInputProps,
            }}
            variant="outlined"
            fullWidth
            color="warning"
          />
        </Box>
        <Box>
          <SearchBox
            data={allTimezoneInfo}
            state={clinicTZ}
            setState={setClinicTZ}
            propertyBit={1}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ paddingBottom: 3, display: "flex", justifyContent: "center" }}
      >
        <Button variant="text" onClick={onCancel} sx={ModalCustomCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={ModalCustomConfirm}
          color="primary"
          disabled={shouldDisable}
          onClick={onConfirm}
        >
          {t(confirmButtonTitle)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClinicModalShared;
