// MUI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import { forwardRef, useEffect } from "react";

const UsersCard = forwardRef(
  (
    { row, headCells, isSelected, handleCheckboxClick, handleReference },
    ref
  ) => {
    useEffect(() => {
      if (ref && ref.current) {
        handleReference(true);
      }
    }, [ref, handleReference]);
    return (
      <Card
        elevation={0}
        key={row.id}
        sx={{
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: !isSelected ? "#202338" : "#E2772E",
          color: "white",
          height: { xs: 89, xxs: 52 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={ref}
      >
        <Grid
          container
          px={2}
          py={1}
          sx={{
            display: { xs: "grid", xxs: "flex" },
            gridTemplateColumns: { xs: "15% 85%", xxs: "none" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} xxs={2}>
            <CustomCheckbox
              checkedFill="#E2772E"
              checked={isSelected}
              onClick={(event) => handleCheckboxClick(event, row.id)}
            />
          </Grid>
          <Grid
            item
            xxs={10}
            display={{ sx: "block", xxs: "flex" }}
            flexDirection={{ xxs: "space-between" }}
          >
            <Grid
              item
              xxs={5}
              display={{ sx: "block", xxs: "flex" }}
              // flexDirection={{ sm: "space-between" }}
            >
              <Typography
                noWrap
                sx={{
                  display: { xs: "block", xxs: "none" },
                  fontSize: { sm: "12px", md: "16px", lg: "1rem" },
                }}
              >
                <b>{headCells[0].label}:</b> {row.user}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block" },
                  fontSize: { sm: "12px", md: "1rem" },
                }}
              >
                {row.user}
              </Typography>
            </Grid>
            <Grid item xs={12} xxs={headCells[1].gridSize}>
              <Typography
                noWrap
                sx={{
                  display: { xs: "block", xxs: "none" },
                  fontSize: { sm: "12px", md: "1rem" },
                }}
              >
                <b>{headCells[1].label}:</b> {row.user}
              </Typography>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", xxs: "block" },
                  fontSize: { sm: "12px", md: "1rem" },
                }}
              >
                {row.user}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default UsersCard;
