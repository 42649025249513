import { Navigate } from "react-router-dom";

// Routing components
import Layout from "../components/Routing/Layout";
import LayoutAdmin from "../components/Routing/LayoutAdmin";
import ProtectedRoute from "../components/Routing/ProtectedRoute";

// Page components
import Callback from "../pages/Callback";
import Invalid from "../pages/Invalid";
import NotFound from "../pages/NotFound";
import Patients from "../pages/Patients";
import Clinics from "../pages/Clinics";
import Report from "../pages/Report";
import Pdf from "../pages/Pdf";
import Dashboard from "../pages/Dashboard";
import DevicesManagement from "../pages/DevicesManagement";
import UnauthorizedPdf from "../pages/UnauthorizedPdf";
import ClinicSettings from "../pages/ClinicSettings";
import AdminDashboard from "../pages/AdminDashboard";

const routes = [
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "dashboard",
    element: <ProtectedRoute component={Layout} />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Dashboard} />,
      },
    ],
  },
  {
    path: "patients",
    element: <ProtectedRoute component={Layout} />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Patients} />,
      },
      {
        path: ":patientId/exam/:examId",
        element: <ProtectedRoute component={Report} />,
      },
    ],
  },
  {
    path: "clinic-settings",
    element: <ProtectedRoute component={Layout} />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={ClinicSettings} />,
      },
    ],
  },
  {
    path: "pdf",
    element: <Pdf />,
  },
  {
    path: "callback",
    element: <ProtectedRoute component={Callback} />,
  },
  {
    path: "invalid",
    element: <ProtectedRoute component={Invalid} />,
  },
  {
    path: "admin",
    element: <ProtectedRoute component={LayoutAdmin} />,
    children: [
      {
        path: "clinics",
        element: <ProtectedRoute component={Clinics} />,
      },
      {
        path: "devices",
        element: <ProtectedRoute component={DevicesManagement} />,
      },
      {
        path: "dashboard",
        element: <ProtectedRoute component={AdminDashboard} />,
      },
    ],
  },
  {
    path: "404",
    element: <ProtectedRoute component={NotFound} />,
  },
  {
    path: "401",
    element: <UnauthorizedPdf />,
  },
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
];

export default routes;
