import { createContext } from "react";
import up from "../assets/images/contrastSensitivity/up.png";
import down from "../assets/images/contrastSensitivity/down.png";
import left from "../assets/images/contrastSensitivity/left.png";
import right from "../assets/images/contrastSensitivity/right.png";

const images = {
  up: up,
  down: down,
  left: left,
  right: right,
};

const ContrastImageContext = createContext();

const ContrastImageProvider = ({ children }) => {
  return (
    <ContrastImageContext.Provider value={images}>
      {children}
    </ContrastImageContext.Provider>
  );
};

export { ContrastImageContext, ContrastImageProvider };
