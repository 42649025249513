import { createContext } from "react";

const ReportDataContext = createContext({
  isLoading: false,
  patientName: "",
  patient: {},
  exam: {},
  examReport: {},
  examReport2: {},
  eyeReport: {},
  eyeReport2: {},
  comments: [{}],
  comments2: [{}],
  sectionId: "",
  sectionId2: "",
  refetchComment: () => {},
  durationStartTime: "",
  durationStartTime2: "",
  duration: "",
  duration2: "",
  clinicTimezone: null,
  //   downloadPdf: () => {},
  //   hasNoPdf: false,
});

const ReportInfoDataContext = createContext({
  getReportInfoData: () => {},
});

export { ReportDataContext, ReportInfoDataContext };
