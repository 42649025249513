// MUI Components
import { Grid } from "@mui/material";

const VisualAcuityBar = ({ index, isLiveUpdate }) => {
  return (
    <Grid
      container
      item
      xs={isLiveUpdate ? (index === 5 ? 4.8 : 5.6) : undefined}
      width={
        !isLiveUpdate
          ? {
              xs: index === 5 ? "140px" : "170px",
              sm: index === 5 ? "170px" : "210px",
            }
          : undefined
      }
      position={"absolute"}
      left={0}
      right={0}
      top={{ xs: 23, sm: 27 }}
      mx={"auto"}
    >
      <Grid
        item
        xs={12}
        height={3}
        bgcolor={index === 5 ? "#13A321" : "#B21515"}
      ></Grid>
    </Grid>
  );
};

export default VisualAcuityBar;
