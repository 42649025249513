import { useState, useEffect, useCallback, useRef } from "react";
import { getVersion } from "../services/SignalR";
import { HubConnectionState } from "@microsoft/signalr";

export const useOnlineStatus = (connection, clinicId) => {
  const [isConnected, setIsConnected] = useState(false);
  const lastHeartbeatResponse = useRef(Date.now());

  const HEARTBEAT_INTERVAL = 3000;
  const TIMEOUT = 5000;

  const checkConnection = useCallback(() => {
    const now = Date.now();
    if (now - lastHeartbeatResponse?.current > TIMEOUT) {
      console.warn("Connection might be lost!");
      setIsConnected(false);
    } else {
      setIsConnected(true);
    }
  }, []);

  const sendHeartbeat = useCallback(async () => {
    if (connection && connection?.state === HubConnectionState.Connected) {
      try {
        await getVersion(connection);
        if (lastHeartbeatResponse?.current)
          lastHeartbeatResponse.current = Date.now();
        setIsConnected(true);
      } catch (e) {
        console.error(e);
        setIsConnected(false);
      }
    }
  }, [connection]);

  useEffect(() => {
    if (connection) {
      // Start the heartbeat and connection check intervals
      const heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
      const checkConnectionInterval = setInterval(
        checkConnection,
        HEARTBEAT_INTERVAL
      );

      // Cleanup on unmount
      return () => {
        clearInterval(heartbeatInterval);
        clearInterval(checkConnectionInterval);
      };
    }
  }, [checkConnection, connection, sendHeartbeat]);

  useEffect(() => {
    if (!isConnected && connection) {
      const recoveryInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
      return () => clearInterval(recoveryInterval);
    }
  }, [isConnected, connection, sendHeartbeat]);

  return isConnected;
};
