import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeviceAppVersionContext } from "../../../contexts/DeviceContext";

const NewAppVersionWarningPopup = () => {
  const { t } = useTranslation();
  const { minimumCompatibleVersion, setOpenInstruction } = useContext(
    DeviceAppVersionContext
  );
  const helpMessageParts = t("device_new_version_available").split(
    "VERSION_NUM"
  );
  const handleClick = useCallback(() => {
    setOpenInstruction(true);
  }, [setOpenInstruction]);

  const modalButton = useMemo(
    () => (
      <Button
        onClick={handleClick}
        sx={{
          color: "white",
          textDecoration: "underline",
          fontWeight: 800,
          fontSize: { xs: "12px", sm: "14px", lg: "15px" },
          textTransform: "none",
          height: 25,
          marginTop: { xs: -0.3, lg: -0.2 },
        }}
      >
        {t("device_learn_more")}
      </Button>
    ),
    [handleClick, t]
  );
  return (
    <Grid
      item
      bgcolor={"#2c87e8"}
      width={"100%"}
      minHeight={{ xs: 35, sm: 30, md: 25 }}
      position={"absolute"}
      top={{ xs: 42, sm: 85 }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      zIndex={99}
    >
      <Typography
        color={"white"}
        textAlign={"center"}
        fontSize={{ xs: "12px", sm: "14px", lg: "15px" }}
      >
        {minimumCompatibleVersion ? (
          <>
            {helpMessageParts[0]}
            {minimumCompatibleVersion}
            {helpMessageParts[1]}
            {modalButton}
          </>
        ) : (
          <>
            t("device_new_version_available_alternative")
            {modalButton}
          </>
        )}
      </Typography>
    </Grid>
  );
};

export default NewAppVersionWarningPopup;
