// MUI Components
import Grid from "@mui/material/Grid";

import {
  CommentTitleProvider,
  ReportEachSectionContext,
} from "../../../contexts/CommentContext";
import { useContext, useMemo } from "react";
import { ReportDataContext } from "../../../contexts/ReportDataContext";

const ReportSectionContent = ({ EyeReport }) => {
  const {
    sectionId,
    sectionId2,
    examReport,
    examReport2,
    eyeReport,
    eyeReport2,
    duration,
    duration2,
    comments,
    comments2,
  } = useContext(ReportDataContext);
  const Memoized = useMemo(
    () => (
      <CommentTitleProvider>
        <Grid container sx={{ display: "flex" }} columnSpacing={4}>
          <ReportEachSectionContext.Provider
            value={{
              sectionId: sectionId,
              examReport: examReport,
              eyeReport: eyeReport,
              duration: duration,
              comments: comments,
            }}
          >
            {examReport && EyeReport && (
              <Grid item xs={12} lg={6} pt={4}>
                {EyeReport}
              </Grid>
            )}
          </ReportEachSectionContext.Provider>
          <ReportEachSectionContext.Provider
            value={{
              sectionId: sectionId2,
              examReport: examReport2,
              eyeReport: eyeReport2,
              duration: duration2,
              comments: comments2,
            }}
          >
            {examReport2 && EyeReport && (
              <Grid item xs={12} lg={6} pt={4}>
                {EyeReport}
              </Grid>
            )}
          </ReportEachSectionContext.Provider>
        </Grid>
      </CommentTitleProvider>
    ),
    [
      EyeReport,
      comments,
      comments2,
      duration,
      duration2,
      examReport,
      examReport2,
      eyeReport,
      eyeReport2,
      sectionId,
      sectionId2,
    ]
  );
  return <>{Memoized}</>;
};

export default ReportSectionContent;
