import { Box, Button, Grid, createTheme, useMediaQuery } from "@mui/material";
import ClinicsList from "../../containers/AdminDashboard/ClinicsList";
import {
  ModalCustomCancel,
  PageContainerWrapper,
} from "../../styles/muiStylesHelper";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAdminDashboard } from "../../services/Clinic";
import Loader from "../../components/UI/Loader";
import { getUtcStartEndDate } from "../../utils/dateHelper";

const AdminDashboard = () => {
  // Context
  const { getAccessTokenSilently } = useAuth0();
  const { t, i18n } = useTranslation();
  const theme = createTheme({});
  // This checks if the screen size is below the 'sm' breakpoint
  const isScreenSm = useMediaQuery(theme.breakpoints.down("md"));

  // State
  const [term, setTerm] = useState(0);
  const [clinicsInfo, setClinicsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchAdminDashboardInfo = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const { startOfUTC, endOfUTC } = getUtcStartEndDate(term);
        const dashboardInfo = await getAdminDashboard(token, {
          startDate: startOfUTC,
          endDate: endOfUTC,
        });
        if (!dashboardInfo?.length) return;
        // Organaize the data
        const formatted = dashboardInfo.map((clinicInfo) => {
          const tmp = {
            clinicName: clinicInfo.clinic.name,
            totalExamsCreated: clinicInfo.examsCreated,
            totalExamsTerminated: clinicInfo.examsTerminated,
            totalOfTotalExams: clinicInfo.totalExams,
            examsCreated: {},
            examsTerminated: {},
            totalExams: {},
            totalPatients: clinicInfo.totalPatients,
            patientsCreated: clinicInfo.patientsCreated,
          };

          const TOTAL = "total";
          const EXAMS = "Exams";
          const CREATED = "Created";
          const TERMINATED = "Terminated";
          Object.keys(clinicInfo).forEach((key) => {
            if (key.includes(EXAMS + CREATED)) {
              const examType = key.replace(EXAMS + CREATED, "");
              tmp.examsCreated[
                examType.charAt(0).toUpperCase() + examType.slice(1)
              ] = clinicInfo[key];
            } else if (key.includes(EXAMS + TERMINATED)) {
              const examType = key.replace(EXAMS + TERMINATED, "");
              tmp.examsTerminated[
                examType.charAt(0).toUpperCase() + examType.slice(1)
              ] = clinicInfo[key];
            } else if (
              key.startsWith(TOTAL) &&
              key.endsWith(EXAMS) &&
              key !== TOTAL + EXAMS
            ) {
              const examType = key.replace(TOTAL, "").replace(EXAMS, "");
              tmp.totalExams[examType.charAt(0) + examType.slice(1)] =
                clinicInfo[key];
            }
          });

          return tmp;
        });
        setIsLoading(false);
        setClinicsInfo(formatted);
      } catch (e) {
        setIsLoading(false);
      }
    };
    fetchAdminDashboardInfo();
  }, [getAccessTokenSilently, term]);

  return (
    <Box sx={PageContainerWrapper()}>
      <Grid container display={"flex"} justifyContent={"right"}>
        <Grid
          item
          xs={12}
          md={4}
          mt={3}
          mb={1}
          display={!isScreenSm ? "flex" : undefined}
          justifyContent={!isScreenSm ? "right" : undefined}
        >
          <Button
            onClick={() => setTerm(0)}
            sx={{
              ...ModalCustomCancel(),
              mr: 1.5,
              backgroundColor: term === 0 ? "#20233820" : "#FFFFFA",
            }}
          >
            {t("Today")}
          </Button>
          <Button
            onClick={() => setTerm(7)}
            sx={{
              ...ModalCustomCancel(),
              mr: 1.5,
              backgroundColor: term === 7 ? "#20233820" : "#FFFFFA",
            }}
          >
            {i18n.language === "fr"
              ? `7 ${t("word_last")} ${t("word_days")}`
              : `${t("word_last")} 7 ${t("word_days")}`}
          </Button>
          <Button
            onClick={() => setTerm(30)}
            sx={{
              ...ModalCustomCancel(),
              mr: 2,
              backgroundColor: term === 30 ? "#20233820" : "#FFFFFA",
            }}
          >
            {i18n.language === "fr"
              ? `30 ${t("word_last")} ${t("word_days")}`
              : `${t("word_last")} 30 ${t("word_days")}`}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Loader containerHeight="70vh" />
        ) : (
          <ClinicsList clinicsInfo={clinicsInfo} />
        )}
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
