import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

// Components
import NavbarAdmin from "../../UI/NavbarAdmin";
import Footer from "../../UI/Footer";
import { useRef, useState } from "react";
import LayoutHeightContext from "../../../contexts/LayoutHeight";

const LayoutAdmin = () => {
  const navarAdmiinRef = useRef(null);
  const footerRef = useRef(null);
  const [navbarAdminHeight, setNavbarAdminHeight] = useState(null);
  const [footerHeight, setFooterHeight] = useState(null);
  return (
    <LayoutHeightContext.Provider
      value={{ navbarHeight: navbarAdminHeight, footerHeight: footerHeight }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <NavbarAdmin
          ref={navarAdmiinRef}
          onHeightChange={(height) => {
            setNavbarAdminHeight(height);
          }}
        />
        <Outlet />
        <Footer
          ref={footerRef}
          onHeightChange={(height) => {
            setFooterHeight(height);
          }}
        />
      </Box>
    </LayoutHeightContext.Provider>
  );
};

export default LayoutAdmin;
